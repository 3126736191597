import { FC, useCallback, useState, useEffect, useMemo } from 'react'
import { Card, Table, type TableProps } from 'antd'
import { ReactComponent as ExportIcon } from '@src/assets/export_icon.svg'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Filters } from '@src/components/Table/Filters/Filters'
import { TableFiltersType } from '@src/types/tableFilters'
import { ResponseMetaType } from '@src/api/api'
import { TablePaginationConfig } from 'antd/lib/table/interface'
import { CardTabListType } from 'antd/es/card/Card'
import { FiltersV2 } from '@src/components/Table/Filters/FiltersV2'
import { SorterResult } from 'antd/es/table/interface'
import { isArray } from '@src/lib/utils'
import { PaginationFormik } from '@src/components/PaginationFormik/PaginationFormik'
import { ReactComponent as PlusIcon } from '@src/assets/plus_icon.svg'
import { Button } from '@src/components/ui/Button/Button'
import style from './pageTable.module.scss'

type TableRowSelection<T> = TableProps<T>['rowSelection'];

type PageTablePropsBase = {
  title?: string | boolean;
  createLink?: string;
  createText?: string;
  setParamsFilters: (value: (prevState: any) => any) => void;
  onExport?: ((value: number[]) => void) | false;
  isLoadingExport?: boolean
  tabList?: CardTabListType[]
  onTabChange?: (key: string) => void
  filtersV2?: boolean
  tableData: {
    data?: Array<any>
    meta?: ResponseMetaType
    isLoading: boolean
    columns: any
    components?: any
    footer?: () => any
  }
  actions?: any[]
  isTableOnly?: boolean
  activeTabKey?: string
  onCreate?: () => void
  columnsHeight?: string
}

type PageTablePropsWithFilters = PageTablePropsBase & {
  filtersList: TableFiltersType;
  paramsFilters: Record<string, any>
}

type PageTablePropsWithoutFilters = PageTablePropsBase & {
  filtersList?: TableFiltersType;
  paramsFilters?: Record<string, any>;
}

type PageTableProps = PageTablePropsWithFilters | PageTablePropsWithoutFilters;

export const PageTable: FC<PageTableProps> = ({
  title, createLink, createText, setParamsFilters, filtersList, paramsFilters, tableData, onExport,
  isLoadingExport, actions, isTableOnly, tabList, onTabChange, activeTabKey, filtersV2,
  onCreate, columnsHeight,
}) => {
  const { t } = useTranslation()
  const [selectedRowKeys, setSelectedRowKeys] = useState<Set<number>>(new Set())

  useEffect(() => () => {
    setSelectedRowKeys(new Set())
  }, [])

  const handleFilters = (e: Array<{ columns: string, value: any }>) => {
    setParamsFilters((prevState) => ({
      ...prevState,
      page: 1,
      filters: { ...prevState.filters, filters: e },
    }))
  }

  const handleFiltersV2 = useCallback((e: any) => {
    setParamsFilters((prevState) => ({
      ...prevState,
      page: 1,
      filters: {
        ...prevState.filters,
        ...e,
      },
    }))
  }, [setParamsFilters])

  const handleTableChange = useCallback((
    pagination: TablePaginationConfig,
    _: any,
    sorter: SorterResult<any> | SorterResult<any>[],
  ) => {
    let sort: string[] = []

    // todo если надо будет сортировать по 2 колонкам сразу нужно будет дописать логику
    if (!isArray(sorter) && sorter.column) {
      sort = [`${sorter.column.key}:${sorter.order}`]
    }

    setParamsFilters((prevState: any) => ({
      ...prevState,
      page: pagination.current || 1,
      sort,
    }))
  }, [setParamsFilters])

  const rowSelection = useMemo<TableRowSelection<any>>(() => ({
    selectedRowKeys: Array.from(selectedRowKeys),
    onSelect: (record, selected) => {
      if (selected) {
        setSelectedRowKeys((prevState) => {
          const newItems = new Set(prevState)
          newItems.add(record.id)
          return newItems
        })
      } else {
        setSelectedRowKeys((prevState) => {
          const newItems = new Set(prevState)
          newItems.delete(record.id)
          return newItems
        })
      }
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      if (selected) {
        setSelectedRowKeys((prevState) => new Set([...prevState, ...changeRows.map((item) => item.id)]))
      } else {
        setSelectedRowKeys((prevState) => {
          const newItems = new Set(prevState)
          changeRows.forEach((element) => newItems.delete(element.id))
          return newItems
        })
      }
    },
  }), [selectedRowKeys])

  const columns = useMemo(() => {
    if (paramsFilters?.sort?.length) {
      return tableData.columns.map((item: any) => {
        const sortItem = paramsFilters.sort.find((sort: string) => sort.startsWith(item.key))
        const defaultSortOrder = sortItem ? sortItem.split(':')[1] : ''

        return ({
          ...item,
          defaultSortOrder,
        })
      })
    }

    return tableData.columns
  }, [paramsFilters?.sort, tableData])

  const handlePaginationChange = useCallback((page: number) => {
    setParamsFilters((prevState: any) => ({
      ...prevState,
      page,
    }))
  }, [setParamsFilters])

  const table = useMemo(() => {
    const totalCount = tableData.meta?.totalCount || 0
    const pageSize = tableData.meta?.pageSize || 10
    const shouldShowPagination = totalCount > pageSize

    return (
      <div className={style.tableContainer}>
        <Table
          components={tableData.components}
          rowSelection={onExport ? rowSelection : undefined}
          dataSource={tableData?.data || []}
          columns={columns}
          loading={tableData.isLoading}
          onChange={handleTableChange}
          rowKey="id"
          scroll={{ x: 'max-content' }}
          footer={tableData.footer}
          pagination={false}
          onRow={() => ({
            style: {
              height: columnsHeight,
              lineHeight: columnsHeight,
            },
          })}
        />
        {shouldShowPagination && (
          <PaginationFormik
            total={tableData.meta?.totalCount || 0}
            current={tableData.meta?.currentPage || 1}
            pageSize={tableData.meta?.pageSize || 10}
            onChange={handlePaginationChange}
          />
        )}
      </div>
    )
  }, [handleTableChange, handlePaginationChange, onExport, rowSelection, tableData, columns, columnsHeight])

  if (isTableOnly) {
    return table
  }

  return (
    <div>
      <Card
        className={style.cardWrapper}
        title={title}
        tabList={tabList}
        onTabChange={onTabChange}
        activeTabKey={activeTabKey}
        style={{ backgroundColor: '#F7F8F9', border: 'none' }}
        extra={(
          <div className={style.cardActions}>
            {actions}
            {onExport && (
              <Button
                onClick={() => onExport(Array.from(selectedRowKeys))}
                size="large"
                leftIcon={<ExportIcon />}
                loading={isLoadingExport}
              >
                {t('pageTable.export')}
              </Button>
            )}
            {createLink && (
              <NavLink to={createLink}>
                <Button size="large" leftIcon={<PlusIcon />} className="test-create-btn">
                  {createText || t('pageTable.create')}
                </Button>
              </NavLink>
            )}
            {onCreate && (
              <Button onClick={onCreate} size="large" leftIcon={<PlusIcon />} className="test-create-btn">
                {createText || t('pageTable.create')}
              </Button>
            )}
          </div>
        )}
      >
        {filtersList
          && (filtersV2 ? (
            <FiltersV2
              filters={filtersList}
              onChange={handleFiltersV2}
              values={paramsFilters!.filters}
            />
          ) : (
            <Filters
              filters={filtersList}
              onChange={handleFilters}
              values={paramsFilters!.filters?.filters}
            />
          ))}
        {table}
      </Card>
    </div>
  )
}
