import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import useQuery from '@src/hooks/useQuery'
import { addQuery } from '@src/lib/route'
import { TableFiltersType } from '@src/types/tableFilters'
import { companiesApi } from '@src/store/services/companies-service'
import { PageTable } from '@src/components/Table/PageTable'
import { useTransportationBills } from '@src/components/pages/transportationBills/TransportationBills.hook'
import { TransportationBillStatus } from '@src/types/transportationBills'

export const TransportationBills = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { data: companiesList } = companiesApi.useGetSelectCompaniesQuery()
  const { data: companiesSelect } = companiesApi.useGetSelectCompaniesQuery()
  const pageQuery = useQuery('page')
  const filtersQuery = useQuery('filters')

  const { data, columns, paramsFilters, setParamsFilters, isLoading } = useTransportationBills({
    initParamsFilters: {
      page: pageQuery ? +pageQuery : 1,
      filters: filtersQuery ? JSON.parse(filtersQuery) : {},
    },
  })

  useEffect(() => {
    history.push({
      search: addQuery({ ...paramsFilters, filters: JSON.stringify(paramsFilters.filters) }),
    })
  }, [paramsFilters, history])

  const filtersList = useMemo<TableFiltersType>(() => [
    [
      {
        type: 'input',
        dataIndex: 'transportation.points.cargo.name',
        placeholder: t('transportation.columns.cargo'),
        width: '200px',
        operator: '$contains',
      },
    ],
    [
      {
        type: 'select',
        dataIndex: 'payer_id',
        operator: '$eq',
        placeholder: t('transportation.columns.payer'),
        options: companiesSelect?.map((item) => ({ label: item.name, value: item.id })) || [],
        width: '200px',
      },
      {
        type: 'input',
        operator: '$contains',
        dataIndex: 'bill_number',
        placeholder: t('transportation.columns.billNumber'),
        width: '200px',
      },
    ],
    [
      {
        type: 'select',
        dataIndex: 'transportation.from.id',
        placeholder: t('transportation.columns.from'),
        operator: '$eq',
        width: '200px',
        options: companiesList?.map((item) => ({ value: item.id, label: item.name })) || [],
        showSearch: true,
      },
      {
        type: 'select',
        dataIndex: 'transportation.to.id',
        placeholder: t('transportation.columns.to'),
        operator: '$eq',
        width: '200px',
        options: companiesList?.map((item) => ({ value: item.id, label: item.name })) || [],
        showSearch: true,
      },
    ],
    [
      {
        type: 'input',
        dataIndex: 'transportation.from.address',
        operator: '$contains',
        placeholder: t('transportationBills.columns.addressFrom'),
        width: '150px',
      },
      {
        type: 'input',
        dataIndex: 'transportation.to.address',
        operator: '$contains',
        placeholder: t('transportationBills.columns.addressTo'),
        width: '150px',
      },
    ],
    [
      {
        type: 'select',
        dataIndex: 'status',
        operator: '$eq',
        placeholder: t('transportation.columns.status'),
        width: '150px',
        options: [
          {
            value: TransportationBillStatus.notProcessed,
            label: t(`transportationBills.status.${TransportationBillStatus.notProcessed}`),
          },
          {
            value: TransportationBillStatus.processed,
            label: t(`transportationBills.status.${TransportationBillStatus.processed}`),
          },
          {
            value: TransportationBillStatus.archived,
            label: t(`transportationBills.status.${TransportationBillStatus.archived}`),
          },
        ],
      },
      {
        type: 'select',
        dataIndex: 'is_sent_accountant',
        operator: '$eq',
        placeholder: t('transportationBills.columns.sentAccountant'),
        width: '150px',
        options: [
          {
            value: '0',
            label: t('sendStatus.0'),
          },
          {
            value: '1',
            label: t('sendStatus.1'),
          },
        ],
      },
      {
        type: 'select',
        dataIndex: 'is_sent_customer',
        operator: '$eq',
        placeholder: t('transportationBills.columns.sentCustomer'),
        width: '150px',
        options: [
          {
            value: '0',
            label: t('sendStatus.0'),
          },
          {
            value: '1',
            label: t('sendStatus.1'),
          },
        ],
      },
    ],
    [
      {
        type: 'rangePicker',
        dataIndex: 'transportation.date',
        placeholder: [t('filters.dateFrom'), t('filters.dateTo')],
      },
    ],
  ], [t, companiesSelect, companiesList])

  return (
    <div>
      <PageTable
        title={t('transportationBills.title')!}
        filtersList={filtersList}
        setParamsFilters={setParamsFilters}
        paramsFilters={paramsFilters}
        filtersV2
        tableData={{
          ...data,
          isLoading,
          columns,
          components: {
            body: {
              // eslint-disable-next-line max-len
              row: (props: any) => (
                <tr
                  {...props}
                  style={{
                    background: data?.data.find((item) => item.id === props['data-row-key'])?.company?.color,
                    height: '64px',
                  }}
                />
              ),
            },
          },
        }}
      />
    </div>
  )
}
