import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { ColumnProps } from 'antd/lib/table'
import { transportationApi } from '@src/store/services/transportation-service'
import { TransportationBillStatus, TransportationBillType } from '@src/types/transportationBills'
import { AppDispatch } from '@src/store/store'
import { setSendEmail } from '@src/store/ducks/app/reducer'
import { SendEmailType } from '@src/types/mailLogs'
import { isSelectionText } from '@src/lib/utils'
import { Button, Popover, Tag } from 'antd'
import cn from 'classnames'
import { ReactComponent as DocumentIcon } from '@src/assets/document_icon.svg'
import { ReactComponent as SendMailIcon } from '@src/assets/send-mail_icon.svg'
import { TransportationTooltip } from '../transportation/TransportationTooltip'

const statusColor: any = {
  1: 'blue',
  2: 'green',
  3: 'red',
}
export const useTransportationBills = ({ initParamsFilters }: any) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch<AppDispatch>()
  const location = useLocation()
  const [paramsFilters, setParamsFilters] = useState(initParamsFilters)
  const [isOpenEditStatus, setIsOpenEditStatus] = useState<null | number>(null)
  const { data, isLoading } = transportationApi.useGetListTransportationBillsQuery(paramsFilters)
  const [generateDocument] = transportationApi.useTransportationBillGenerateDocumentMutation()
  const [editTransportationBill] = transportationApi.useEditTransportationBillMutation()

  const updateStatus = useCallback((record: TransportationBillType, status: TransportationBillStatus) => {
    editTransportationBill({ id: record.id, body: { status } })
    setIsOpenEditStatus(null)
  }, [editTransportationBill])

  const onCell = useCallback((record: TransportationBillType) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/transportation-bills/edit/${record.id}`, { backUrl: location.pathname + location.search })
      }
    },
  }), [history, location])

  const onCellMailHandler = useCallback((record: TransportationBillType, isAccountant?: boolean) => ({
    onClick: () => {
      if (!isSelectionText()) {
        if ((record.status === TransportationBillStatus.processed && record?.payer?.id) || isAccountant) {
          dispatch(setSendEmail({
            type: SendEmailType.TransportationBills,
            companyId: record?.payer?.id!,
            id: record.id,
            isAccountant,
          }))
        }
      }
    },
  }), [dispatch])

  const columns = useMemo<ColumnProps<TransportationBillType>[]>(() => [
    {
      title: t('transportation.columns.from_to'),
      dataIndex: 'transportation',
      key: 'from_to',
      render: (transportation) => `${transportation.from?.full_name} - ${transportation.to?.full_name}`,
      onCell,
    },
    {
      title: t('transportation.columns.payer'),
      dataIndex: 'payer',
      key: 'payer',
      render: (payer) => payer?.full_name,
      onCell,
    },
    {
      title: t('transportation.columns.transportationDate'),
      dataIndex: 'transportation',
      key: 'transportationDate',
      render: (transportation) => transportation.date,
      onCell,
    },
    {
      title: t('transportation.columns.sum'),
      dataIndex: 'sum',
      key: 'sum',
      render: (sum) => (
        sum ? `${sum} EUR` : null
      ),
      onCell,
    },
    {
      title: t('transportation.columns.billNumber'),
      dataIndex: 'bill_number',
      key: 'bill_number',
      onCell,
    },
    {
      title: t('transportation.columns.route'),
      dataIndex: 'transportation',
      key: 'cargo',
      render: (transportation) => <TransportationTooltip routes={transportation.points} />,
      onCell,
    },
    {
      title: t('transportation.columns.distance'),
      dataIndex: 'transportation',
      key: 'total_distance',
      render: (transportation) => `${transportation.total_distance} km`,
      onCell,
    },
    {
      title: t('transportationBills.columns.status'),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (status, record) => (
        <Popover
          content={(
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div>
                <Button
                  type="text"
                  onClick={() => updateStatus(record, TransportationBillStatus.notProcessed)}
                >
                  <div>
                    {t(`invoices.status.${TransportationBillStatus.notProcessed}`)}
                  </div>
                </Button>
              </div>
              <div>
                <Button
                  type="text"
                  onClick={() => updateStatus(record, TransportationBillStatus.processed)}
                >
                  <div>
                    {t(`invoices.status.${TransportationBillStatus.processed}`)}
                  </div>
                </Button>
              </div>
              <div>
                <Button
                  type="text"
                  onClick={() => updateStatus(record, TransportationBillStatus.archived)}
                >
                  <div>
                    {t(`invoices.status.${TransportationBillStatus.archived}`)}
                  </div>
                </Button>
              </div>
            </div>
          )}
          trigger="click"
          placement="bottom"
          open={isOpenEditStatus === record.id}
          onOpenChange={() => setIsOpenEditStatus(record?.id)}
        >
          <Tag
            className="status"
            style={{ cursor: 'pointer' }}
            color={statusColor[status]}
          >
            {t(`transportationBills.status.${status}`)}
          </Tag>
        </Popover>
      ),
    },
    {
      title: t('transportationBills.columns.sentAccountant'),
      dataIndex: 'is_sent_accountant',
      key: 'isSentAccountant',
      align: 'center',
      render: (isSentAccountant) => (
        <Tag
          className="status"
          color={isSentAccountant ? 'green' : 'red'}
          style={{ cursor: 'pointer' }}
        >
          {t(`sendStatus.${isSentAccountant}`)}
        </Tag>
      ),
      onCell: (record) => onCellMailHandler(record, true),
    },
    {
      title: t('transportationBills.columns.sentCustomer'),
      dataIndex: 'is_sent_customer',
      key: 'isSentCustomer',
      align: 'center',
      render: (isSentAccountant) => (
        <Tag
          className="status"
          color={isSentAccountant ? 'green' : 'red'}
          style={{ cursor: 'pointer' }}
        >
          {t(`sendStatus.${isSentAccountant}`)}
        </Tag>
      ),
      onCell: (record) => onCellMailHandler(record),
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '104px',
      fixed: 'right',
      render: (_, record) => (
        <div className={cn('table_actions')}>
          {record.status === TransportationBillStatus.processed && record?.payer?.id && (
            <>
              <button
                type="button"
                onClick={() => {
                  generateDocument(record.id)
                }}
                className={cn('table_actions_item')}
              >
                <DocumentIcon />
              </button>
              <button
                type="button"
                onClick={() => {
                  dispatch(setSendEmail({
                    type: SendEmailType.TransportationBills,
                    companyId: record?.payer?.id!,
                    id: record.id,
                  }))
                }}
                className={cn('table_actions_item')}
              >
                <SendMailIcon />
              </button>
            </>
          )}
        </div>
      ),
    },
  ], [t, onCell, onCellMailHandler, generateDocument, dispatch, isOpenEditStatus, updateStatus])

  return {
    columns,
    data,
    isLoading,
    paramsFilters,
    setParamsFilters,
  }
}
