import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { Button, Card, Col, Drawer, Row } from 'antd'
import { Form, Formik } from 'formik'

import { Preloader } from '@src/components/Preloader/Preloader'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { SelectFormik } from '@src/components/SelectFormik/SelectFormik'
import { CustomerCreate } from '@src/Pages/CustomerCreate/CustomerCreate'
import { OwnerCreate } from '@src/Pages/OwnerCreate/OwnerCreate'
import { CustomerInfo } from '@src/components/CustomerInfo/CustomerInfo'
import { companiesApi } from '@src/store/services/companies-service'
import { CompanyType } from '@src/types/company'
import { agreementsApi } from '@src/store/services/agreements-service'
import { customerWorkersApi } from '@src/store/services/customerWorkers-service'
import { DatePickerFormik } from '@src/components/DatePickerFormik/DatePickerFormik'
import moment from 'moment'
import { ViewContentHeader } from '@src/components/ViewContentHeader/ViewContentHeader'
import { ReactComponent as PlusCircleIcon } from '@src/assets/plus-circle_icon.svg'
import { Button as PrimaryButton } from '@src/components/ui/Button/Button'
import { AgreementsDocument } from '@src/components/pages/agreements/AgreementsDocument'
import style from './agreements.module.scss'

type AgreementsCreateProps = {
  isModal?: boolean
  successCallbackModal?: () => void
}

export const AgreementsCreate: FC<AgreementsCreateProps> = ({ isModal, successCallbackModal }) => {
  const formRef = useRef<any>()
  const { t } = useTranslation()
  const params = useParams<{ id?: string }>()
  const history = useHistory()
  const [isCreate, setIsCreate] = useState<boolean>(false)
  const [isShowInfo, setIsShowInfo] = useState<boolean>(false)
  const [createType, setCreateType] = useState<'car' | 'customer' | 'owner' | null>(null)
  const { data: customersSelect } = companiesApi.useGetSelectCompaniesQuery(CompanyType.Customer)
  const { data: ownersSelect } = companiesApi.useGetSelectCompaniesQuery(CompanyType.Owner)
  const [triggerOwnerRepresentative, { data: ownerRepresentative }] = customerWorkersApi.useLazyShortListQuery()
  const [triggerCustomerRepresentative, { data: customerRepresentative }] = customerWorkersApi.useLazyShortListQuery()
  const { data: info, isLoading } = agreementsApi.useGetOneAgreementQuery(+params.id!, { skip: !params.id || isModal })
  const [create, { isLoading: isLoadingCreate }] = agreementsApi.useCreateAgreementMutation()
  const [edit, { isLoading: isLoadingEdit }] = agreementsApi.useEditAgreementMutation()

  useEffect(() => {
    if (info) {
      triggerOwnerRepresentative({ companyId: info.owner_id })
      triggerCustomerRepresentative({ companyId: info.customer_id })
    }
  }, [info, triggerOwnerRepresentative, triggerCustomerRepresentative])

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        owner_id: Yup.string().required(t('form.errors.required') ?? ''),
        owner_representative_id: Yup.string().required(t('form.errors.required') ?? ''),
        customer_id: Yup.string().required(t('form.errors.required') ?? ''),
        customer_representative_id: Yup.string().required(t('form.errors.required') ?? ''),
        date: Yup.string().required(t('form.errors.required') ?? ''),
        agreement_number: Yup.string().required(t('form.errors.required') ?? ''),
      }),
    [t],
  )

  const initialValues = useMemo(
    () => ({
      owner_id: info?.owner_id ?? '',
      owner_representative_id: info?.owner_representative_id ?? '',
      customer_id: info?.customer_id ?? '',
      customer_representative_id: info?.customer_representative_id ?? '',
      date: info?.date ? moment(info.date) : moment(),
      agreement_number: info?.agreement_number ?? '',
    }),
    [info],
  )

  const successCallback = useCallback(() => {
    if (successCallbackModal) {
      successCallbackModal()
    } else {
      history.goBack()
    }
  }, [history, successCallbackModal])

  const onSubmit = useCallback(
    async (values: any) => {
      let result

      const body = {
        ...values,
        date: moment(values.date).format('YYYY-MM-DD'),
      }

      if (params.id && !isModal) {
        result = await edit({
          id: +params.id,
          body,
        })
      } else {
        result = await create(body)
      }

      if ('data' in result) {
        successCallback()
      }
    },
    [create, edit, isModal, successCallback, params.id],
  )

  const onCloseCreate = useCallback(() => {
    setIsCreate(false)
    setCreateType(null)
  }, [])

  const onCloseInfo = useCallback(() => {
    setIsShowInfo(false)
  }, [])

  const onOwnerChange = useCallback((value: any, setFieldValue: any) => {
    triggerOwnerRepresentative({ companyId: +value })
    setFieldValue('owner_representative_id', '')
  }, [triggerOwnerRepresentative])

  const onCustomerChange = useCallback((value: any, setFieldValue: any) => {
    triggerCustomerRepresentative({ companyId: +value })
    setFieldValue('customer_representative_id', '')
  }, [triggerCustomerRepresentative])

  return (
    <div>
      {!isModal && (
        <ViewContentHeader onBackClick={() => history.goBack()}>
          {params.id
            ? `${t('agreementsCreate.titleAgreementEdit')} ${info?.agreement_number ? info?.agreement_number : ''}`
            : t('agreementsCreate.title')}
        </ViewContentHeader>
      )}
      <Preloader loading={isLoading}>
        <div>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            innerRef={formRef}
            enableReinitialize
            style={{ height: '100%' }}
          >
            {({ values, setFieldValue }) => (
              <div>
                {!isModal ? (
                  <Row gutter={[24, 0]}>
                    <Col xs={24} xl={12}>
                      <Form className="not-card">
                        <Col xl={12} xs={24}>
                          <h2 className={style.title}>{t('agreementsCreate.ownerInfoBlock')}</h2>
                        </Col>
                        <Card className={style.card}>
                          <Row gutter={[8, 0]}>
                            <Col xl={9} xs={10}>
                              <SelectFormik
                                name="owner_id"
                                showSearch
                                placeholder={t('agreementsCreate.owner') ?? ''}
                                options={ownersSelect?.map((item) => ({ label: item.name, value: item.id })) || []}
                                onChange={(value) => onOwnerChange(value, setFieldValue)}
                              />
                            </Col>
                            <Col xl={3} xs={2}>
                              <Button
                                size="large"
                                onClick={() => {
                                  setIsCreate(true)
                                  setCreateType('owner')
                                }}
                                className={style.plusButton}
                              >
                                <PlusCircleIcon />
                              </Button>
                            </Col>
                            <Col span={12}>
                              <SelectFormik
                                name="owner_representative_id"
                                options={ownerRepresentative?.map((item) => ({
                                  value: item.id!,
                                  label: `${item.name}`,
                                })) || []}
                                disabled={!values.owner_id}
                                placeholder={t('agreementsCreate.ownerRepresentative')!}
                              />
                            </Col>
                          </Row>
                        </Card>
                        <Col xl={12} xs={24}>
                          <h2 className={style.title}>{t('agreementsCreate.customerInfoBlock')}</h2>
                        </Col>
                        <Card className={style.card}>
                          <Row gutter={[8, 0]}>
                            <Col xl={9} xs={10}>
                              <SelectFormik
                                name="customer_id"
                                showSearch
                                placeholder={t('agreementsCreate.customer') ?? ''}
                                options={customersSelect?.map((item) => ({ label: item.name, value: item.id })) || []}
                                onChange={(value) => onCustomerChange(value, setFieldValue)}
                              />
                            </Col>
                            <Col xl={3} xs={2}>
                              <Button
                                size="large"
                                onClick={() => {
                                  setIsCreate(true)
                                  setCreateType('customer')
                                }}
                                className={style.plusButton}
                              >
                                <PlusCircleIcon />
                              </Button>
                            </Col>
                            <Col span={12}>
                              <SelectFormik
                                name="customer_representative_id"
                                options={customerRepresentative?.map((item) => ({
                                  value: item.id!,
                                  label: `${item.name}`,
                                })) || []}
                                disabled={!values.customer_id}
                                placeholder={t('agreementsCreate.customerRepresentative')!}
                              />
                            </Col>
                          </Row>
                        </Card>
                        <Col xl={12} xs={24}>
                          <h2 className={style.title}>{t('agreementsCreate.agreementInfoBlock')}</h2>
                        </Col>
                        <Card className={style.card}>
                          <Row gutter={[8, 0]}>
                            <Col span={12}>
                              <DatePickerFormik name="date" placeholder={t('agreementsCreate.date')} />
                            </Col>
                            <Col span={12}>
                              <FieldFormik name="agreement_number" placeholder={t('agreements.columns.number')} />
                            </Col>
                            <PrimaryButton
                              htmlType="submit"
                              type="primary"
                              size="large"
                              loading={isLoadingCreate || isLoadingEdit}
                              style={{ marginBottom: '24px' }}
                            >
                              {params.id ? t('agreementsCreate.save') : t('agreementsCreate.submit')}
                            </PrimaryButton>
                          </Row>
                        </Card>
                      </Form>
                    </Col>
                    <Col xs={24} xl={12}>
                      <Card className={style.card}>
                        <AgreementsDocument />
                      </Card>
                    </Col>
                  </Row>
                ) : (
                  <Row gutter={[24, 0]} style={{ height: '100%' }}>
                    <Col span={24} style={{ height: '100%' }}>
                      <Form
                        className="not-card"
                        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
                      >
                        <h2 className={style.titleCard}>{t('agreementsCreate.ownerInfoBlock')}</h2>
                        <Row gutter={[8, 0]}>
                          <Col xl={9} xs={9}>
                            <SelectFormik
                              name="owner_id"
                              showSearch
                              placeholder={t('agreementsCreate.owner') ?? ''}
                              options={ownersSelect?.map((item) => ({ label: item.name, value: item.id })) || []}
                              onChange={(value) => onOwnerChange(value, setFieldValue)}
                            />
                          </Col>
                          <Col xl={3} xs={3}>
                            <Button
                              size="large"
                              onClick={() => {
                                setIsCreate(true)
                                setCreateType('owner')
                              }}
                              className={style.plusButton}
                            >
                              <PlusCircleIcon />
                            </Button>
                          </Col>
                          <Col span={12}>
                            <SelectFormik
                              name="owner_representative_id"
                              options={ownerRepresentative?.map((item) => ({
                                value: item.id!,
                                label: `${item.name}`,
                              })) || []}
                              disabled={!values.owner_id}
                              placeholder={t('agreementsCreate.ownerRepresentative')!}
                            />
                          </Col>
                        </Row>
                        <h2 className={style.titleCard}>{t('agreementsCreate.customerInfoBlock')}</h2>
                        <Row gutter={[8, 0]}>
                          <Col xl={9} xs={9}>
                            <SelectFormik
                              name="customer_id"
                              showSearch
                              placeholder={t('agreementsCreate.customer') ?? ''}
                              options={customersSelect?.map((item) => ({ label: item.name, value: item.id })) || []}
                              onChange={(value) => onCustomerChange(value, setFieldValue)}
                            />
                          </Col>
                          <Col xl={3} xs={3}>
                            <Button
                              size="large"
                              onClick={() => {
                                setIsCreate(true)
                                setCreateType('customer')
                              }}
                              className={style.plusButton}
                            >
                              <PlusCircleIcon />
                            </Button>
                          </Col>
                          <Col span={12}>
                            <SelectFormik
                              name="customer_representative_id"
                              options={customerRepresentative?.map((item) => ({
                                value: item.id!,
                                label: `${item.name}`,
                              })) || []}
                              disabled={!values.customer_id}
                              placeholder={t('agreementsCreate.customerRepresentative')!}
                            />
                          </Col>
                        </Row>
                        <h2 className={style.titleCard}>{t('agreementsCreate.agreementInfoBlock')}</h2>
                        <Row gutter={[8, 0]}>
                          <Col span={12}>
                            <DatePickerFormik name="date" placeholder={t('agreementsCreate.date')} />
                          </Col>
                          <Col span={12}>
                            <FieldFormik name="agreement_number" placeholder={t('agreements.columns.number')} />
                          </Col>
                        </Row>
                        <PrimaryButton
                          htmlType="submit"
                          type="primary"
                          size="large"
                          loading={isLoadingCreate || isLoadingEdit}
                          style={{ marginTop: 'auto', alignSelf: 'flex-start' }}
                        >
                          {params.id ? t('agreementsCreate.save') : t('agreementsCreate.submit')}
                        </PrimaryButton>
                      </Form>
                    </Col>
                    {!isModal && (
                      <Col xs={24} xl={12}>
                        <Card className={style.card}>
                          <AgreementsDocument />
                        </Card>
                      </Col>
                    )}
                  </Row>
                )}
              </div>
            )}
          </Formik>
        </div>
      </Preloader>
      <Drawer
        title={
          (createType === 'customer' && t('customerCreate.title'))
          || (createType === 'owner' && t('ownerCreate.title'))
        }
        open={isCreate}
        onClose={onCloseCreate}
        width={554}
      >
        {createType === 'customer' && <CustomerCreate onClose={onCloseCreate} isModal successCallbackModal={onCloseCreate} />}
        {createType === 'owner' && <OwnerCreate onClose={onCloseCreate} isModal successCallbackModal={onCloseCreate} />}
      </Drawer>
      <CustomerInfo isOpen={isShowInfo} onClose={onCloseInfo} customerId={formRef.current?.values.customer_id} />
    </div>
  )
}
