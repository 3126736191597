import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { InvoicesStatus, InvoicesType } from '@src/types/invoices'
import { GetItemsType } from '@src/api/api'
import { formatFilters } from '@src/lib/formatFilters'
import qs from 'qs'
import { getFileName } from '@src/lib/getFileName'
import { downloadFile } from '@src/lib/downloadFile'

export const invoicesApi = createApi({
  reducerPath: 'invoicesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      headers.set('Accept', 'application/json, text/plain, */*')

      return headers
    },
  }),
  refetchOnMountOrArgChange: 60,
  tagTypes: ['Invoices'],
  endpoints: (build) => ({
    getList: build.query<GetItemsType<InvoicesType>, { page: number, filters: any }>({
      query: (params) => ({
        url: `/business-invoices?${formatFilters(params)}`,
      }),
      providesTags: () => ['Invoices'],
    }),
    getOne: build.query<InvoicesType, string>({
      query: (id) => ({
        url: `/business-invoices/${id}`,
      }),
      providesTags: () => ['Invoices'],
    }),
    create: build.mutation<InvoicesType, any>({
      query: (body) => ({
        url: '/business-invoices',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Invoices'],
    }),
    update: build.mutation<InvoicesType, any>({
      query: (body) => ({
        url: `/business-invoices/${body.invoiceId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Invoices'],
    }),
    changeStatus: build.mutation<void, {id: number, status: InvoicesStatus}>({
      query: (body) => ({
        url: `/business-invoices/${body.id}/change-status`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Invoices'],
    }),
    sendEmail: build.mutation<void, {id: number, emails: string[]}>({
      query: ({ id, ...params }) => ({
        url: `/business-invoices/${id}/customer-send?${qs.stringify(params)}`,
        method: 'GET',
      }),
      invalidatesTags: ['Invoices'],
    }),
    remove: build.mutation<void, number>({
      query: (id) => ({
        url: `/business-invoices/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Invoices'],
    }),
    generateDocument: build.mutation<void, number>({
      query: (id) => ({
        url: `/business-invoices/generate/${id}`,
        method: 'GET',
        responseHandler: async (response) => {
          const blob = await response.blob()
          const name = getFileName(response)
          downloadFile(blob, name)
        },
      }),
    }),

    getAllInvoices: build.query<any, { page: number, filters: any }>({
      query: (params) => ({ // @ts-ignore
        url: `/settings/get-all-invoices?${qs.stringify({ filters: Object.fromEntries(Object.entries(params.filters).filter(([, value]) => (value?.$gte || value?.$lte || value?.$eq)).map(([key, value]) => [key, value?.$eq ?? (value?.$gte || value?.$lte ? { ...(value.$gte && { from: value.$gte }), ...(value.$lte && { to: value.$lte }) } : value)])) })}`,
        params: {
          page: params.page,
        },
      }),
      providesTags: () => ['Invoices'],
    }),
  }),
})
