import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import { Col, Row } from 'antd'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { Button } from '@src/components/ui/Button/Button'
import { Preloader } from '@src/components/Preloader/Preloader'
import * as Yup from 'yup'
import style from './CarMakes.module.scss'

export const CarMakesCreate = () => {
  const { t } = useTranslation()

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      name: Yup.string().required(t('form.errors.required') ?? ''),
    })
  ), [t])

  const initialValues = useMemo(() => ({
    name: '',
  }), [])

  const onSubmit = useCallback(async () => {

  }, [])

  return (
    <Preloader loading={false}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {() => (
          <Form className={style.form}>
            <Row gutter={[8, 0]}>
              <Col xs={24} md={24}>
                <FieldFormik name="name" placeholder={t('carMakes.name') ?? ''} />
              </Col>
            </Row>
            <Button
              size="large"
              htmlType="submit"
              type="primary"
              style={{ marginTop: 'auto', alignSelf: 'flex-start' }}
            >
              {t('carMakes.create') ?? ''}
            </Button>
          </Form>
        )}
      </Formik>
    </Preloader>
  )
}
