import { FC, useEffect, useMemo, useState } from 'react'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'
import { Col, Drawer, Row } from 'antd'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { Button } from '@src/components/ui/Button/Button'
import style from './driver.module.scss'

type CustomerInfoProps = {
  isOpen: boolean,
  onClose: ()=>void,
}
export const DriverFilters:FC<CustomerInfoProps> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation()
  const [isMobile, setIsMobile] = useState(false)

  const initialValues = useMemo(() => ({
    date: '',
    cargo: '',
    from_id: '',
    to_id: '',
  }), [])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024)
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <Drawer
      title={(
        <div className={style.filtersTitle}>
          <h2>
            {t('table.filtersTitle')}
          </h2>
        </div>
      )}
      open={isOpen}
      onClose={onClose}
      width={554}
      mask={!isMobile}
      styles={{
        body: { display: 'flex', flexDirection: 'column', height: '100%' },
      }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={() => {}}
        enableReinitialize
      >
        {({ resetForm }) => (
          <Form style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <div className={style.cardContent}>
              <Row gutter={[8, 0]}>
                <Col xs={24} xl={12}>
                  <FieldFormik name="date" placeholder={t('transportation.columns.date') ?? ''} />
                </Col>
                <Col xs={24} xl={12}>
                  <FieldFormik name="cargo" placeholder={t('transportation.columns.cargo') ?? ''} />
                </Col>
                <Col xs={24} xl={12}>
                  <FieldFormik name="from_id" placeholder={t('transportation.columns.from') ?? ''} />
                </Col>
                <Col xs={24} xl={12}>
                  <FieldFormik name="to_id" placeholder={t('transportation.columns.to') ?? ''} />
                </Col>
              </Row>
            </div>
            <div className={style.cardFooter}>
              <Button
                className={style.styleButton}
                type="secondary"
                size="large"
                style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
                onClick={() => resetForm()}
              >
                {t('menu.cancelAll') ?? ''}
              </Button>
              <Button
                className={style.styleButton}
                htmlType="submit"
                type="primary"
                size="large"
                style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
              >
                {t('pageTable.create') ?? ''}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Drawer>
  )
}
