import { useMemo, useCallback, useTransition, FC } from 'react'
import { useTranslation } from 'react-i18next'
import useQuery from '@src/hooks/useQuery'
import { AgreementInvoicesStatus } from '@src/types/agreementInvoices'
import { TableFiltersType } from '@src/types/tableFilters'
import { companiesApi } from '@src/store/services/companies-service'
import { CompanyType } from '@src/types/company'
import { carsApi } from '@src/store/services/cars-service'
import { PageTable } from '@src/components/Table/PageTable'
import { agreementsApi } from '@src/store/services/agreements-service'
import { useAgreementInvoicesTableHook } from '@src/components/pages/agreementInvoices/AgreementInvoicesTable.hook'

type AgreementInvoicesProps = {
  isTableOnly?: boolean;
  companyId?: number;
  companyType?: CompanyType;
  filters?: any;
  withoutParamsFilters?: boolean;
}

export const AgreementInvoices: FC<AgreementInvoicesProps> = ({ isTableOnly, companyId, companyType, filters, withoutParamsFilters }) => {
  const { t } = useTranslation()
  const { data: ownersSelect } = companiesApi.useGetSelectCompaniesQuery(CompanyType.Owner)
  const { data: customerNamesList } = companiesApi.useGetSelectCompaniesQuery(CompanyType.Customer)
  const { data: modelsList } = carsApi.useGetCarsModelsQuery()
  const { data: makesList } = carsApi.useGetCarsMakesQuery()
  const pageQuery = useQuery('page')
  const filtersQuery = useQuery('filters')
  const [exportAgreementInvoices, { isLoading: isLoadingExport }] = agreementsApi.useExportAgreementInvoicesMutation()

  const [isPending, startTransition] = useTransition()
  const { columns, paramsFilters, setParamsFilters, isLoading, data } = useAgreementInvoicesTableHook({
    initParamsFilters: filters || {
      page: pageQuery ? +pageQuery : 1,
      filters: {
        ...(companyId && companyType === CompanyType.Customer
          ? { 'order.agreement.customer_id': { $eq: companyId } }
          : {}),
        ...(companyId && companyType === CompanyType.Owner
          ? { 'order.agreement.owner_id': { $eq: companyId } }
          : {}),
        ...(filtersQuery ? JSON.parse(filtersQuery) : {}),
      },
    },
    withoutParamsFilters,
  })

  const handleFilterChange = useCallback((filter: any) => {
    startTransition(() => {
      setParamsFilters(filter)
    })
  }, [setParamsFilters])

  const filtersList = useMemo<TableFiltersType>(
    () => [
      [
        {
          type: 'select',
          dataIndex: 'company_id',
          placeholder: t('agreementInvoices.columns.customer'),
          width: '200px',
          operator: '$eq',
          options: customerNamesList?.map((item) => ({ value: item.id, label: item.name })) || [],
          showSearch: true,
        },
        {
          type: 'select',
          dataIndex: 'order.agreement.owner_id',
          placeholder: t('cars.columns.owner'),
          width: '200px',
          operator: '$eq',
          options: ownersSelect?.map((owner) => ({ value: owner.id, label: owner.name })) || [],
        },
        {
          type: 'input',
          dataIndex: 'bill_number',
          operator: '$contains',
          placeholder: t('agreementInvoices.columns.number'),
          width: '150px',
        },
        {
          type: 'select',
          dataIndex: 'type',
          placeholder: t('agreementInvoices.columns.type.title'),
          width: '150px',
          operator: '$eq',
          options: [
            { value: 'periodic', label: t('agreementInvoices.columns.type.periodic') },
            { value: 'last', label: t('agreementInvoices.columns.type.last') },
          ],
        },
      ],
      [
        {
          type: 'select',
          dataIndex: 'order.car.make',
          placeholder: t('agreementBills.columns.make'),
          width: '200px',
          operator: '$eq',
          options: makesList?.map((item) => ({ value: item, label: item })) || [],
          showSearch: true,
        },
        {
          type: 'select',
          dataIndex: 'order.car.model',
          placeholder: t('agreementBills.columns.model'),
          width: '200px',
          operator: '$eq',
          options: modelsList?.map((item) => ({ value: item, label: item })) || [],
          showSearch: true,
        },
        {
          type: 'input',
          dataIndex: 'order.car.serial_number',
          placeholder: t('agreementBills.columns.serialNumber'),
          width: '150px',
          operator: '$contains',
        },
      ],
      [
        {
          type: 'select',
          dataIndex: 'status',
          placeholder: t('agreementInvoices.columns.status'),
          width: '180px',
          operator: '$eq',
          options: [
            { value: AgreementInvoicesStatus.notProcessed, label: t(`agreementInvoices.status.${AgreementInvoicesStatus.notProcessed}`) },
            { value: AgreementInvoicesStatus.processed, label: t(`agreementInvoices.status.${AgreementInvoicesStatus.processed}`) },
            { value: AgreementInvoicesStatus.archived, label: t(`agreementInvoices.status.${AgreementInvoicesStatus.archived}`) },
          ],
        },
        {
          type: 'select',
          dataIndex: 'is_sent_accountant',
          placeholder: t('agreementInvoices.columns.sentAccountant'),
          width: '180px',
          operator: '$eq',
          options: [
            { value: '0', label: t('sendStatus.0') },
            { value: '1', label: t('sendStatus.1') },
          ],
        },
        {
          type: 'select',
          dataIndex: 'is_sent_customer',
          placeholder: t('agreementInvoices.columns.sentCustomer'),
          width: '180px',
          operator: '$eq',
          options: [
            { value: '0', label: t('sendStatus.0') },
            { value: '1', label: t('sendStatus.1') },
          ],
        },
      ],
      [
        {
          type: 'rangePicker',
          dataIndex: 'date_start',
          operator: '$eq',
          placeholder: [t('filters.dateStartFrom'), t('filters.dateStartTo')],
        },
        {
          type: 'rangePicker',
          dataIndex: 'date_end',
          operator: '$eq',
          placeholder: [t('filters.dateEndFrom'), t('filters.dateEndTo')],
        },
      ],
    ],
    [customerNamesList, makesList, modelsList, ownersSelect, t],
  )

  return (
    <div>
      <PageTable
        title={t('agreementInvoices.title')!}
        filtersList={filtersList}
        filtersV2
        isTableOnly={isTableOnly}
        setParamsFilters={handleFilterChange}
        paramsFilters={paramsFilters}
        onExport={!isTableOnly && exportAgreementInvoices}
        isLoadingExport={isLoadingExport}
        tableData={{
          ...data,
          isLoading: isLoading || isPending,
          columns,
          components: {
            body: {
              row: (props: any) => (
                <tr
                  {...props}
                  style={{
                    height: '64px',
                    background: data?.data.find((item: { id: any; }) => item.id === props['data-row-key'])?.order?.agreement.owner.color,
                  }}
                />
              ),
            },
          },
        }}
      />
    </div>
  )
}
