import { FC } from 'react'

type CompanyRowLabeledProps = {
  label: string
  company: string
}

export const CompanyRowLabeled: FC<CompanyRowLabeledProps> = ({ label, company }) => (
  <p className="text" style={{ width: '100%' }}>
    <span style={{ width: '100%' }}>
      <span style={{ float: 'left', width: '11%' }}>
        {label}
        :
      </span>
      <span style={{ borderBottom: 'solid 1px #000000', width: '89%', display: 'block', marginLeft: '11%' }}>
        {company}
      </span>
    </span>
    <span className="text" style={{ textAlign: 'center', display: 'inline-block', width: '100%', fontSize: '12px' }}>
      (pavadinimas, įmonės kodas arba vardas pavardė, asmens kodas)
    </span>
  </p>

)
