import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'antd'
import { ReactComponent as PointsIcon } from '@src/assets/points_icon.svg'
import { ReactComponent as Border } from '@src/assets/border-popover_icon.svg'
import styles from './transportationSign.module.scss'

type Route = {
  id: number
  address_from?: any
  address_to?: any
  cargo?: any
  distance?: any
}

type CustomTooltipProps = {
  routes: Route[]
}

export const TransportationTooltip: FC<CustomTooltipProps> = ({ routes }) => {
  const { t } = useTranslation()
  const tooltipContent = (
    <div className={styles.tooltipContent}>
      {routes.map((route, index) => (
        <div key={route.id}>
          <div className={styles.tooltipItem}>
            <div className={styles.routeNumber}>{index + 1}</div>
            <div className={styles.routeDetails}>
              <div style={{ display: 'flex', gap: '8px' }}>
                <span className={styles.label}>{t('transportation.columns.points.from')}</span>
                <span className={styles.address}>{route.address_from}</span>
              </div>
              <div style={{ display: 'flex', gap: '8px' }}>
                <span className={styles.label}>{t('transportation.columns.points.to')}</span>
                <span className={styles.address}>{route.address_to}</span>
              </div>
              {route.cargo?.name && (
                <div style={{ display: 'flex', gap: '8px' }}>
                  <span className={styles.label}>{t('transportation.columns.points.cargo')}</span>
                  <span className={styles.address}>{route.cargo?.name}</span>
                </div>
              )}
            </div>
            <Border style={{ height: !route.cargo?.name ? '40px' : '64px' }} />
            <div
              style={{ height: !route.cargo?.name ? '40px' : '64px' }}
              className={styles.distance}
            >
              {`${route?.distance} km`}
            </div>
          </div>
        </div>
      ))}
    </div>
  )

  return (
    <Tooltip placement="bottomLeft" title={tooltipContent} overlayClassName={styles.customTooltip}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <PointsIcon />
        <span>{`${routes.length} ${t('transportation.columns.points.title')}`}</span>
      </div>
    </Tooltip>
  )
}
