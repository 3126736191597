import { ReactNode, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Card, Col, Row } from 'antd'
import { ReactComponent as PlusIcon } from '@src/assets/plus_icon.svg'
import { ViewContentHeader } from '@src/components/ViewContentHeader/ViewContentHeader'
import { Button as PrimaryButton } from '@src/components/ui/Button/Button'
import { agreementsApi } from '@src/store/services/agreements-service'
import { DetailsCard } from '@src/components/DetailsCard/DetailsCard'
import { AgreementOrders } from '../AgreementOrders/AgreementOrders'
import { AgreementInvoices } from '../AgreementInvoices/AgreementInvoices'
import style from './agreements.module.scss'

export const AgreementView = () => {
  const { t } = useTranslation()
  const params = useParams<{ id: string }>()
  const history = useHistory()
  const [activeTabKey, setActiveTabKey] = useState<string>('orders')
  const { data: info, isLoading } = agreementsApi.useGetOneAgreementQuery(+params.id!, { skip: !params.id })

  const invoiceFilters = useMemo(() => ({
    page: 1,
    filters: {
      order: {
        agreement: {
          id: {
            $eq: params.id,
          },
        },
      },
    },
  }), [params.id])

  const orderFilters = useMemo(() => ({
    page: 1,
    filters: {
      agreement: {
        id: {
          $eq: params.id,
        },
      },
    },
  }), [params.id])

  const detailsData = [
    { label: t('agreements.columns.number'), value: info?.agreement_number },
    { label: t('agreements.columns.owner'), value: info?.owner?.full_name },
    { label: t('agreements.columns.ownerRepresentative'), value: info?.owner_representative?.name },
    { label: t('agreements.columns.customer'), value: info?.customer.full_name },
    { label: t('agreements.columns.customerRepresentative'), value: info?.customer_representative?.name },
    { label: t('agreementsCreate.date'), value: info?.date },
  ]

  const contentList: Record<string, ReactNode> = useMemo(() => ({
    orders: (
      <AgreementOrders
        isTableOnly
        companyId={+params.id}
        filters={orderFilters}
        withoutParamsFilters
      />
    ),
    invoices: (
      <AgreementInvoices
        isTableOnly
        companyId={+params.id}
        filters={invoiceFilters}
        withoutParamsFilters
      />
    ),
  }), [invoiceFilters, orderFilters, params.id])

  const tabList = useMemo(() => ([
    { key: 'orders', label: t('menu.orders') },
    { key: 'invoices', label: t('invoices.title') },
  ]), [t])

  return (
    <div>
      {info && (
        <ViewContentHeader
          onBackClick={() => history.goBack()}
        >
          {`${t('menu.agreements')} ${info?.agreement_number} (${info?.owner.full_name} - ${info?.customer.full_name})`}
        </ViewContentHeader>
      )}
      <h2 className={style.title}>
        {t('agreementsCreate.agreementInfoBlock')}
      </h2>
      <Row gutter={[24, 24]}>
        <Col xs={24} xl={12}>
          <DetailsCard
            data={detailsData}
            isLoading={isLoading}
            buttonLabel={t('customerCreate.edit')}
            buttonAction={() => history.push(`/agreements/edit/${params.id}`)}
            showButton
          />
        </Col>
        <Col xs={24} xl={12}>
          <Card
            className={style.cardAgreementStyle}
            activeTabKey={activeTabKey}
            onTabChange={setActiveTabKey}
            tabList={tabList}
            tabProps={{
              size: 'middle',
            }}
            tabBarExtraContent={
              activeTabKey === 'orders' && (
                <PrimaryButton
                  leftIcon={<PlusIcon />}
                  className={style.button}
                  size="large"
                  type="primary"
                  onClick={() => history.push('/agreement-orders/create')}
                >
                  {t('customerCreate.submit')}
                </PrimaryButton>
              )
            }
          >
            {contentList[activeTabKey]}
          </Card>
        </Col>
      </Row>
    </div>
  )
}
