import { FC } from 'react'

type SignatureRowLabeledProps = {
  label: string
  person: string
  signature: string
}

export const SignatureRowLabeled: FC<SignatureRowLabeledProps> = ({ label, person, signature }) => (
  <p className="text">
    <span style={{ width: '100%', display: 'inline-block' }}>
      <span style={{ float: 'left', width: '20%' }}>
        {label}
        :
      </span>
      <span style={{ borderBottom: 'solid 1px #000000', width: '80%', display: 'block', marginLeft: '20%' }}>
        <span>{person}</span>
        {signature && (
          <img src={signature} style={{ width: 120 }} alt="" />
        )}
      </span>
    </span>
    <br />
    <span
      className="text"
      style={{ textAlign: 'center', display: 'inline-block', width: '100%', marginLeft: '10%', fontSize: 12 }}
    >
      (pareigos, vardas, pavardė, parašas)
    </span>
  </p>
)
