import { FC, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button as ButtonAntd, Layout, Menu } from 'antd'
import { AppDispatch } from '@src/store/store'
import { useTranslation } from 'react-i18next'
import { NavLink, useLocation } from 'react-router-dom'
import { skipToken } from '@reduxjs/toolkit/query/react'
import { ReactComponent as Logo } from '@src/assets/logo.svg'
import { ReactComponent as UserIcon } from '@src/assets/user_icon.svg'
import { settingsApi } from '@src/store/services/settings-service'
import { usePageFocus } from '@src/hooks/usePageFocus'
import { ReactComponent as LogoutIcon } from '@src/assets/logout_icon.svg'
import { useWindowSize } from '@src/hooks/useWindowSize'
import cn from 'classnames'
import { authApi } from '@src/store/services/auth-service'
import { UserRoles } from '@src/types/users'
import { setCookie } from '@src/lib/cookie'
import { ReactComponent as CloseMobileMenuIcon } from '@src/assets/close-mobile-menu_icon.svg'
import { DownCircleOutlined } from '@ant-design/icons'
import { ReactComponent as OpenMenuIcon } from '@src/assets/open-menu_icon.svg'
import { ReactComponent as CloseMenuIcon } from '@src/assets/close-menu_icon.svg'
import { ReactComponent as OpenMobileMunuIcon } from '@src/assets/open-mobile-menu_icon.svg'
import { Button } from '@src/components/ui/Button/Button'
import { useMenuList } from './menuList'
import style from './SitebarLeft.module.scss'
import ltLogo from '../../assets/lt.png'
import ukLogo from '../../assets/uk.png'

type SitebarLeftProps = {
  isOpenMenu: boolean
}

export const SitebarLeft: FC<SitebarLeftProps> = ({ isOpenMenu }) => {
  const { width } = useWindowSize()
  const location = useLocation()
  const isPageFocus = usePageFocus()
  const { data: user } = authApi.useGetMeQuery()
  const dispatch = useDispatch<AppDispatch>()
  const { t, i18n } = useTranslation()
  const [logout] = authApi.useLogoutMutation()
  const [formatBillNumber, setFormatBillNumber] = useState('')
  const { data: lastBillNumber, refetch } = settingsApi.useGetLastNumberQuery(isPageFocus ? undefined : skipToken, {
    // pollingInterval: 5000, todo
    skip: user?.role !== UserRoles.Admin,
  })

  const menuList = useMenuList()
  const [menuCollapsed, setMenuCollapsed] = useState(false)
  const [isFullScreenMenuVisible, setFullScreenMenuVisible] = useState(false)

  const handleLogout = async () => {
    await logout()
    localStorage.removeItem('token')
    dispatch(authApi.util.resetApiState())
  }

  function onLangChange(lang: string) {
    i18n.changeLanguage(lang)
    setCookie('lang', lang)
  }

  const collapsedMenu = useMemo(() => {
    if (width > 1024) {
      return menuCollapsed
    }
    return false
  }, [menuCollapsed, width])

  useEffect(() => {
    if (isPageFocus && user?.role === UserRoles.Admin) {
      refetch()
    }
  }, [isPageFocus, refetch, user])

  useEffect(() => {
    if (lastBillNumber) {
      setFormatBillNumber(lastBillNumber > 1000 ? `0${lastBillNumber}` : `00${lastBillNumber}`)
    }
  }, [lastBillNumber])

  useEffect(() => {
    if (user?.role === UserRoles.Driver) {
      i18n.changeLanguage('lt')
      setCookie('lang', 'lt')
    }
  }, [user, i18n])

  const toggleMenu = () => {
    setMenuCollapsed((prev) => !prev)
  }

  const toggleFullScreenMenu = () => {
    setFullScreenMenuVisible((prev) => !prev)
  }

  const isMobile = width <= 1024

  useEffect(() => {
    if (isMobile) {
      setFullScreenMenuVisible(false)
    }
  }, [location, isMobile])

  const renderLogo = () => {
    if (user && (user.role === UserRoles.Admin || user.role === UserRoles.Driver)) {
      return collapsedMenu ? <OpenMenuIcon /> : <Logo className={style.mobileLogo} />
    }
    return <div>CRM</div>
  }

  return (
    <>
      {(isMobile || user?.role === UserRoles.Driver) && (
        <Layout.Header className={style.mobileHeader}>
          <div className={style.mobileHeaderContent}>
            <NavLink to="/" className={style.mobileLogo}>
              {renderLogo()}
            </NavLink>
            <div className={style.navWrapper}>
              {formatBillNumber && user?.role === UserRoles.Admin && (
                <NavLink to="/all-invoices" className={cn(style.lastNumber, { [style.lastNumber]: menuCollapsed })}>
                  <ButtonAntd
                    type="primary"
                    style={{ width: '95%', height: '38px' }}
                    className={cn(style.lastNumberButton, { [style.collapsedButton]: menuCollapsed })}
                  >
                    {formatBillNumber}
                  </ButtonAntd>
                </NavLink>
              )}
              {user?.role === UserRoles.Driver ? (
                <Button
                  type="secondary"
                  leftIcon={<LogoutIcon />}
                  onClick={handleLogout}
                  style={{ color: '#E94444' }}
                >
                  {t('menu.logOut')}
                </Button>
              ) : (
                <ButtonAntd
                  type="text"
                  className={style.menuToggleBtn}
                  onClick={toggleFullScreenMenu}
                >
                  {isFullScreenMenuVisible ? <CloseMobileMenuIcon /> : <OpenMobileMunuIcon />}
                </ButtonAntd>
              )}
            </div>
          </div>
          {isFullScreenMenuVisible && (
            <div className={style.fullScreenMenu}>
              <div className={style.mobileHeaderContent}>
                <NavLink to="/" className={style.mobileLogo}>
                  {renderLogo()}
                </NavLink>
                <div className={style.navWrapper}>
                  {formatBillNumber && user?.role === UserRoles.Admin && (
                    <NavLink to="/all-invoices" className={cn(style.lastNumber, { [style.lastNumber]: menuCollapsed })}>
                      <ButtonAntd
                        type="primary"
                        style={{ width: '95%', height: '38px' }}
                        className={cn(style.lastNumberButton, { [style.collapsedButton]: menuCollapsed })}
                      >
                        {formatBillNumber}
                      </ButtonAntd>
                    </NavLink>
                  )}
                  <ButtonAntd
                    type="text"
                    className={style.menuToggleBtn}
                    onClick={toggleFullScreenMenu}
                  >
                    <CloseMobileMenuIcon />
                  </ButtonAntd>
                </div>
              </div>
              <Menu
                mode="inline"
                selectedKeys={[`/${location.pathname.split('/')[1]}`]}
                className={style.menu}
                items={[
                  ...menuList,
                  {
                    key: 'user',
                    label: (
                      <div className={cn(style.lang, { [style.langCollapsed]: menuCollapsed })}>
                        <UserIcon />
                        <p className={cn(style.userName, { [style.userNameCollapsed]: menuCollapsed })}>
                          {`${user?.name} ${user?.surname}`}
                        </p>
                        {!menuCollapsed && (
                          <ButtonAntd
                            className={style.langBtn}
                            type="text"
                            onClick={() => onLangChange(i18n.language === 'lt' ? 'en' : 'lt')}
                          >
                            <img
                              className={style.logoLangLocations}
                              src={i18n.language === 'lt' ? ltLogo : ukLogo}
                              alt={i18n.language === 'lt' ? 'ltLang' : 'ukLang'}
                            />
                          </ButtonAntd>
                        )}
                      </div>
                    ),
                  },
                  {
                    key: 'logout',
                    label: (
                      <div
                        role="button"
                        tabIndex={0}
                        className={style.logOutMobileMenuItem}
                        onClick={handleLogout}
                      >
                        <LogoutIcon />
                        {t('menu.logOut')}
                      </div>
                    ),
                  },
                ]}
                expandIcon={({ isOpen }) => (
                  <DownCircleOutlined
                    style={{
                      transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                      transition: 'transform 0.3s',
                      marginLeft: 'auto',
                    }}
                  />
                )}
              />
            </div>
          )}
        </Layout.Header>
      )}
      {!isMobile && user?.role !== UserRoles.Driver && (
        <Layout.Sider
          trigger={null}
          collapsible
          collapsed={collapsedMenu}
          width={menuCollapsed ? 64 : 260}
          theme="light"
          className={cn(style.leftSitebar, { [style.leftSitebarOpen]: isOpenMenu })}
        >
          <div className={cn(style.logoContainer, { [style.collapsed]: menuCollapsed })}>
            <NavLink to="/" className={cn(style.logo, { [style.collapsed]: menuCollapsed })}>
              {renderLogo()}
            </NavLink>
            <CloseMenuIcon style={{ margin: '16px', cursor: 'pointer' }} onClick={toggleMenu} />
          </div>
          <div className={style.menuWrapper}>
            <Menu
              mode="inline"
              selectedKeys={[`/${location.pathname.split('/')[1]}`]}
              className={cn(style.menu, { [style.collapsed]: !collapsedMenu })}
              items={menuList}
              expandIcon={({ isOpen }) => (
                <DownCircleOutlined
                  style={{
                    transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform 0.3s',
                    marginLeft: 'auto',
                  }}
                />
              )}
            />
            <div className={style.endContainer}>
              {formatBillNumber && user?.role === UserRoles.Admin && (
                <NavLink to="/all-invoices" className={cn(style.lastNumber, { [style.lastNumber]: menuCollapsed })}>
                  <ButtonAntd
                    type="primary"
                    style={{ width: '95%', height: '38px' }}
                    className={cn(style.lastNumberButton, { [style.collapsedButton]: menuCollapsed })}
                  >
                    {formatBillNumber}
                  </ButtonAntd>
                </NavLink>
              )}
              <div className={cn(style.lang, { [style.langCollapsed]: menuCollapsed })}>
                <UserIcon />
                <p className={cn(style.userName, { [style.userNameCollapsed]: menuCollapsed })}>
                  {`${user?.name} ${user?.surname}`}
                </p>
                {!menuCollapsed && (
                  <ButtonAntd
                    className={style.langBtn}
                    type="text"
                    onClick={() => onLangChange(i18n.language === 'lt' ? 'en' : 'lt')}
                  >
                    <img
                      className={style.logoLangLocations}
                      src={i18n.language === 'lt' ? ltLogo : ukLogo}
                      alt={i18n.language === 'lt' ? 'ltLang' : 'ukLang'}
                    />
                  </ButtonAntd>
                )}
              </div>
              <ButtonAntd
                icon={<LogoutIcon />}
                className={cn(style.logOutButton, { [style.collapsedButton]: menuCollapsed })}
                onClick={handleLogout}
              >
                {!menuCollapsed && t('menu.logOut')}
              </ButtonAntd>
            </div>
          </div>
        </Layout.Sider>
      )}
    </>
  )
}
