import React, { ReactNode } from 'react'
import { Button } from 'antd'
import { ReactComponent as BackButtonIcon } from '@src/assets/back-button_icon.svg'
import { useHistory } from 'react-router-dom'

type ViewContentHeaderProps = {
  onBackClick?: () => void
  children: ReactNode
}

export const ViewContentHeader: React.FC<ViewContentHeaderProps> = ({ onBackClick, children }) => {
  const history = useHistory()

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '16px', margin: '0 0 24px 0' }}>
      <Button
        onClick={() => (onBackClick ? onBackClick() : history.goBack())}
        type="link"
        icon={<BackButtonIcon />}
        style={{ background: 'none', boxShadow: 'none' }}
      />
      <h1 style={{ color: '#22262B', fontSize: '24px', fontWeight: 600, lineHeight: '32px', margin: '0' }}>
        {children}
      </h1>
    </div>
  )
}
