import React, { FC, useMemo } from 'react'
import { Form, Formik } from 'formik'
import { Button, Card, Col, Drawer, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { SignatureFormik } from '@src/components/SignatureFormik/SignatureFormik'
import { transportationApi } from '@src/store/services/transportation-service'
import { Preloader } from '@src/components/Preloader/Preloader'
import style from './transportationSign.module.scss'

type TransportationSignProps = {
  onClose: () => void
  transportationId: number | null
}

export const TransportationSign: FC<TransportationSignProps> = ({
  onClose,
  transportationId,
}) => {
  const { t } = useTranslation()
  const [signTransportation, { isLoading }] = transportationApi.useSignTransportationMutation()
  const { data, isLoading: isLoadingGetOne } = transportationApi.useGetOneTransportationQuery(transportationId!, { skip: !transportationId })

  const validationSignSchema = useMemo(() => (
    Yup.object({
      signature: Yup.string().required(t('form.errors.required') ?? ''),
      customer_name: Yup.string().when('activeTabKey', {
        is: 'customer',
        then: () => Yup.string().required(t('form.errors.required')),
      }),
      customer_surname: Yup.string().when('activeTabKey', {
        is: 'customer',
        then: () => Yup.string().required(t('form.errors.required')),
      }),
    })
  ), [t])

  const initialValues = useMemo(
    () => ({
      activeTabKey: !data?.recipient_signature ? 'customer' : 'driver',
      customer_name: data?.customer_name || '',
      customer_surname: data?.customer_surname || '',
      driver_name: data?.driver?.name || '',
      driver_surname: data?.driver?.surname || '',
      signature: '',
    }),
    [data],
  )

  const onSubmit = async (values: any, { resetForm }: { resetForm: () => void }) => {
    const result = await signTransportation({
      id: transportationId!,
      body: {
        customer_name: values.activeTabKey === 'customer' ? values.customer_name : undefined,
        customer_surname: values.activeTabKey === 'customer' ? values.customer_surname : undefined,
        signature: values.signature,
        signature_type: values.activeTabKey,
      },
    })

    if ('data' in result) {
      onClose()
      resetForm()
    }
  }

  const tabList = useMemo(() => [
    { key: 'customer', label: t('menu.customers'), disabled: !!data?.recipient_signature },
    { key: 'driver', label: t('transportation.sign.driver'), disabled: !!data?.driver_signature },
  ], [data, t])

  return (
    <Drawer
      title={t('transportationCreate.customerSign')}
      open={!!transportationId}
      onClose={onClose}
      width={554}
      styles={{
        body: {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          padding: 24,
        },
      }}
    >
      <Preloader loading={isLoadingGetOne}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSignSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ values, setFieldValue }) => (
            <Form className="not-card">
              <Card
                className={style.carCardStyle}
                loading={isLoading}
                activeTabKey={values.activeTabKey}
                onTabChange={(key: any) => setFieldValue('activeTabKey', key)}
                tabList={tabList}
                tabProps={{
                  size: 'middle',
                }}
              >
                <Row gutter={[8, 0]}>
                  <Col xs={24} md={12}>
                    <FieldFormik
                      name={`${values.activeTabKey}_name`}
                      placeholder={t(`transportation.sign.${values.activeTabKey}Name`)}
                      disabled={values.activeTabKey === 'driver'}
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <FieldFormik
                      name={`${values.activeTabKey}_surname`}
                      placeholder={t(`transportation.sign.${values.activeTabKey}Surname`)}
                      disabled={values.activeTabKey === 'driver'}
                    />
                  </Col>
                  <Col span={24}>
                    <SignatureFormik
                      name="signature"
                      placeholder={t('transportationCreate.signature') ?? ''}
                    />
                  </Col>
                </Row>
              </Card>
              <Button
                className="card-button"
                htmlType="submit"
                type="primary"
                block
                loading={isLoading}
              >
                {t('transportationCreate.sign')}
              </Button>
            </Form>
          )}
        </Formik>
      </Preloader>
    </Drawer>
  )
}
