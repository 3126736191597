import { FC, useMemo } from 'react'
import { Button, Col, Row } from 'antd'
import { Form, Formik } from 'formik'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { Preloader } from '@src/components/Preloader/Preloader'
import { SelectFormik } from '@src/components/SelectFormik/SelectFormik'
import { trucksApi } from '@src/store/services/trucks-service'
import { usersApi } from '@src/store/services/users-service'
import { UserRoles } from '@src/types/users'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@src/store/store'
import { transportationApi } from '@src/store/services/transportation-service'

type TruckCreateProps = {
  record?: any
  onClose: any
}

export const TrucksCreate: FC<TruckCreateProps> = ({ record, onClose }) => {
  const { t } = useTranslation()
  const params = useParams<{ id?: string }>()
  const dispatch = useDispatch<AppDispatch>()
  const { data: usersSelect } = usersApi.useGetUsersForSelectQuery(UserRoles.Driver)
  const { data, isLoading: isLoadingGetOne } = trucksApi.useGetOneTruckQuery(+record?.id!, { skip: !record?.id })
  const [create, { isLoading: isLoadingCreate }] = trucksApi.useCreateTruckMutation()
  const [edit, { isLoading: isLoadingEdit }] = trucksApi.useEditTruckMutation()

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      make: Yup.string().required(t('form.errors.required') ?? ''),
      model: Yup.string().required(t('form.errors.required') ?? ''),
      truck_plate: Yup.string().required(t('form.errors.required') ?? ''),
      trailer_plate: Yup.string().required(t('form.errors.required') ?? ''),
      user_id: Yup.string().required(t('form.errors.required') ?? ''),
    })
  ), [t])

  const initialValues = useMemo(() => ({
    id: data?.id || null,
    make: data?.make || '',
    model: data?.model || '',
    truck_plate: data?.truck_plate || '',
    trailer_plate: data?.trailer_plate || '',
    user_id: data?.user_id || '',
  }), [data])

  const onSubmit = async (values: any, { resetForm }: any) => {
    let result
    if (params.id) {
      result = await edit(values)
    } else {
      result = await create(values)
    }

    if ('data' in result) {
      dispatch(transportationApi.util.invalidateTags(['Transportation']))
      resetForm()
      onClose()
    }
  }

  return (
    <Preloader loading={isLoadingGetOne}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {() => (
          <Form className="not-card">
            <Row gutter={[8, 0]}>
              <Col xs={24} md={12}>
                <FieldFormik name="make" placeholder={t('trucks.columns.make') ?? ''} />
              </Col>
              <Col xs={24} md={12}>
                <FieldFormik name="model" placeholder={t('trucks.columns.model') ?? ''} />
              </Col>
              <Col xs={24} md={12}>
                <FieldFormik name="truck_plate" placeholder={t('trucks.columns.truckPlate') ?? ''} />
              </Col>
              <Col xs={24} md={12}>
                <FieldFormik name="trailer_plate" placeholder={t('trucks.columns.trailerPlate') ?? ''} />
              </Col>
              <Col xs={24} md={24}>
                <SelectFormik
                  name="user_id"
                  options={usersSelect?.map((item) => ({ value: item.id, label: item.name })) || []}
                  placeholder={t('trucks.columns.driver') ?? ''}
                />
              </Col>
            </Row>
            <Button
              htmlType="submit"
              type="primary"
              loading={isLoadingCreate || isLoadingEdit}
              className="card-button"
            >
              {params.id ? t('trucks.save') : t('trucks.submit')}
            </Button>
          </Form>
        )}
      </Formik>
    </Preloader>
  )
}
