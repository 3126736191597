import { Button } from 'antd'
import { PrinterOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import { TransportationType } from '@src/types/transportation'
import moment from 'moment'
import { CompanyRowLabeled } from '@src/components/documents/CompanyRowLabeled'
import { SignatureRowLabeled } from '@src/components/documents/SignatureRowLabeled'

type TransportationDocumentProps = {
  values: TransportationType
}

export const TransportationDocument: FC<TransportationDocumentProps> = ({ values }) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="document" id="printable-block">
        <p className="font-bold" style={{ textAlign: 'center', textTransform: 'uppercase' }}>
          Krovinio važtaraštis Nr.
          {' '}
          {moment(values.created_at).format('YYYY-MM-DD')}
          /
          {values.id}
        </p>
        <p className="text" style={{ textAlign: 'center', margin: -8 }}>
          Data:
          {' '}
          {values.date}
        </p>
        <CompanyRowLabeled label="Siuntėjas" company={`${values.from?.full_name}, ${values.from?.company_code}`} />
        <CompanyRowLabeled label="Gavėjas" company={`${values.to?.full_name}, ${values.to?.company_code}`} />
        <CompanyRowLabeled label="Vežėjas" company="UAB Senresa, 304865983" />

        <p className="text">
          Vilkiko/puspriekabes valst. nr.
          {' '}
          {values.truck?.truck_plate}
          /
          {values.truck?.trailer_plate}
        </p>

        <p className="text" style={{ fontWeight: 'bold', marginBottom: 0 }}>Transportavimo maršrutas i krovinio:</p>

        <table className="table" style={{ fontSize: 13, width: '100%' }}>
          <thead>
            <tr>
              <td style={{ width: '25%' }}>Adresas iš</td>
              <td style={{ width: '25%' }}>Adresas į</td>
              <td style={{ width: '25%', textAlign: 'center' }}>Krovinio pavadinimas</td>
              <td style={{ width: '7.5%', textAlign: 'center' }}>Mato vnt.</td>
              <td style={{ width: '7.5%', textAlign: 'center' }}>Kiekis</td>
              <td style={{ width: '10%', textAlign: 'center' }}>Krovinio svoris</td>
            </tr>
          </thead>
          <tbody>
            {values.points?.map((point) => (
              <tr key={point.address_from + point.address_to}>
                <td style={{ width: '25%' }}>{point.address_from}</td>
                <td style={{ width: '25%' }}>{point.address_to}</td>
                <td style={{ width: '25%', textAlign: 'center' }}>{point.cargo?.name}</td>
                <td style={{ width: '7.5%', textAlign: 'center' }}>{point.cargo?.unit}</td>
                <td style={{ width: '7.5%', textAlign: 'center' }}>{point.cargo?.amount}</td>
                <td style={{ width: '10%', textAlign: 'center' }}>{point.cargo?.weight}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <p className="text">
          Krovinio išgabenimo data ir laikas:
          {' '}
          {values.date}
        </p>

        <SignatureRowLabeled label="Krovinį išdavė" person={values.cargo_issued!} signature="" />
        <SignatureRowLabeled
          label="Krovinį priemė vežti"
          person={values.driver?.full_name!}
          signature={values.driver_signature!}
        />
        <SignatureRowLabeled
          label="Krovinį gavo"
          person={`${values.customer_name} ${values.customer_surname}`}
          signature={values.recipient_signature!}
        />
      </div>
      <div className="documentActions">
        <Button icon={<PrinterOutlined />} onClick={window.print}>{t('settings.print')}</Button>
      </div>
    </>
  )
}
