import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Card, Col, Drawer, Row } from 'antd'
import { useParams } from 'react-router-dom'
import useQuery from '@src/hooks/useQuery'
import { PageTable } from '@src/components/Table/PageTable'
import { ReactComponent as EditIcon } from '@src/assets/edit_icon.svg'
import { ViewContentHeader } from '@src/components/ViewContentHeader/ViewContentHeader'
import { trucksApi } from '@src/store/services/trucks-service'
import { useTransportationTable } from '@src/components/pages/transportation/TransportationTable.hook'
import { TrucksCreate } from './TrucksCreate'
import style from './trucks.module.scss'

export const TrucksView = () => {
  const { t } = useTranslation()
  const pageQuery = useQuery('page')
  const params = useParams<{ id: string }>()
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)
  const { data, isFetching: isLoadingGetOne } = trucksApi.useGetOneTruckQuery(+params?.id!, { skip: !params?.id })

  const { paramsFilters, setParamsFilters, columns, data: transportationData, isLoading, TransportationsComponents } = useTransportationTable({
    initParamsFilters: {
      page: pageQuery ? +pageQuery : 1,
      filters: {
        truck_id: {
          $eq: params.id,
        },
      },
    },
  })

  const openDrawer = () => setIsDrawerVisible(true)
  const closeDrawer = () => setIsDrawerVisible(false)

  return (
    <div className={style.categoriesContainer}>
      {data && (
        <ViewContentHeader>
          {`${data.make} ${data.model} | ${data.truck_plate}`}
        </ViewContentHeader>
      )}
      <Row gutter={[24, 24]}>
        <Col xs={24} lg={12}>
          <h2 className={style.title}>
            {t('trucks.info')}
          </h2>
          <Card
            loading={isLoadingGetOne}
            style={{
              borderRadius: '16px',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                  <span className={style.cardText}>{`${t('trucks.columns.make')}:`}</span>
                  <span className={style.cardTextResult}>{data?.make}</span>
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                  <span className={style.cardText}>{`${t('trucks.columns.model')}:`}</span>
                  <span className={style.cardTextResult}>{data?.model}</span>
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                  <span className={style.cardText}>{`${t('trucks.columns.truckPlate')}:`}</span>
                  <span className={style.cardTextResult}>{data?.truck_plate}</span>
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                  <span className={style.cardText}>{`${t('trucks.columns.trailerPlate')}:`}</span>
                  <span className={style.cardTextResult}>{data?.trailer_plate}</span>
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                  <span className={style.cardText}>{`${t('trucks.columns.driver')}:`}</span>
                  <span className={style.cardTextResult}>{data?.driver?.full_name}</span>
                </div>
              </div>
              <Button
                type="primary"
                icon={<EditIcon />}
                className="card-button"
                onClick={openDrawer}
              >
                {t('carCategories.edit')}
              </Button>
            </div>
          </Card>
        </Col>
        <Col xs={24} lg={12}>
          <PageTable
            isTableOnly
            paramsFilters={paramsFilters}
            setParamsFilters={setParamsFilters}
            columnsHeight="64px"
            tableData={{
              ...transportationData,
              isLoading,
              columns,
            }}
          />
        </Col>
        <Drawer
          title={t('trucks.editTitle')}
          open={isDrawerVisible}
          onClose={closeDrawer}
          width={554}
        >
          <TrucksCreate
            record={data}
            onClose={closeDrawer}
          />
        </Drawer>
      </Row>
      {TransportationsComponents}
    </div>
  )
}
