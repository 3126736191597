import { useTranslation } from 'react-i18next'
import { FC, useMemo } from 'react'
import * as Yup from 'yup'
import { carCategoriesApi } from '@src/store/services/car-categories-service'
import { Preloader } from '@src/components/Preloader/Preloader'
import { Form, Formik } from 'formik'
import { Button } from 'antd'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'

type CarCategoriesCreateProps = {
  record?: any,
  onClose: any,
}

export const CarCategoriesCreate: FC<CarCategoriesCreateProps> = ({ record, onClose }) => {
  const { t } = useTranslation()
  const { data, isFetching: isLoadingGetOne } = carCategoriesApi.useGetOneCarCategoryQuery(+record?.id!, { skip: !record?.id })
  const [create, { isLoading: isLoadingCreate }] = carCategoriesApi.useCreateCarCategoryMutation()
  const [edit, { isLoading: isLoadingEdit }] = carCategoriesApi.useEditCarCategoryMutation()

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      name: Yup.string().required(t('form.errors.required') ?? ''),
    })
  ), [t])

  const initialValues = useMemo(() => ({
    id: record?.id || data?.id || null,
    name: record?.name || data?.name || '',
  }), [record, data])

  const onSubmit = async (values: any) => {
    let result
    if (record?.id) {
      result = await edit(values)
    } else {
      result = await create(values)
    }

    if ('data' in result) {
      onClose()
    }
  }

  return (
    <Preloader loading={isLoadingGetOne}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {() => (
          <Form className="not-card" style={{ padding: 0 }}>
            <FieldFormik name="name" placeholder={t('carCategories.columns.name') ?? ''} />
            <Button
              className="card-button"
              htmlType="submit"
              type="primary"
              loading={isLoadingCreate || isLoadingEdit}
            >
              {t('carCategories.submit')}
            </Button>
          </Form>
        )}
      </Formik>
    </Preloader>
  )
}
