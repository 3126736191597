import { Fragment, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import { Button, Card, Col, Divider, Row } from 'antd'
import { useParams, useHistory } from 'react-router-dom'
import moment from 'moment'
import { Preloader } from '@src/components/Preloader/Preloader'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { DatePickerFormik } from '@src/components/DatePickerFormik/DatePickerFormik'
import { invoicesApi } from '@src/store/services/invoices-service'
import { SelectFormik } from '@src/components/SelectFormik/SelectFormik'
import * as Yup from 'yup'
import { companiesApi } from '@src/store/services/companies-service'
import { InvoicesTypeEnum, InvoicesUnitEnum } from '@src/types/invoices'
import { setSendEmail } from '@src/store/ducks/app/reducer'
import { SendEmailType } from '@src/types/mailLogs'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@src/store/store'
import { ViewContentHeader } from '@src/components/ViewContentHeader/ViewContentHeader'
import { ReactComponent as DeleteIcon } from '@src/assets/delete_icon.svg'
import { ReactComponent as PlusIcon } from '@src/assets/plus_icon.svg'
import { SwitchFormik } from '@src/components/SwitchFormik/SwitchFormik'
import { formatPrice } from '@src/lib/formatPrice'
import { InvoiceDefaultDocument } from '@src/components/documents/InvoiceDefaultDocument'
import style from './invoicesCreate.module.scss'

export const InvoicesCreate = () => {
  const { t } = useTranslation()
  const params = useParams<{ id?: string }>()
  const dispatch = useDispatch<AppDispatch>()
  const history = useHistory()
  const { data, isLoading } = invoicesApi.useGetOneQuery(params.id || '', { skip: !params.id })
  const [create, { isLoading: isLoadingCreate }] = invoicesApi.useCreateMutation()
  const [update, { isLoading: isLoadingUpdate }] = invoicesApi.useUpdateMutation()
  const { data: companiesSelect } = companiesApi.useGetSelectCompaniesQuery()

  const initialValues = useMemo(() => ({
    pay_by: data?.pay_by ? moment(data.pay_by) : '',
    sum: data?.sum || '',
    bill_number: data?.bill_number || '',
    is_credit: data?.is_credit || 0,
    date: data?.date ? moment(data.date) : '',
    company_id: data?.company_id || '',
    with_pvm: data?.with_pvm ?? true,
    type: data?.type || InvoicesTypeEnum.tax,
    services: data?.services || [{ name: '', unit: '', amount: '', value: '' }],
    vat_calculated: data?.vat_calculated ?? 1,
    comment: data?.comment || '',
  }), [data])

  const onSubmit = async (values: any) => {
    const totalSum = Array.isArray(values.services)
      ? values.services.reduce(
        (total: number, service: any) => total + (+service.amount || 0) * (+service.value || 0),
        0,
      )
      : 0

    const requestData = {
      ...values,
      services: values.services,
      date: values.date ? values.date.format('YYYY-MM-DD') : '',
      pay_by: values.pay_by ? values.pay_by.format('YYYY-MM-DD') : '',
      sum: totalSum,
    }

    let result
    if (params.id) {
      result = await update({
        ...requestData,
        invoiceId: params.id,
      })
    } else {
      result = await create(requestData)
    }

    if ('data' in result) {
      if (values.sum && values.bill_number) {
        dispatch(
          setSendEmail({
            type: SendEmailType.Invoices,
            companyId: +values.company_id,
            id: result.data.id ? +result.data.id : +params.id!,
          }),
        )
      }
      history.goBack()
    }
  }

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        company_id: Yup.string().required(t('form.errors.required') ?? ''),
        bill_number: Yup.string().required(t('form.errors.required') ?? ''),
        services: Yup.array().of(
          Yup.object().shape({
            name: Yup.string().required(t('form.errors.required') ?? ''),
            unit: Yup.string().required(t('form.errors.required') ?? ''),
            amount: Yup.string().required(t('form.errors.required') ?? ''),
            value: Yup.string().required(t('form.errors.required') ?? ''),
          }),
        ),
        date: Yup.string().required(t('form.errors.required') ?? ''),
        pay_by: Yup.string().required(t('form.errors.required') ?? ''),
      }),
    [t],
  )

  return (
    <div>
      <ViewContentHeader>
        {params.id ? t('invoicesCreate.titleEdit') : t('invoicesCreate.title')}
      </ViewContentHeader>
      <Preloader loading={isLoading}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => {
            const totalSum = Array.isArray(values.services)
              ? values.services.reduce(
                (total, service) => total + (+service.amount || 0) * (+service.value || 0),
                0,
              )
              : 0

            const pvm = values.vat_calculated ? (totalSum * 0.21).toFixed(2) : 0
            return (
              <Row gutter={[24, 24]}>
                <Col xl={12} xs={24}>
                  <Form style={{ background: '#F7F8F9', padding: 0 }}>
                    <h2 className={style.title}>
                      {t('invoicesCreate.invoiceInfoTitle')}
                    </h2>
                    <Card className={style.card}>
                      <Row gutter={[8, 0]}>
                        <Col xs={24} md={12}>
                          <SelectFormik
                            name="type"
                            showSearch
                            placeholder={t('invoicesCreate.type') ?? ''}
                            options={Object.values(InvoicesTypeEnum).map((type) => ({
                              label: t(`invoicesCreate.invoiceType.${type}`),
                              value: type,
                            }))}
                          />
                        </Col>
                        <Col xs={24} md={12}>
                          <FieldFormik
                            name="bill_number"
                            placeholder={t('invoicesCreate.billNumber') ?? ''}
                          />
                        </Col>
                        <Col span={24}>
                          <SelectFormik
                            name="company_id"
                            showSearch
                            placeholder={t('invoicesCreate.company') ?? ''}
                            options={
                              companiesSelect?.map((item) => ({
                                label: item.name,
                                value: item.id,
                              })) || []
                            }
                          />
                        </Col>
                        <Col xs={24} md={12}>
                          <DatePickerFormik
                            name="date"
                            placeholder={t('invoicesCreate.dateCreated') ?? ''}
                          />
                        </Col>
                        <Col xs={24} md={12}>
                          <DatePickerFormik
                            name="pay_by"
                            placeholder={t('invoicesCreate.payBy') ?? ''}
                          />
                        </Col>
                      </Row>
                    </Card>
                    <h2 className={style.title}>
                      {t('invoicesCreate.itemsTitle')}
                    </h2>
                    <Card className={style.card}>
                      <Row gutter={[8, 0]}>
                        {Array.isArray(values.services) && values.services.map((item: any, i: any) => (
                          <>
                            <Col xs={24} md={10}>
                              <FieldFormik
                                name={`services[${i}].name`}
                                placeholder={
                                  t('invoicesCreate.customCargoName') ?? ''
                                }
                              />
                            </Col>
                            <Col xs={24} md={4}>
                              <SelectFormik
                                name={`services[${i}].unit`}
                                showSearch
                                placeholder={t('invoicesCreate.customCargoUnit') ?? ''}
                                options={Object.values(InvoicesUnitEnum).map((unit) => ({
                                  label: t(`invoicesCreate.invoiceUnit.${unit}`),
                                  value: unit,
                                }))}
                              />
                            </Col>
                            <Col xs={24} md={4}>
                              <FieldFormik
                                name={`services[${i}].amount`}
                                type="number"
                                placeholder={
                                  t('invoicesCreate.customCargoAmount') ?? ''
                                }
                              />
                            </Col>
                            <Col xs={24} md={4}>
                              <FieldFormik
                                name={`services[${i}].value`}
                                type="number"
                                placeholder={
                                  t('invoicesCreate.customBillValue') ?? ''
                                }
                              />
                            </Col>
                            <Col xs={24} md={2}>
                              {values.services.length > 1 && (
                                <Button
                                  size="large"
                                  style={{
                                    marginTop: '20px',
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                  onClick={() => {
                                    if (Array.isArray(values.services)) {
                                      setFieldValue(
                                        'services',
                                        values.services.filter((_: any, filterI: number) => filterI !== i),
                                      )
                                    }
                                  }}
                                >
                                  <DeleteIcon color="#E94444" />
                                </Button>
                              )}
                            </Col>
                          </>
                        ))}
                        <Col span={24}>
                          <Button
                            className={style.button}
                            icon={<PlusIcon />}
                            onClick={() => {
                              setFieldValue('services', [
                                ...values.services,
                                { name: '', unit: '', amount: '', value: '' },
                              ])
                            }}
                          >
                            {t('invoicesCreate.addItem')}
                          </Button>
                        </Col>
                      </Row>
                    </Card>
                    <h2 className={style.title}>
                      {t('invoicesCreate.sumTitle')}
                    </h2>
                    <Card className={style.card}>
                      <Row>
                        <Col span={24}>
                          <SwitchFormik
                            name="vat_calculated"
                            placeholder={t('invoicesCreate.switch')}
                          />
                        </Col>
                        <Divider />
                        <Col span={12}>
                          <p className={style.sumText}>
                            {t('invoicesCreate.suma')}
                          </p>
                        </Col>
                        <Col span={12}>
                          <p className={style.sum}>{formatPrice(totalSum)}</p>
                        </Col>
                        <Col span={12}>
                          <p className={style.sumText}>
                            {t('invoicesCreate.pvm')}
                          </p>
                        </Col>
                        <Col span={12}>
                          <p className={style.sum}>{formatPrice(+pvm)}</p>
                        </Col>
                        <Col span={12}>
                          <p className={style.boldSumText}>
                            {t('invoicesCreate.bendraSum')}
                          </p>
                        </Col>
                        <Col span={12}>
                          <p className={style.boldSum}>
                            {formatPrice(+(+pvm + +totalSum).toFixed(2))}
                          </p>
                        </Col>
                        <Col xs={24} md={24}>
                          <FieldFormik
                            name="comment"
                            type="input"
                            placeholder={t('invoicesCreate.typeHere') ?? ''}
                            label={t('invoicesCreate.comment') ?? ''}
                          />
                        </Col>
                      </Row>
                      <Button
                        className={style.submitButton}
                        htmlType="submit"
                        type="primary"
                        loading={isLoadingCreate || isLoadingUpdate}
                      >
                        {t('invoicesCreate.submit')}
                      </Button>
                    </Card>
                  </Form>
                </Col>
                <Col xl={12} xs={24} xxl={12}>
                  <Card>
                    <InvoiceDefaultDocument
                      values={{
                        name: values.type && t(`invoicesCreate.invoiceType.${values.type}`),
                        bill_number: values.bill_number,
                        company_id: values.company_id as number,
                        date: values.date,
                        pay_by: values.pay_by,
                        type: values.type,
                        items: values.services as any,
                        vat_calculated: !!values.vat_calculated,
                        comment: values.comment,
                      }}
                      lang={values.type === InvoicesTypeEnum.proforma ? 'en' : 'lt'}
                    />
                  </Card>
                </Col>
              </Row>
            )
          }}
        </Formik>
      </Preloader>
    </div>
  )
}
