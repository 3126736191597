import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, Col, Row } from 'antd'
import { Form, Formik } from 'formik'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { customerWorkersApi } from '@src/store/services/customerWorkers-service'
import { useHistory, useParams } from 'react-router-dom'
import { ViewContentHeader } from '@src/components/ViewContentHeader/ViewContentHeader'
import { Button } from '@src/components/ui/Button/Button'
import { Preloader } from '@src/components/Preloader/Preloader'
import * as Yup from 'yup'
import style from './createWorkers.module.scss'

export const CreateWorkers = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams<{ action: string, id: string }>()
  const { data: worker, isLoading: isLoadingWorker } = customerWorkersApi.useGetOneQuery(+params.id, { skip: !params.id || params.action === 'create' })
  const [create, { isLoading: isLoadingCreate }] = customerWorkersApi.useCreateMutation()
  const [edit, { isLoading: isLoadingUpdate }] = customerWorkersApi.useUpdateMutation()

  const initialValues = useMemo(() => ({
    name: worker ? worker.name : '',
    email: worker ? worker.email : '',
    phone: worker ? worker.phone : '',
    position: worker ? worker.position : '',
  }), [worker])

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      name: Yup.string().required(t('form.errors.required') ?? ''),
      email: Yup.string().email().required(t('form.errors.required') ?? ''),
      phone: Yup.string().required(t('form.errors.required') ?? ''),
      position: Yup.string().required(t('form.errors.required') ?? ''),
    })
  ), [t])

  const onSubmit = useCallback(async (values: any) => {
    let result: any

    if (+params.id && params.action === 'edit') {
      result = await edit({ ...values, id: params.id })
    } else if (params.action === 'create' && params.id) {
      result = await create({ ...values, company_id: params.id })
    }

    if (result && 'data' in result) {
      history.goBack()
    }
  }, [create, edit, history, params.id, params.action])

  return (
    <div className={style.container}>
      <ViewContentHeader
        onBackClick={() => history.goBack()}
      >
        {params.action === 'create'
          ? t('customerWorkersCreate.title')
          : t('customerWorkersCreate.titleEdit')}
      </ViewContentHeader>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {() => (
          <Form style={{ height: '100%' }}>
            <h2 className={style.title}>{t('customerWorkersCreate.info')}</h2>
            <Preloader loading={isLoadingWorker}>
              <Card className={style.card}>
                <div className={style.cardContent}>
                  <Row gutter={[8, 0]}>
                    <Col xs={24} xl={12}>
                      <FieldFormik name="name" placeholder={t('customerWorkersCreate.name') ?? ''} />
                    </Col>
                    <Col xs={24} xl={12}>
                      <FieldFormik name="email" placeholder={t('customerWorkersCreate.email') ?? ''} />
                    </Col>
                    <Col xs={24} xl={12}>
                      <FieldFormik name="phone" placeholder={t('customerWorkersCreate.phone') ?? ''} />
                    </Col>
                    <Col xs={24} xl={12}>
                      <FieldFormik name="position" placeholder={t('customerWorkersCreate.position') ?? ''} />
                    </Col>
                  </Row>
                </div>
                <div className={style.cardFooter}>
                  <Button
                    className={style.styleButton}
                    htmlType="submit"
                    type="primary"
                    size="large"
                    loading={isLoadingCreate || isLoadingUpdate}
                  >
                    {params.action === 'create' ? t('pageTable.create') : t('customerCreate.edit') }
                  </Button>
                </div>
              </Card>
            </Preloader>
          </Form>
        )}
      </Formik>
    </div>
  )
}
