import { TransportationCompanyType, TransportationType } from '@src/types/transportation'

export enum TransportationBillStatus {
  notProcessed = 1,
  processed = 2,
  archived = 3,
}

export enum TransportationBillTypeEnum {
  Tax = 'tax',
  Credit = 'credit'
}

export type TransportationBillType = {
  type: TransportationBillTypeEnum
  items?: any
  bill_number?: any
  created_at: string
  id: number
  pay_by?: any
  date?: any
  status: TransportationBillStatus
  transportation: TransportationType
  transportation_id: number
  updated_at: string
  company_id: number
  company?: TransportationCompanyType
  isSentAccountant: boolean
  isSentCustomer: boolean
  sum?: string
  payer_id?: number
  vat_calculated?: number
  comment: string
  payer?: {
    id: number
  }
}
