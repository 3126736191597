import { ReactNode, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Card, Col, Drawer, Row } from 'antd'
import moment from 'moment/moment'

import { companiesApi } from '@src/store/services/companies-service'
import { CompanyType } from '@src/types/company'
import { PageTable } from '@src/components/Table/PageTable'
import { useAgreementsTable } from '@src/components/pages/agreements/AgreementsTable.hook'
import { CustomerWorkers } from '@src/components/CustomerWorkers/CustomerWorkers'
import { authApi } from '@src/store/services/auth-service'
import { UserRoles } from '@src/types/users'
import { TaskComments } from '@src/components/pages/tasks/TaskComments'
import { inquiriesApi } from '@src/store/services/inquiries-service'
import { Button } from '@src/components/ui/Button/Button'
import useQuery from '@src/hooks/useQuery'
import { CustomerWorkerCreate } from '@src/components/CustomerWorkerCreate/CustomerWorkerCreate'
import { ReactComponent as PlusIcon } from '@src/assets/plus_icon.svg'
import { ViewContentHeader } from '@src/components/ViewContentHeader/ViewContentHeader'
import { ColumnProps } from 'antd/es/table'
import { Agreement } from '@src/types/agreements'
import cn from 'classnames'
import { DetailsCard } from '@src/components/DetailsCard/DetailsCard'
import { OwnerCreate } from '../OwnerCreate/OwnerCreate'
import { CustomerCreate } from '../CustomerCreate/CustomerCreate'
import { AgreementOrders } from '../AgreementOrders/AgreementOrders'
import { AgreementInvoices } from '../AgreementInvoices/AgreementInvoices'
import style from './companiesView.module.scss'

export const CompaniesView = () => {
  const { t } = useTranslation()
  const filtersQuery = useQuery('filters')
  const params = useParams<{ id: string }>()
  const history = useHistory()
  const [activeTabKey, setActiveTabKey] = useState<string>('customerWorkers')
  const { data, isLoading } = companiesApi.useGetOneCompanyQuery(+params.id)
  const { data: user } = authApi.useGetMeQuery()
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false)
  const [isOpenWorkerCreate, setIsOpenWorkerCreate] = useState<boolean>(false)

  const openWorkerDrawer = () => setIsOpenWorkerCreate(true)
  const closeWorkerDrawer = () => setIsOpenWorkerCreate(false)
  const openDrawer = () => setIsDrawerVisible(true)
  const closeDrawer = () => setIsDrawerVisible(false)
  const { columns, data: dataAgreements, paramsFilters, setParamsFilters, isLoading: isLoadingAgreements } = useAgreementsTable({
    initParamsFilters: {
      page: 1,
      filters: {
        [data?.type === CompanyType.Owner ? 'owner_id' : 'customer_id']: {
          $eq: params.id,
        },
      },
    },
    skip: user?.role !== UserRoles.Admin,
  })

  const { data: inquiries } = inquiriesApi.useListQuery({
    filters: filtersQuery ? JSON.parse(filtersQuery) : {
      company_id: {
        $eq: +params.id,
      },
    },
    sort: ['created_at:desc'],
  }, {
    skip: user?.role === UserRoles.Driver,
  })

  useEffect(() => {
    setParamsFilters({
      page: 1,
      filters: {
        [data?.type === CompanyType.Owner ? 'owner_id' : 'customer_id']: {
          $eq: params.id,
        },
      },
    })
  }, [data?.type, params.id, setParamsFilters])

  const getFilteredColumns = (column: ColumnProps<Agreement>[], companyType?: string) => {
    const filteredColumns = companyType === CompanyType.Owner
      ? column.filter((col) => col.key !== 'owner')
      : column.filter((col) => col.key !== 'customer')

    if (companyType !== CompanyType.Owner) {
      const ownerRepresentativeIndex = filteredColumns.findIndex((col) => col.key === 'owner_representative')
      const customerRepresentativeIndex = filteredColumns.findIndex((col) => col.key === 'customer_representative')

      if (ownerRepresentativeIndex !== -1 && customerRepresentativeIndex !== -1) {
        const temp = filteredColumns[ownerRepresentativeIndex]
        filteredColumns[ownerRepresentativeIndex] = filteredColumns[customerRepresentativeIndex]
        filteredColumns[customerRepresentativeIndex] = temp
      }
    }

    return filteredColumns
  }

  const contentList: Record<string, ReactNode> = useMemo(() => ({
    agreements: user?.role === UserRoles.Admin ? (
      <PageTable
        isTableOnly
        setParamsFilters={setParamsFilters}
        paramsFilters={paramsFilters}
        tableData={{
          ...dataAgreements,
          isLoading: isLoadingAgreements,
          columns: getFilteredColumns(columns, data?.type),
          components: {
            body: {
              row: (props: any) => (
                <tr
                  {...props}
                  style={{
                    background: dataAgreements?.data.find((item: any) => item.id === props['data-row-key'])?.owner.color,
                    height: '64px',
                  }}
                />
              ),
            },
          },
        }}
      />
    ) : null,
    customerWorkers: (
      <CustomerWorkers notHeader companyId={+params.id} />
    ),
    orders: (
      <AgreementOrders
        isTableOnly
        companyType={data?.type}
        companyId={+params.id}
        withoutParamsFilters
      />
    ),
    invoices: (
      <AgreementInvoices
        isTableOnly
        companyType={data?.type}
        companyId={+params.id}
        withoutParamsFilters
      />
    ),
    chat: (
      <TaskComments taskId={+params.id} inquiries={inquiries?.data!} />
    ),
  }), [
    columns,
    dataAgreements,
    isLoadingAgreements,
    inquiries,
    params.id,
    paramsFilters,
    setParamsFilters,
    user,
    data?.type,
  ])

  const tabList = useMemo(() => {
    if (user?.role === UserRoles.Admin) {
      return [
        { key: 'customerWorkers', label: t('customerWorkers.title') },
        { key: 'agreements', label: t('agreements.title') },
        { key: 'orders', label: t('menu.orders') },
        { key: 'invoices', label: t('invoices.title') },
        { key: 'chat', label: t('chat.title') },
      ]
    }

    if (user?.role === UserRoles.SalesManager) {
      setActiveTabKey('chat')
      return [
        { key: 'customerWorkers', label: t('customerWorkers.title') },
        { key: 'chat', label: t('chat.title') },
      ]
    }

    return [
      { key: 'customerWorkers', label: t('customerWorkers.title') },
    ]
  }, [t, user])

  const detailsData = [
    { label: t('companies.columns.code'), value: data?.company_code },
    { label: t('companies.columns.address'), value: data?.address },
    data?.type === CompanyType.Owner
      ? { label: t('companies.columns.accountNumber'), value: data?.account_number }
      : { label: t('companies.columns.agreementNumber'), value: data?.agreement_number },
    { label: t('companies.columns.createdAt'), value: moment(data?.created_at).format('YYYY-MM-DD') },
    { label: t('companies.columns.vatNumber'), value: data?.vat_number },
  ]

  return (
    <div>
      <ViewContentHeader
        onBackClick={() => {
          if (data?.type === CompanyType.Customer) {
            history.push('/customers')
          } else {
            history.push('/owners')
          }
        }}
      >
        {data?.full_name}
      </ViewContentHeader>
      {data?.type && (
        <h2 className={style.info}>
          {data?.type === CompanyType.Customer
            ? t('customerCreate.info')
            : t('ownerCreate.info')}
        </h2>
      )}
      <Row gutter={[24, 24]}>
        <Col xs={24} xl={12}>
          <DetailsCard
            data={detailsData}
            isLoading={isLoading}
            buttonLabel={t('customerCreate.edit')}
            buttonAction={openDrawer}
            showButton
          />
        </Col>
        <Col xs={24} xl={12}>
          {user?.role === UserRoles.Driver && (
            <div className={style.headerWrapper}>
              <h2 className={style.info}>
                {t('tasks.columns.workers')}
              </h2>
              <Button
                leftIcon={<PlusIcon />}
                type="primary"
                size="small"
                onClick={() => history.push(`/workers/create/${params.id}`)}
              >
                {t('customerCreate.submit')}
              </Button>
            </div>
          )}
          <Card
            className={cn(style.carCardStyle, { [style.driver]: user?.role === UserRoles.Driver })}
            activeTabKey={activeTabKey}
            onTabChange={setActiveTabKey}
            tabList={user?.role !== UserRoles.Driver ? tabList : undefined}
            tabProps={{
              size: 'middle',
            }}
            tabBarExtraContent={
              activeTabKey === 'customerWorkers' && (
                <Button
                  leftIcon={<PlusIcon />}
                  type="primary"
                  size="large"
                  onClick={openWorkerDrawer}
                >
                  {t('customerCreate.submit')}
                </Button>
              )
            }
          >
            {contentList[activeTabKey]}
          </Card>
        </Col>
      </Row>
      <Drawer
        title={data?.type === CompanyType.Customer
          ? t('customerCreate.titleEdit')
          : t('ownerCreate.titleEdit')}
        open={isDrawerVisible}
        onClose={closeDrawer}
        width={554}
      >
        {data?.type === CompanyType.Customer
          ? <CustomerCreate onClose={closeDrawer} />
          : <OwnerCreate onClose={closeDrawer} />}
      </Drawer>
      <CustomerWorkerCreate isOpen={isOpenWorkerCreate} onClose={closeWorkerDrawer} companyId={+params.id} />
    </div>
  )
}
