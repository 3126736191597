import React, { Key, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { DatePicker, Table, Tag, notification, Row, Col } from 'antd'
import { ColumnProps } from 'antd/lib/table'

import { AgreementInvoicesStatus, AgreementInvoicesType } from '@src/types/agreementInvoices'
import { TransportationBillType } from '@src/types/transportationBills'
import { BillsTypeEnum } from '@src/types/settings'
import { InvoicesType } from '@src/types/invoices'
import { ConfirmEmail } from '@src/components/ConfirmEmail/ConfirmEmail'
import { accountingApi } from '@src/store/services/accounting-service'
import { formatPrice } from '@src/lib/formatPrice'
import { Preloader } from '@src/components/Preloader/Preloader'
import { isSelectionText } from '@src/lib/utils'
import { addQuery } from '@src/lib/route'
import useQuery from '@src/hooks/useQuery'
import moment, { Moment } from 'moment'
import cn from 'classnames'
import { TransportationTooltip } from '@src/components/pages/transportation/TransportationTooltip'
import { Button } from '@src/components/ui/Button/Button'
import style from './accounting.module.scss'

const statusColor: any = {
  1: 'blue',
  2: 'green',
  3: 'red',
}

const tabs = [
  {
    key: BillsTypeEnum.Rent,
    label: 'accounting.agreementInvoices',
  },
  {
    key: BillsTypeEnum.Transportation,
    label: 'accounting.transportationBill',
  },
  {
    key: BillsTypeEnum.Business,
    label: 'accounting.businessInvoices',
  },
]

export const Accounting = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [getBillsTrigger, { data: bills, isFetching: isLoadingGetBills }] = accountingApi.useLazyGetBillsQuery()
  const selectedTabQuery = useQuery('selectedTab')
  const dateFromQuery = useQuery('dateFrom')
  const dateToQuery = useQuery('dateTo')
  const [dates, setDates] = useState<any>(dateFromQuery && dateToQuery ? [moment(dateFromQuery), moment(dateToQuery)] : [moment().add(-1, 'month'), moment()])
  const [isOpenSendEmail, setIsOpenSendEmail] = useState(false)
  const [selectedRow, setSelectedRow] = useState<number[]>([])
  const [selectedTab, setSelectedTab] = useState<BillsTypeEnum>(selectedTabQuery as BillsTypeEnum || BillsTypeEnum.Rent)
  const [sendEmailAccountant, { isLoading: isLoadingSendBills }] = accountingApi.useSendEmailAccountantMutation()

  useEffect(() => {
    history.push({
      search: addQuery({ selectedTab, dateFrom: dates[0].format(), dateTo: dates[1].format() }),
    })
  }, [dates, history, selectedTab])

  const onCellAgreementInvoice = useCallback((record: AgreementInvoicesType) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/agreement-invoices/edit/${record.id}`)
      }
    },
  }), [history])

  const onCellTransportationBill = useCallback((record: TransportationBillType) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/transportation-bills/edit/${record.id}`)
      }
    },
  }), [history])

  const onCellBusinessInvoices = useCallback((record: InvoicesType) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/invoices/edit/${record.id}`)
      }
    },
  }), [history])

  const onCellMailHandler = useCallback((record: any) => ({
    onClick: () => {
      if (!isSelectionText()) {
        setSelectedRow([record.id])
        setIsOpenSendEmail(true)
      }
    },
  }), [])

  const agreementInvoiceColumns = useMemo<ColumnProps<AgreementInvoicesType>[]>(
    () => [
      {
        title: t('agreementInvoices.columns.dateCreated'),
        dataIndex: 'date_created',
        key: 'date_created',
        onCell: onCellAgreementInvoice,
      },
      {
        title: t('agreementInvoices.columns.number'),
        dataIndex: 'bill_number',
        key: 'bill_number',
        onCell: onCellAgreementInvoice,
      },
      {
        title: t('agreementInvoices.columns.price'),
        dataIndex: 'price',
        key: 'price_month',
        render: (priceMonth) => priceMonth && formatPrice(priceMonth),
        onCell: onCellAgreementInvoice,
      },
      {
        title: t('agreementInvoices.columns.customer'),
        dataIndex: ['company', 'full_name'],
        key: 'company',
        width: 200,
        onCell: onCellAgreementInvoice,
      },
      {
        title: t('agreementInvoices.columns.car'),
        dataIndex: 'order',
        key: 'car',
        width: 200,
        render: (order) => `${order.car.make} ${order.car.model} (${order.car.serial_number})`,
        onCell: onCellAgreementInvoice,
      },
      {
        title: t('agreementInvoices.columns.status'),
        dataIndex: 'status',
        key: 'status',
        render: (status) => {
          switch (+status) {
            case AgreementInvoicesStatus.notProcessed:
              return <Tag color="blue">{t(`agreementInvoices.status.${AgreementInvoicesStatus.notProcessed}`)}</Tag>
            case AgreementInvoicesStatus.processed:
              return <Tag color="green">{t(`agreementInvoices.status.${AgreementInvoicesStatus.processed}`)}</Tag>
            case AgreementInvoicesStatus.archived:
              return <Tag color="green">{t(`agreementInvoices.status.${AgreementInvoicesStatus.archived}`)}</Tag>
            default:
              return ''
          }
        },
        onCell: onCellAgreementInvoice,
      },
      {
        title: t('agreementInvoices.columns.sentAccountant'),
        dataIndex: 'is_sent_accountant',
        key: 'isSentAccountant',
        render: (isSentAccountant) => (
          <Tag color={isSentAccountant ? 'green' : 'red'}>{t(`sendStatus.${isSentAccountant}`)}</Tag>
        ),
        onCell: onCellAgreementInvoice,
      },
      {
        title: t('agreementInvoices.columns.sentCustomer'),
        dataIndex: 'is_sent_customer',
        key: 'isSentCustomer',
        render: (isSentAccountant) => (
          <Tag color={isSentAccountant ? 'green' : 'red'}>{t(`sendStatus.${isSentAccountant}`)}</Tag>
        ),
        onCell: onCellMailHandler,
      },
    ],
    [onCellAgreementInvoice, onCellMailHandler, t],
  )

  const transportationBillColumns = useMemo<ColumnProps<TransportationBillType>[]>(
    () => [
      {
        title: t('transportationBills.columns.dateClosed'),
        dataIndex: 'date',
        key: 'date',
        onCell: onCellTransportationBill,
      },
      {
        title: t('transportation.columns.billNumber'),
        dataIndex: 'bill_number',
        key: 'bill_number',
        onCell: onCellTransportationBill,
      },
      {
        title: t('transportation.columns.sum'),
        dataIndex: 'sum',
        key: 'sum',
        render: (sum) => formatPrice(sum),
        onCell: onCellTransportationBill,
      },
      {
        title: t('transportation.columns.payer'),
        dataIndex: ['payer', 'full_name'],
        key: 'payer',
        onCell: onCellTransportationBill,
      },
      {
        title: t('transportation.columns.route'),
        dataIndex: 'transportation',
        key: 'cargo',
        render: (transportation) => <TransportationTooltip routes={transportation.points} />,
        onCell: onCellTransportationBill,
      },
      {
        title: t('transportationBills.columns.status'),
        dataIndex: 'status',
        key: 'status',
        render: (status) => <Tag color={statusColor[status]}>{t(`transportationBills.status.${status}`)}</Tag>,
        onCell: onCellTransportationBill,
      },
      {
        title: t('transportationBills.columns.sentAccountant'),
        dataIndex: 'is_sent_accountant',
        key: 'isSentAccountant',
        render: (isSentAccountant) => (
          <Tag color={isSentAccountant ? 'green' : 'red'}>{t(`sendStatus.${isSentAccountant}`)}</Tag>
        ),
        onCell: onCellTransportationBill,
      },
      {
        title: t('transportationBills.columns.sentCustomer'),
        dataIndex: 'is_sent_customer',
        key: 'isSentCustomer',
        render: (isSentAccountant) => (
          <Tag color={isSentAccountant ? 'green' : 'red'}>{t(`sendStatus.${isSentAccountant}`)}</Tag>
        ),
        onCell: onCellMailHandler,
      },
    ],
    [onCellMailHandler, onCellTransportationBill, t],
  )

  const businessInvoicesColumns = useMemo<ColumnProps<InvoicesType>[]>(
    () => [
      {
        title: t('invoices.columns.dateCreated'),
        dataIndex: 'date',
        key: 'date',
        onCell: onCellBusinessInvoices,
      },
      {
        title: t('invoices.columns.billNumber'),
        dataIndex: 'bill_number',
        key: 'bill_number',
        onCell: onCellBusinessInvoices,
      },
      {
        title: t('invoices.columns.sum'),
        dataIndex: 'sum',
        key: 'sum',
        render: (sum) => formatPrice(sum),
        onCell: onCellBusinessInvoices,
      },
      {
        title: t('invoices.columns.company'),
        dataIndex: ['company', 'full_name'],
        key: 'company',
        onCell: onCellBusinessInvoices,
      },
      {
        title: t('invoices.columns.status'),
        dataIndex: 'status',
        key: 'status',
        render: (status) => <Tag color={statusColor[status]}>{t(`invoices.status.${status}`)}</Tag>,
        onCell: onCellBusinessInvoices,
      },
      {
        title: t('invoices.columns.sentAccountant'),
        dataIndex: 'is_sent_accountant',
        key: 'isSentAccountant',
        render: (isSentAccountant) => (
          <Tag color={isSentAccountant ? 'green' : 'red'}>{t(`sendStatus.${isSentAccountant}`)}</Tag>
        ),
        onCell: onCellBusinessInvoices,
      },
      {
        title: t('invoices.columns.sentCustomer'),
        dataIndex: 'is_sent_customer',
        key: 'isSentCustomer',
        render: (isSentAccountant) => (
          <Tag color={isSentAccountant ? 'green' : 'red'}>{t(`sendStatus.${isSentAccountant}`)}</Tag>
        ),
        onCell: onCellMailHandler,
      },
    ],
    [onCellBusinessInvoices, onCellMailHandler, t],
  )

  const getAccounting = (newDates?: [Moment, Moment]) => {
    getBillsTrigger({ dates: newDates || dates, type: selectedTab })
  }

  const onChangeDates = (newDates: any) => {
    setDates(newDates)
    setSelectedRow([])
    getAccounting(newDates)
  }

  useEffect(() => {
    getAccounting()// eslint-disable-next-line
  }, [])

  const sendAccounting = async (email: string) => {
    const result = await sendEmailAccountant({ ids: selectedRow, emails: [email], type: selectedTab })

    if ('data' in result) {
      setIsOpenSendEmail(false)
      notification.success({
        message: result.data,
      })
    }
  }

  const onChangeTab = async (tab: any) => {
    getBillsTrigger({ dates, type: tab })
    setSelectedTab(tab)
    setSelectedRow([])
  }

  return (
    <div>
      <div>
        <h1>{t('accounting.title')}</h1>
      </div>
      <div className={style.revenue}>
        <Row>
          <Col span={8}>
            <div className={style.revenueTitle}>{t('accounting.agreementInvoices')}</div>
            <div className={style.revenuePrice}>{formatPrice(bills?.revenue?.agreementInvoicesRevenue!)}</div>
          </Col>
          <Col span={8}>
            <div className={style.revenueTitle}>{t('accounting.transportationBill')}</div>
            <div className={style.revenuePrice}>{formatPrice(bills?.revenue?.transportInvoicesRevenue!)}</div>
          </Col>
          <Col span={8}>
            <div className={style.revenueTitle}>{t('accounting.businessInvoices')}</div>
            <div className={style.revenuePrice}>{formatPrice(bills?.revenue?.businessInvoicesRevenue!)}</div>
          </Col>
        </Row>
      </div>
      <div className={style.actions}>
        <div>
          <DatePicker.RangePicker value={dates} style={{ width: 300 }} onChange={onChangeDates} />
        </div>

        <div className={style.tabs}>
          {tabs.map((tab) => (
            <button
              key={tab.key}
              type="button"
              onClick={() => onChangeTab(tab.key)}
              className={cn({ [style.active]: selectedTab === tab.key })}
            >
              {t(tab.label)}
            </button>
          ))}
        </div>
      </div>

      {bills && (
        <>
          <div className={style.table}>
            {selectedTab === BillsTypeEnum.Rent && (
              <Preloader loading={isLoadingGetBills}>
                <Table<AgreementInvoicesType>
                  components={{
                    body: {
                      // eslint-disable-next-line max-len
                      row: (props: any) => <tr {...props} style={{ background: bills.data.find((item) => item.id === props['data-row-key'])?.order.agreement.owner?.color }} />,
                    },
                  }}
                  rowSelection={{
                    selectedRowKeys: selectedRow,
                    type: 'checkbox',
                    onChange: (newSelectedRow: Key[]) => {
                      setSelectedRow(newSelectedRow as any)
                    },
                  }}
                  dataSource={bills.data as AgreementInvoicesType[]}
                  columns={agreementInvoiceColumns}
                  rowKey="id"
                  scroll={{ x: 'max-content' }}
                  pagination={false}
                />
              </Preloader>
            )}
            {selectedTab === BillsTypeEnum.Transportation && (
              <Preloader loading={isLoadingGetBills}>
                <Table<TransportationBillType>
                  components={{
                    body: {
                      // eslint-disable-next-line max-len
                      row: (props: any) => <tr {...props} style={{ background: bills.data.find((item) => item.id === props['data-row-key'])?.company?.color }} />,
                    },
                  }}
                  rowSelection={{
                    selectedRowKeys: selectedRow,
                    type: 'checkbox',
                    onChange: (newSelectedRow: Key[]) => {
                      setSelectedRow(newSelectedRow as any)
                    },
                  }}
                  dataSource={bills.data as TransportationBillType[]}
                  columns={transportationBillColumns}
                  rowKey="id"
                  scroll={{ x: 'max-content' }}
                  pagination={false}
                />
              </Preloader>
            )}
            {selectedTab === BillsTypeEnum.Business && (
              <Preloader loading={isLoadingGetBills}>
                <Table<InvoicesType>
                  components={{
                    body: {
                      // eslint-disable-next-line max-len
                      row: (props: any) => <tr {...props} style={{ background: bills.data.find((item) => item.id === props['data-row-key'])?.company.color }} />,
                    },
                  }}
                  rowSelection={{
                    selectedRowKeys: selectedRow,
                    type: 'checkbox',
                    onChange: (newSelectedRow: Key[]) => {
                      setSelectedRow(newSelectedRow as any)
                    },
                  }}
                  dataSource={bills.data as InvoicesType[]}
                  columns={businessInvoicesColumns}
                  rowKey="id"
                  scroll={{ x: 'max-content' }}
                  pagination={false}
                />
              </Preloader>
            )}
          </div>
          <Button
            onClick={() => setIsOpenSendEmail(true)}
            style={{ margin: '24px 0' }}
          >
            {t('accounting.send')}
          </Button>
        </>
      )}
      <ConfirmEmail
        callback={sendAccounting}
        email={bills?.email || ''}
        isLoading={isLoadingSendBills}
        onClose={() => setIsOpenSendEmail(false)}
        isOpen={isOpenSendEmail}
      />
    </div>
  )
}
