import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Drawer } from 'antd'
import useQuery from '@src/hooks/useQuery'
import { carsApi } from '@src/store/services/cars-service'
import { PageTable } from '@src/components/Table/PageTable'
import { CarMakesType } from '@src/types/car'
import { ReactComponent as DeleteIcon } from '@src/assets/delete_icon.svg'
import { Confirm } from '@src/components/Confirm/Confirm'
import { ColumnProps } from 'antd/es/table'
import cn from 'classnames'
import { CarModelsCreate } from './CarModelsCreate'

export const CarModels = () => {
  const { t } = useTranslation()
  const { data: modelsList, isLoading } = carsApi.useGetCarsModelsQuery()
  const pageQuery = useQuery('page')
  const [paramsFilters, setParamsFilters] = useState({
    page: pageQuery ? +pageQuery : 1,
  })
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false)

  const formattedCarMakes = useMemo(() => (
    modelsList?.map((make, index) => ({
      key: index,
      name: make,
    }))
  ), [modelsList])

  const columns = useMemo<ColumnProps<CarMakesType>[]>(() => [
    {
      title: '#',
      dataIndex: 'key',
      key: 'index',
      width: '55px',
      render: (index) => index + 1,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      dataIndex: 'actions',
      fixed: 'right',
      width: '48px',
      key: 'actions',
      render: () => (
        <Confirm
          title={t('deleteModal.deleteConfirmationTitle')}
          content={t('deleteModal.deleteConfirmation', { localizedType: t('deleteModal.model') })}
          okText={t('deleteModal.delete')}
          cancelText={t('deleteModal.cancel')}
          onOk={() => {}}
          className={cn('table_actions_item', 'table_actions_item__delete')}
        >
          <DeleteIcon />
        </Confirm>
      ),
    },
  ], [t])

  const openDrawer = () => setIsDrawerVisible(true)
  const closeDrawer = () => setIsDrawerVisible(false)

  return (
    <div>
      <PageTable
        title={t('carModels.title')!}
        setParamsFilters={setParamsFilters}
        paramsFilters={paramsFilters}
        filtersV2
        onCreate={openDrawer}
        tableData={{
          data: formattedCarMakes,
          isLoading,
          columns,
        }}
      />
      <Drawer
        title={t('carModels.createTitle')}
        open={isDrawerVisible}
        onClose={closeDrawer}
        width={554}
      >
        <CarModelsCreate />
      </Drawer>
    </div>
  )
}
