import { ChangeEvent, FC, MouseEvent, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Input, Modal, notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { SelectFormik } from '@src/components/SelectFormik/SelectFormik'
import { customerWorkersApi } from '@src/store/services/customerWorkers-service'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '@src/store/store'
import { invoicesApi } from '@src/store/services/invoices-service'
import { debtsApi } from '@src/store/services/debts-service'
import {
  selectAppSendEmailCompanyId, selectAppSendEmailDocumentName,
  selectAppSendEmailId,
  selectAppSendEmailIsAccountant,
  selectAppSendEmailType,
} from '@src/store/ducks/app/selectors'
import { SendEmailType } from '@src/types/mailLogs'
import { closeSendEmail } from '@src/store/ducks/app/reducer'
import { agreementsApi } from '@src/store/services/agreements-service'
import { transportationApi } from '@src/store/services/transportation-service'
import { accountingApi } from '@src/store/services/accounting-service'
import { BillsTypeEnum } from '@src/types/settings'
import { serviceInvoicesApi } from '@src/store/services/serviceInvoices-service'
import { detailsApi } from '@src/store/services/details-service'
import { ReactComponent as DocumentIcon } from '@src/assets/document_icon.svg'
import { ReactComponent as CloseModalIcon } from '@src/assets/close-modal_icon.svg'
import { ReactComponent as PlusIcon } from '@src/assets/plus_icon.svg'
import style from './SendEmail.module.scss'

const mapTypes: any = {
  [SendEmailType.AgreementInvoices]: BillsTypeEnum.Rent,
  [SendEmailType.TransportationBills]: BillsTypeEnum.Transportation,
  [SendEmailType.Invoices]: BillsTypeEnum.Business,
}

const accountantEmails = [
  {
    value: 'Svetlana.semaskiene@gmail.com',
    label: 'Svetlana.semaskiene@gmail.com',
  },
  {
    value: 'martynas@brc.lt',
    label: 'martynas@brc.lt',
  },
]

export const SendEmail: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const type = useSelector(selectAppSendEmailType)
  const companyId = useSelector(selectAppSendEmailCompanyId)
  const id = useSelector(selectAppSendEmailId)
  const isAccountant = useSelector(selectAppSendEmailIsAccountant)
  const documentName = useSelector(selectAppSendEmailDocumentName)
  const formRef = useRef<any>()
  const { data: customerWorkers } = customerWorkersApi.useShortListQuery({ companyId }, { skip: isAccountant || !companyId })
  const [isLoadingSendEmail, setIsLoadingSendEmail] = useState(false)
  const [newEmail, setNewEmail] = useState('')
  const [newEmailList, setNewEmailList] = useState<string[]>([])
  const [sendEmail] = invoicesApi.useSendEmailMutation()
  const [sendDebtEmail] = debtsApi.useSendEmailMutation()
  const [sendEmailCustomerAgreements] = agreementsApi.useSendEmailCustomerAgreementsMutation()
  const [sendEmailCompanyAgreementInvoices] = agreementsApi.useSendEmailCompanyAgreementInvoicesMutation()
  const [sendEmailAgreementInvoices] = agreementsApi.useSendEmailAgreementInvoicesMutation()
  const [sendEmailCompanyAgreementBills] = agreementsApi.useSendEmailCustomerAgreementBillsMutation()
  const [sendEmailAccountant] = accountingApi.useSendEmailAccountantMutation()
  const [sendCustomerServiceInvoice] = serviceInvoicesApi.useSendCustomerServiceInvoiceMutation()
  const [sendEmailDetailsOffers] = detailsApi.useSendEmailDetailsOffersMutation()
  const [sendEmailCustomerAgreementOrder] = agreementsApi.useSendEmailCustomerAgreementOrderMutation()
  const [sendEmailTransportation] = transportationApi.useSendEmailTransportationMutation()

  useEffect(() => {
    if (type) {
      setNewEmail('')
      setNewEmailList([])
    }
  }, [type])

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      email: Yup.array().min(1).required(t('form.errors.required') ?? ''),
    })
  ), [t])

  const initialValues = useMemo(() => {
    let email: string[] = []
    if (isAccountant) {
      email = [accountantEmails[0].value]
    } else if (customerWorkers?.length && customerWorkers[0].email) {
      const defaultCustomerWorker = customerWorkers.find((item) => item.default)
      email = defaultCustomerWorker ? [defaultCustomerWorker.email] : [customerWorkers[0].email]
    }

    return {
      email,
    }
  }, [customerWorkers, isAccountant])

  const onSubmit = async (emailArr: string[] | string) => {
    setIsLoadingSendEmail(true)
    let result

    const emails = typeof emailArr === 'string' ? [emailArr] : emailArr

    if (isAccountant) {
      result = await sendEmailAccountant({ ids: [id], emails, type: mapTypes[type!] })
      if ('data' in result) {
        dispatch(agreementsApi.util?.invalidateTags(['Agreements']))
        dispatch(transportationApi.util?.invalidateTags(['Transportation']))
        dispatch(closeSendEmail())
        notification.success({
          message: t('sendEmail.statusNotification'),
          description: t('sendEmail.sendCustomerEmailNotification'),
        })
      }
    } else {
      switch (type) {
        case SendEmailType.AgreementBills:
          result = await sendEmailCompanyAgreementBills({ id, emails })
          if ('data' in result) {
            dispatch(closeSendEmail())
            notification.success({
              message: t('sendEmail.statusNotification'),
              description: t('sendEmail.sendCustomerEmailNotification'),
            })
          }
          break
        case SendEmailType.AgreementInvoices:
          result = await sendEmailCompanyAgreementInvoices({ id, emails })
          if ('data' in result) {
            dispatch(closeSendEmail())
            notification.success({
              message: t('sendEmail.statusNotification'),
              description: t('sendEmail.sendCustomerEmailNotification'),
            })
          }
          break
        case SendEmailType.AgreementInvoicesV2:
          result = await sendEmailAgreementInvoices({ id, emails })
          if ('data' in result) {
            dispatch(closeSendEmail())
            notification.success({
              message: t('sendEmail.statusNotification'),
              description: t('sendEmail.sendCustomerEmailNotification'),
            })
          }
          break
        case SendEmailType.Agreements:
          result = await sendEmailCustomerAgreements({ id, emails })

          if ('data' in result) {
            dispatch(closeSendEmail())
            notification.success({
              message: t('sendEmail.statusNotification'),
              description: t('sendEmail.sendCustomerEmailNotification'),
            })
          }
          break
        case SendEmailType.Invoices:
          result = await sendEmail({ id, emails })

          if ('data' in result) {
            dispatch(closeSendEmail())
            notification.success({
              message: t('sendEmail.statusNotification'),
              description: t('sendEmail.sendCustomerEmailNotification'),
            })
          }
          break
        case SendEmailType.Debts:
          result = await sendDebtEmail({ id, emails })

          if ('data' in result) {
            dispatch(closeSendEmail())
            notification.success({
              message: t('sendEmail.debtNotification'),
              description: t('sendEmail.sendDebtEmailNotification'),
            })
          }
          break
        case SendEmailType.TransportationBills:
          result = await sendEmailTransportation({ id, emails })

          if ('data' in result) {
            dispatch(closeSendEmail())
            notification.success({
              message: 'Email Status Notification',
              description: 'Agreement has been successfully send to customer.',
            })
          }
          break
        case SendEmailType.ServiceInvoice:
          result = await sendCustomerServiceInvoice({ id, emails })

          if ('data' in result) {
            dispatch(closeSendEmail())
            notification.success({
              message: t('sendEmail.debtNotification'),
              description: t('sendEmail.sendDebtEmailNotification'),
            })
          }
          break
        case SendEmailType.AgreementOrder:
          result = await sendEmailCustomerAgreementOrder({ id, emails })

          if ('data' in result) {
            dispatch(closeSendEmail())
            notification.success({
              message: t('sendEmail.statusNotification'),
              description: t('sendEmail.sendCustomerEmailNotification'),
            })
          }
          break
        case SendEmailType.DetailsOffers:
          result = await sendEmailDetailsOffers({ id, emails })

          if ('data' in result) {
            dispatch(closeSendEmail())
            notification.success({
              message: t('sendEmail.statusNotification'),
              description: t('sendEmail.sendCustomerEmailNotification'),
            })
          }
          break
        default:
          break
      }
    }

    dispatch(invoicesApi.util?.invalidateTags(['Invoices']))
    dispatch(accountingApi.util?.invalidateTags(['Accounting']))

    setIsLoadingSendEmail(false)
  }

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewEmail(event.target.value)
  }

  const addItem = (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    e.preventDefault()
    setNewEmailList((prevState) => [...prevState, newEmail])
    setNewEmail('')
  }

  const options = useMemo(() => {
    const optionsResult: Array<{value: string, label: any}> = []

    const customerWorkersList = customerWorkers?.map((worker) => ({
      value: worker.email,
      label: (
        <div>
          <div>{worker.email}</div>
          <div>
            {worker.name}
          </div>
        </div>
      ),
    })) || []
    optionsResult.push(...customerWorkersList)

    if (isAccountant) {
      optionsResult.push(...accountantEmails)
    }

    const customList = newEmailList.map((item) => ({
      value: item,
      label: item,
    }))
    optionsResult.push(...customList)

    return optionsResult
  }, [customerWorkers, isAccountant, newEmailList])

  return (
    <Modal
      title={t('sendEmail.title')}
      open={!!type}
      confirmLoading={isLoadingSendEmail}
      width={416}
      onCancel={() => dispatch(closeSendEmail())}
      centered
      className={style.sendModal}
      closeIcon={<CloseModalIcon />}
      footer={[
        <Button
          key="sendEmailCancel"
          onClick={() => dispatch(closeSendEmail())}
          className={style.modalButtonCancel}
        >
          {t('sendEmail.cancel')}
        </Button>,
        <Button
          key="sendEmailSend"
          type="primary"
          loading={isLoadingSendEmail}
          onClick={() => formRef.current.submitForm()}
          className={style.modalButton}
        >
          {t('sendEmail.send')}
        </Button>,
      ]}
    >
      <div className={style.descriptionWrapper}>
        <DocumentIcon color="#64676B" />
        <p className={style.descriptionText}>{documentName}</p>
      </div>
      <Formik
        enableReinitialize
        innerRef={formRef}
        initialValues={initialValues}
        onSubmit={(values) => onSubmit(values.email)}
        validationSchema={validationSchema}
      >
        <Form style={{ padding: 0 }}>
          <SelectFormik
            name="email"
            placeholder="Choose emails"
            isMultiple
            options={options}
            dropdownRender={(menu) => (
              <>
                {menu}
                <div
                  className={style.dropdownWrapper}
                >
                  <Input
                    className={style.input}
                    placeholder="Add email"
                    value={newEmail}
                    onChange={onNameChange}
                    onKeyDown={(e) => e.stopPropagation()}
                  />
                  <Button
                    size="large"
                    onClick={addItem}
                    disabled={!newEmail}
                    className={style.plusButton}
                  >
                    <PlusIcon />
                  </Button>
                </div>
              </>
            )}
          />
        </Form>
      </Formik>
    </Modal>
  )
}
