import React from 'react'
import { Card, Row, Col } from 'antd'
import { Button } from '@src/components/ui/Button/Button'
import { ReactComponent as EditIcon } from '@src/assets/edit_icon.svg'
import style from './DetailsCard.module.scss'

interface DetailItem {
  label: string;
  value?: string | React.ReactNode;
}

interface DetailsCardProps {
  data: DetailItem[];
  isLoading: boolean;
  buttonLabel?: string;
  buttonAction?: () => void;
  showButton?: boolean;
  children?: React.ReactNode;
}

export const DetailsCard: React.FC<DetailsCardProps> = ({
  data,
  isLoading,
  buttonLabel = 'Edit',
  buttonAction,
  showButton = true,
  children,
}) => (
  <Card loading={isLoading} className={style.card}>
    <Row gutter={[8, 8]}>
      {data.map((item) => (
        <React.Fragment key={crypto.randomUUID()}>
          <Col span={12}>
            <p className={style.leftText}>{`${item.label}:`}</p>
          </Col>
          <Col span={12}>
            <p className={style.rightText}>{item.value || ''}</p>
          </Col>
        </React.Fragment>
      ))}
      {children}
    </Row>
    {showButton && (
      <Button
        className={style.buttonEdit}
        size="large"
        leftIcon={<EditIcon />}
        htmlType="button"
        type="primary"
        onClick={buttonAction}
        style={{ marginTop: '24px' }}
      >
        {buttonLabel}
      </Button>
    )}
  </Card>
)
