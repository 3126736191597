import React, { FC, Fragment, useMemo } from 'react'
import { Form, Formik } from 'formik'
import { App, Button, Col, Divider, Drawer, Row } from 'antd'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { DatePickerFormik } from '@src/components/DatePickerFormik/DatePickerFormik'
import style from '@src/Pages/Agreements/agreements.module.scss'
import { UploadImages } from '@src/components/UploadImages/UploadImages'
import moment from 'moment/moment'
import { UploadFile } from 'antd/es/upload/interface'
import { useTranslation } from 'react-i18next'
import { agreementsApi } from '@src/store/services/agreements-service'
import { Preloader } from '@src/components/Preloader/Preloader'
import * as Yup from 'yup'
import { serialize } from 'object-to-formdata'

type CloseAgreementFormProps = {
  agreementOrderId: number | null
  onClose: () => void
}

export const CloseAgreementOrderForm: FC<CloseAgreementFormProps> = ({ agreementOrderId, onClose }) => {
  const { modal } = App.useApp()
  const { t } = useTranslation()
  const { data, isLoading } = agreementsApi.useGetOneAgreementOrderQuery(agreementOrderId!, { skip: !agreementOrderId })
  const [closeAgreements, { isLoading: isLoadingClose }] = agreementsApi.useCloseAgreementOrderMutation()
  // const [closeSpecialAgreements, { isLoading: isLoadingCloseSpecial }] = agreementsApi.useCloseSpecialAgreementOrderMutation()

  const validationSchema = useMemo(
    () => (
      Yup.object().shape({
        car_engine_log: Yup.object().shape({
          engine_hours_end: Yup.string().required(t('form.errors.required') ?? ''),
        }),
        date_end: Yup.string().required(t('form.errors.required') ?? ''),
        services: Yup.string().when('paid_once', {
          is: true,
          then: () => Yup.array().of(Yup.object().shape({
            name: Yup.string().required(t('form.errors.required') ?? ''),
            hours: Yup.string().required(t('form.errors.required') ?? ''),
            price_hour: Yup.string().required(t('form.errors.required') ?? ''),
          })),
          otherwise: () => Yup.mixed().nullable(),
        }),
      })),
    [t],
  )

  const initialValuesClose = useMemo(() => ({
    car: `${data?.car?.make} ${data?.car?.model}`,
    car_serial_number: data?.car?.serial_number,
    car_engine_log: {
      engine_hours_start: data?.car_engine_log?.engine_hours_start || '',
      engine_hours_end: data?.car_engine_log?.engine_hours_end,
    },
    date_start: moment(data?.date_start),
    date_end: data?.date_end ? moment(data?.date_end) : '',
    comment: data?.comment ?? '',
    defect_comment: data?.defect_comment ?? '',
    fileList: [] as UploadFile[],
    paid_once: !!data?.car.paid_once,
    services: [{
      name: '',
      hours: '',
      price_hour: '',
      total_price: '',
      agreement_id: data?.id,
    }],
  }), [data])

  const onSubmit = async (values: any) => {
    // todo тут когда-то был closeSpecialAgreements по if data?.car.paid_once

    const body = {
      id: agreementOrderId,
      car: values.car,
      car_serial_number: values.car_serial_number,
      car_engine_log: {
        engine_hours_start: values.car_engine_log.engine_hours_start,
        engine_hours_end: `${values.car_engine_log.engine_hours_end}`,
      },
      date_start: values.date_start.format('YYYY-MM-DD'),
      date_end: values.date_end.format('YYYY-MM-DD'),
      comment: values.comment,
      defect_comment: values.defect_comment,
      images: values.fileList.map((item: any) => item.originFileObj),
    }

    const result = await closeAgreements({
      body: serialize(body, { indices: true }),
    })

    if ('data' in result) {
      onClose()
      modal.info({
        title: '',
        content: t('agreementsClose.tankFullModal'),
      })
    }
  }

  return (
    <Drawer
      title={t('agreementsClose.title')}
      open={!!agreementOrderId}
      onClose={onClose}
      width={554}
    >
      <Preloader loading={isLoading}>
        <Formik
          initialValues={initialValuesClose}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ setFieldValue, values }) => (
            <Form>
              <Row gutter={[20, 0]}>
                <Col xs={24} md={12}>
                  <FieldFormik
                    name="car"
                    placeholder={t('agreementsClose.car') ?? ''}
                    disabled
                  />
                </Col>
                <Col xs={24} md={12}>
                  <FieldFormik
                    name="car_serial_number"
                    placeholder={t('agreementsClose.carSerialNumber') ?? ''}
                    disabled
                  />
                </Col>
                <Col xs={24} md={12}>
                  <FieldFormik
                    name="car_engine_log.engine_hours_start"
                    placeholder={t('agreementsClose.engineHoursStart') ?? ''}
                    disabled
                  />
                </Col>
                <Col xs={24} md={12}>
                  <FieldFormik
                    name="car_engine_log.engine_hours_end"
                    placeholder={t('agreementsClose.engineHoursEnd') ?? ''}
                    type="number"
                    onChange={(e) => {
                      setFieldValue('services[0].hours', +e.target.value - +values.car_engine_log.engine_hours_start)
                    }}
                  />
                </Col>
                <Col xs={24} md={24}>
                  <FieldFormik
                    type="textarea"
                    name="comment"
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    placeholder={t('agreementsClose.comment') ?? ''}
                  />
                </Col>
                <Col xs={24} md={24}>
                  <FieldFormik
                    type="textarea"
                    name="defect_comment"
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    placeholder={t('agreementsClose.commentClose') ?? ''}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <DatePickerFormik name="date_start" placeholder={t('agreementsClose.dateStart') ?? ''} disabled />
                </Col>
                <Col xs={24} md={12}>
                  <DatePickerFormik name="date_end" placeholder={t('agreementsClose.dateEnd') ?? ''} />
                </Col>
              </Row>
              {!!data?.car.paid_once && (
                <div>
                  <Divider orientation="left">
                    {t('agreementsClose.services')}
                  </Divider>
                  <Row gutter={[20, 0]}>
                    {values.services.map((item: any, i: any) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Fragment key={i}>
                        <Col xs={24} md={6}>
                          <FieldFormik
                            name={`services[${i}].name`}
                            placeholder={t('agreementsClose.servicesName') ?? ''}
                          />
                        </Col>
                        <Col xs={24} md={6}>
                          <FieldFormik
                            name={`services[${i}].hours`}
                            placeholder={t('agreementsClose.servicesHours') ?? ''}
                            type="number"
                            onChange={(e) => {
                              setFieldValue(`services[${i}].total_price`, +e.target.value * +values.services[i].price_hour)
                            }}
                          />
                        </Col>
                        <Col xs={24} md={6}>
                          <FieldFormik
                            name={`services[${i}].price_hour`}
                            placeholder={t('agreementsClose.servicesPriceHour') ?? ''}
                            type="number"
                            onChange={(e) => {
                              setFieldValue(`services[${i}].total_price`, +e.target.value * +values.services[i].hours)
                            }}
                          />
                        </Col>
                        <Col xs={24} md={6}>
                          <FieldFormik
                            name={`services[${i}].total_price`}
                            placeholder={t('agreementsClose.servicesTotalPrice') ?? ''}
                            type="number"
                            disabled
                          />
                        </Col>
                      </Fragment>
                    ))}
                  </Row>
                  <Button
                    onClick={() => {
                      setFieldValue(
                        'services',
                        [...values.services, { name: '', hours: '', price_hour: '', total_price: '', agreement_id: data.id }],
                      )
                    }}
                  >
                    +
                  </Button>
                </div>
              )}
              <div className={style.upload}>
                <UploadImages
                  fileList={values.fileList}
                  setFileList={(newData) => { setFieldValue('fileList', newData) }}
                />
              </div>

              <Button
                htmlType="submit"
                type="primary"
                loading={isLoadingClose}
              >
                {t('agreementsClose.submit')}
              </Button>
            </Form>
          )}
        </Formik>
      </Preloader>
    </Drawer>
  )
}
