import { combineReducers, configureStore, isRejectedWithValue, Middleware, MiddlewareAPI } from '@reduxjs/toolkit'
import { notification } from 'antd'
import { setupListeners } from '@reduxjs/toolkit/query'
import { appReducer } from '@src/store/ducks/app/reducer'
import { carsReducer } from '@src/store/ducks/cars/reducer'
import { statisticsApi } from '@src/store/services/statistics-service'
import { i18n } from '@src/locales'
import { invoicesApi } from '@src/store/services/invoices-service'
import { customerWorkersApi } from '@src/store/services/customerWorkers-service'
import { debtsApi } from '@src/store/services/debts-service'
import { monthlyPaymentsApi } from '@src/store/services/mounthly-paymenys-service'
import { mailLogsApi } from '@src/store/services/mailLogs-service'
import { standardWorkApi } from '@src/store/services/standard-work-service'
import { companiesApi } from '@src/store/services/companies-service'
import { carsApi } from '@src/store/services/cars-service'
import { agreementsApi } from '@src/store/services/agreements-service'
import { transportationApi } from '@src/store/services/transportation-service'
import { settingsApi } from '@src/store/services/settings-service'
import { usersApi } from '@src/store/services/users-service'
import { accountingApi } from '@src/store/services/accounting-service'
import { tasksApi } from '@src/store/services/tasks-service'
import { detailsApi } from '@src/store/services/details-service'
import { trucksApi } from '@src/store/services/trucks-service'
import { authApi } from '@src/store/services/auth-service'
import { serviceTemplatesApi } from '@src/store/services/serviceTemplates-service'
import { carCategoriesApi } from '@src/store/services/car-categories-service'
import { serviceInvoicesApi } from '@src/store/services/serviceInvoices-service'
import { stocksApi } from '@src/store/services/stocks-service'
import { inventoriesApi } from '@src/store/services/inventories-service'
import { suppliersApi } from '@src/store/services/suppliers-service'
import { notificationApi } from './services/notification-service'
import { inquiriesApi } from './services/inquiries-service'

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action: any) => {
  if (isRejectedWithValue(action)) {
    if (action.payload.status === 401) {
      localStorage.removeItem('token')
      if (action.meta.arg.endpointName !== 'getMe') {
        api.dispatch(authApi.util.resetApiState())
      }
    } else {
      notification.error({
        message: i18n.t('errorTitle'),
        description: action.payload.data?.message || action.payload.data?.error || action.payload.error,
      })
    }
  }

  return next(action)
}

const combinedReducer = combineReducers({
  app: appReducer,
  cars: carsReducer,
  [statisticsApi.reducerPath]: statisticsApi.reducer,
  [invoicesApi.reducerPath]: invoicesApi.reducer,
  [debtsApi.reducerPath]: debtsApi.reducer,
  [monthlyPaymentsApi.reducerPath]: monthlyPaymentsApi.reducer,
  [customerWorkersApi.reducerPath]: customerWorkersApi.reducer,
  [standardWorkApi.reducerPath]: standardWorkApi.reducer,
  [mailLogsApi.reducerPath]: mailLogsApi.reducer,
  [companiesApi.reducerPath]: companiesApi.reducer,
  [carsApi.reducerPath]: carsApi.reducer,
  [agreementsApi.reducerPath]: agreementsApi.reducer,
  [transportationApi.reducerPath]: transportationApi.reducer,
  [settingsApi.reducerPath]: settingsApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [accountingApi.reducerPath]: accountingApi.reducer,
  [tasksApi.reducerPath]: tasksApi.reducer,
  [detailsApi.reducerPath]: detailsApi.reducer,
  [trucksApi.reducerPath]: trucksApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [serviceTemplatesApi.reducerPath]: serviceTemplatesApi.reducer,
  [carCategoriesApi.reducerPath]: carCategoriesApi.reducer,
  [serviceInvoicesApi.reducerPath]: serviceInvoicesApi.reducer,
  [stocksApi.reducerPath]: stocksApi.reducer,
  [inventoriesApi.reducerPath]: inventoriesApi.reducer,
  [notificationApi.reducerPath]: notificationApi.reducer,
  [inquiriesApi.reducerPath]: inquiriesApi.reducer,
  [suppliersApi.reducerPath]: suppliersApi.reducer,
})

const middleware = [
  statisticsApi.middleware,
  invoicesApi.middleware,
  mailLogsApi.middleware,
  debtsApi.middleware,
  monthlyPaymentsApi.middleware,
  standardWorkApi.middleware,
  customerWorkersApi.middleware,
  companiesApi.middleware,
  carsApi.middleware,
  agreementsApi.middleware,
  transportationApi.middleware,
  settingsApi.middleware,
  usersApi.middleware,
  accountingApi.middleware,
  tasksApi.middleware,
  detailsApi.middleware,
  trucksApi.middleware,
  authApi.middleware,
  serviceTemplatesApi.middleware,
  carCategoriesApi.middleware,
  serviceInvoicesApi.middleware,
  stocksApi.middleware,
  inventoriesApi.middleware,
  notificationApi.middleware,
  inquiriesApi.middleware,
  suppliersApi.middleware,
  rtkQueryErrorLogger,
]

const rootReducer = (state: any, action: any) => {
  let tmpState = state
  if (action.type === 'app/logoutAC') {
    tmpState = undefined
  }
  return combinedReducer(tmpState, action)
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleware),
  devTools: process.env.NODE_ENV !== 'production',
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
