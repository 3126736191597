import React, { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useAgreementsTable } from '@src/components/pages/agreements/AgreementsTable.hook'
import useQuery from '@src/hooks/useQuery'
import { PageTable } from '@src/components/Table/PageTable'
import { TableFiltersType } from '@src/types/tableFilters'
import { companiesApi } from '@src/store/services/companies-service'
import { CompanyType } from '@src/types/company'
import { agreementsApi } from '@src/store/services/agreements-service'
import { addQuery } from '@src/lib/route'

export const Agreements = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { data: ownersSelect } = companiesApi.useGetSelectCompaniesQuery(CompanyType.Owner)
  const { data: customerSelect } = companiesApi.useGetSelectCompaniesQuery(CompanyType.Customer)
  const [exportAgreements, { isLoading: isLoadingExport }] = agreementsApi.useExportAgreementsMutation()
  const pageQuery = useQuery('page')
  const filtersQuery = useQuery('filters')

  const { columns, data, paramsFilters, setParamsFilters, isLoading } = useAgreementsTable({
    initParamsFilters: {
      page: pageQuery ? +pageQuery : 1,
      filters: filtersQuery ? JSON.parse(filtersQuery) : {},
    },
  })

  useEffect(() => {
    history.push({
      search: addQuery({ ...paramsFilters, filters: JSON.stringify(paramsFilters.filters) }),
    })
  }, [paramsFilters, history])

  const filtersList = useMemo<TableFiltersType>(() => [
    [
      {
        type: 'input',
        dataIndex: 'agreement_number',
        operator: '$contains',
        placeholder: t('agreements.columns.agreementNumber'),
        width: '150px',
      },
      {
        type: 'select',
        dataIndex: 'customer_id',
        operator: '$eq',
        placeholder: t('agreements.columns.customer'),
        width: '200px',
        options: customerSelect?.map((item) => ({ value: item.id, label: item.name })) || [],
        showSearch: true,
      },
      {
        type: 'select',
        dataIndex: 'owner_id',
        operator: '$eq',
        placeholder: t('agreements.columns.owner'),
        width: '200px',
        options: ownersSelect?.map((owner) => ({ value: owner.id, label: owner.name })) || [],
      },
      {
        type: 'rangePicker',
        dataIndex: 'date',
        placeholder: [t('filters.dateStartFrom'), t('filters.dateStartTo')],
      },
    ],
  ], [customerSelect, ownersSelect, t])

  return (
    <div>
      <PageTable
        title={t('agreements.title')!}
        createLink="/agreements/create"
        filtersList={filtersList}
        setParamsFilters={setParamsFilters}
        paramsFilters={paramsFilters}
        filtersV2
        onExport={exportAgreements}
        isLoadingExport={isLoadingExport}
        tableData={{
          ...data,
          isLoading,
          columns,
          components: {
            body: {
              row: (props: any) => (
                <tr
                  {...props}
                  style={{
                    height: '64px',
                    background: data?.data.find((item: any) => item.id === props['data-row-key'])?.owner.color,
                  }}
                />
              ),
            },
          },
        }}
      />
    </div>
  )
}
