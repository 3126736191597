import { useCallback, useMemo, useState } from 'react'
import { ColumnProps } from 'antd/lib/table'
import { Agreement } from '@src/types/agreements'
import cn from 'classnames'
import { ReactComponent as DocumentIcon } from '@src/assets/document_icon.svg'
import { ReactComponent as SendMailIcon } from '@src/assets/send-mail_icon.svg'
import { ReactComponent as DeleteIcon } from '@src/assets/delete_icon.svg'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { agreementsApi } from '@src/store/services/agreements-service'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@src/store/store'
import { setSendEmail } from '@src/store/ducks/app/reducer'
import { SendEmailType } from '@src/types/mailLogs'
import { isSelectionText } from '@src/lib/utils'
import { Confirm } from '@src/components/Confirm/Confirm'

export const useAgreementsTable = ({ initParamsFilters, skip }: any) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch<AppDispatch>()
  const [paramsFilters, setParamsFilters] = useState(initParamsFilters)
  const { data, isLoading } = agreementsApi.useGetListAgreementsQuery(paramsFilters, { skip })
  const [deleteAgreement] = agreementsApi.useDeleteAgreementMutation()
  const [generateDocument] = agreementsApi.useAgreementGenerateDocumentMutation()

  const handleDelete = useCallback((record: Agreement) => (
    deleteAgreement(record.id!)
  ), [deleteAgreement])

  const onCell = useCallback((record: Agreement) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/agreements/view/${record.id}`)
      }
    },
  }), [history])

  const columns = useMemo<ColumnProps<Agreement>[]>(() => [
    {
      title: t('agreements.columns.dateStart'),
      dataIndex: 'agreement_number',
      key: 'agreement_number',
      onCell,
    },
    {
      title: t('agreements.columns.customer'),
      dataIndex: 'customer',
      key: 'customer',
      render: (customer) => customer.full_name,
      onCell,
    },
    {
      title: t('agreements.columns.customerRepresentative'),
      dataIndex: 'customer_representative',
      key: 'customer_representative',
      render: (customerRepresentative) => customerRepresentative.name,
      onCell,
    },
    {
      title: t('agreements.columns.owner'),
      dataIndex: 'owner',
      key: 'owner',
      render: (owner) => owner.full_name,
      onCell,
    },
    {
      title: t('agreements.columns.ownerRepresentative'),
      dataIndex: 'owner_representative',
      key: 'owner_representative',
      render: (ownerRepresentative) => ownerRepresentative.name,
      onCell,
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '104px',
      fixed: 'right',
      render: (_, record) => (
        <div className={cn('table_actions')}>
          <button
            type="button"
            onClick={() => {
              generateDocument(record.id)
            }}
            className={cn('table_actions_item')}
          >
            <DocumentIcon />
          </button>
          <button
            type="button"
            onClick={() => {
              dispatch(setSendEmail({
                type: SendEmailType.Agreements,
                companyId: record.customer.id,
                id: record.id,
              }))
            }}
            className={cn('table_actions_item')}
          >
            <SendMailIcon />
          </button>
          <Confirm
            title={t('deleteModal.deleteConfirmationTitle')}
            content={t('deleteModal.deleteConfirmation', { localizedType: t('deleteModal.agreement') })}
            okText={t('deleteModal.delete')}
            cancelText={t('deleteModal.cancel')}
            onOk={() => handleDelete(record)}
            className={cn('table_actions_item', 'table_actions_item__delete')}
          >
            <DeleteIcon />
          </Confirm>
        </div>
      ),
    },
  ], [
    dispatch,
    generateDocument,
    onCell,
    handleDelete,
    t,
  ])

  return {
    columns,
    data,
    isLoading,
    paramsFilters,
    setParamsFilters,
  }
}
