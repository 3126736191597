import { FC, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Card, Col, Input, Row } from 'antd'
import { PaginationFormik } from '@src/components/PaginationFormik/PaginationFormik'
import { Preloader } from '@src/components/Preloader/Preloader'
import { ReactComponent as GlassIcon } from '@src/assets/glass_icon.svg'
import { ResponseMetaType } from '@src/api/api'
import { Company } from '@src/types/company'
import { FloatingButtons } from './FloatingButtons'
import style from './driver.module.scss'

type DriverCustomersType = {
  customers?: {
    data: Company[]
    meta: ResponseMetaType
  }
  setParamsFilters: (state: any) => void
  paramsFilters?: any
  isLoading: boolean
}

export const DriverCustomers: FC<DriverCustomersType> = ({ customers, setParamsFilters, paramsFilters, isLoading }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const handlePaginationChange = useCallback((page: number) => {
    setParamsFilters((prevState: any) => ({
      ...prevState,
      page,
    }))
  }, [setParamsFilters])

  const handleFilters = useCallback((e: any) => {
    setParamsFilters((prevState: any) => ({
      ...prevState,
      page: 1,
      filters: {
        ...prevState.filters,
        ...e,
      },
    }))
  }, [setParamsFilters])

  const handleCustomerClick = useCallback((id: number) => {
    history.push(`/companies/view/${id}`)
  }, [history])

  const filters = useMemo(() => [
    {
      type: 'input',
      dataIndex: 'name',
      operator: '$contains',
      placeholder: t('table.filtersName'),
    },
  ], [t])

  return (
    <Preloader loading={isLoading}>
      <Col span={24}>
        <div className={style.title}>
          <h2>
            {t('menu.customers')}
          </h2>
          {filters.map((filter) => (
            <Input
              key={filter.dataIndex}
              defaultValue={paramsFilters?.filters?.[filter.dataIndex]?.[filter.operator] || ''}
              type="text"
              allowClear
              placeholder={filter.placeholder}
              suffix={<GlassIcon />}
              onChange={(event) => {
                handleFilters({
                  [filter.dataIndex]: {
                    [filter.operator!]: event.target.value ? event.target.value : undefined,
                  },
                })
              }}
              style={{
                width: '100%',
                height: '38px',
                borderRadius: '8px',
                padding: '8px 12px',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: '22px', /* 157.143% */
              }}
            />
          ))}
        </div>
      </Col>
      <Row gutter={[24, 8]}>
        {customers?.data?.map((customer) => (
          <Col span={24} key={customer.id}>
            <Card
              className={style.card}
              onClick={() => handleCustomerClick(customer.id)}
            >
              <Row gutter={[8, 0]}>
                <Col span={8}>
                  <p className={style.placeholder}>
                    {t('customerCreate.name')}
                  </p>
                  <p className={style.info}>
                    {customer?.name}
                  </p>
                </Col>
                <Col span={16}>
                  <p className={style.placeholder}>
                    {t('customerCreate.address')}
                  </p>
                  <p className={style.info}>
                    {customer?.address}
                  </p>
                </Col>
              </Row>
            </Card>
          </Col>
        ))}
      </Row>
      {customers?.meta.lastPage && customers?.meta.lastPage > 1 && (
        <div className={style.paginations}>
          <PaginationFormik
            total={customers?.meta?.totalCount || 0}
            current={customers?.meta?.currentPage || 1}
            pageSize={customers?.meta?.pageSize || 10}
            onChange={handlePaginationChange}
          />
        </div>
      )}
      <Col>
        <FloatingButtons />
      </Col>
    </Preloader>
  )
}
