import React, { FC, useState } from 'react'
import { Image } from 'antd'
import styles from '@src/components/ImagesPreview/Preview.module.scss'
import { ReactComponent as DeleteIcon } from '@src/assets/delete_icon.svg'
import { EyeOutlined } from '@ant-design/icons'

type ImageArray = {
  id: number
  image_s3: string
  thumb_s3: string
}

type ImageProps = {
  style?: React.CSSProperties
  className?: string
  attributes?: Record<string, any>
  listeners?: Record<string, any>
  onImageDelete?: (imageId?: number) => any
  imageList: ImageArray[]
}

export const Images: FC<ImageProps> = ({ style, attributes, listeners, onImageDelete, imageList = [] }) => {
  const flatImageList = Object.values(imageList).flat()
  const [previewVisible, setPreviewVisible] = useState(false)
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)

  const handleImageClick = (index: number) => {
    setCurrentImageIndex(index)
    setPreviewVisible(true)
  }

  const handleDeleteClick = (imageId: number, e: React.MouseEvent) => {
    e.stopPropagation()
    if (onImageDelete) {
      onImageDelete(imageId)
    }
  }

  return (
    <>
      <div
        style={style}
        className={styles.imgContainer}
        {...attributes}
        {...listeners}
      >
        {flatImageList.map((item, index) => (
          <div
            key={item.id}
            className={styles.imageWrapper}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            <button
              onClick={() => handleImageClick(index)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleImageClick(index)
                }
              }}
              type="button"
            >
              <img
                src={`${item.thumb_s3}`}
                alt={`Thumbnail ${index}`}
                style={{
                  width: 98,
                  height: 81,
                  objectFit: 'cover',
                  borderRadius: 16,
                  boxShadow: 'none',
                }}
              />
            </button>
            {hoveredIndex === index && (
            <div className={styles.actions}>
              <button
                className={styles.iconButton}
                aria-label={`View image ${index + 1}`}
                onClick={() => handleImageClick(index)}
                type="button"
              >
                <EyeOutlined className={styles.icon} />
              </button>
              {onImageDelete && (
              <button
                className={styles.iconButton}
                aria-label={`Delete image ${index + 1}`}
                onClick={(e) => handleDeleteClick(item.id, e)}
                type="button"
              >
                <DeleteIcon className={styles.icon} />
              </button>
              )}
            </div>
            )}
          </div>
        ))}
      </div>

      {previewVisible && (
      <Image.PreviewGroup
        preview={{
          visible: previewVisible,
          current: currentImageIndex,
          onVisibleChange: (visible) => setPreviewVisible(visible),
          onChange: (index) => {
            setCurrentImageIndex(index)
          },
        }}
      >
        {flatImageList.map((item, index) => (
          <Image
            key={item.id}
            src={item.image_s3}
            alt={`Image ${index}`}
            style={{ display: 'none' }}
          />
        ))}
      </Image.PreviewGroup>
      )}
    </>
  )
}
