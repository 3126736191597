import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Card, Col, Row, Table } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { ReactComponent as EditIcon } from '@src/assets/edit_icon.svg'
import { ViewContentHeader } from '@src/components/ViewContentHeader/ViewContentHeader'
import { transportationApi } from '@src/store/services/transportation-service'
import { ColumnProps } from 'antd/lib/table'
import { Images } from '@src/components/ImagesPreview/Image'
import { TransportationShortType } from '@src/types/transportation'
import { TransportationDocument } from '@src/components/pages/transportation/TransportationDocument'
import { NoData } from '@src/components/NoData/NoData'
import style from './transportationView.module.scss'

export const TransportationView = () => {
  const { t } = useTranslation()
  const params = useParams<{ id: string }>()
  const history = useHistory()
  const [activeTabKey, setActiveTabKey] = useState<string>('agreement')
  const { data, isLoading } = transportationApi.useGetOneTransportationQuery(+params.id!, { skip: !params.id })
  const [deleteImage] = transportationApi.useDeleteImageMutation()

  const hanldeTruckCkick = useCallback(() => (
    history.push(`/trucks/view/${data?.truck_id}`)
  ), [history, data])

  const handleDeleteImage = async (id?: number) => {
    if (id === undefined) {
      return
    }
    await deleteImage({ id })
  }

  const columns = useMemo<ColumnProps<TransportationShortType>[]>(() => [
    {
      title: t('transportation.columns.addressFrom'),
      dataIndex: 'address_from',
      key: 'address_from',
    },
    {
      title: t('transportation.columns.addressTo'),
      dataIndex: 'address_to',
      key: 'address_to',
    },
    {
      title: t('transportation.columns.distanceView'),
      dataIndex: 'distance',
      key: 'distance',
    },
    {
      title: t('transportation.columns.cargo'),
      dataIndex: 'cargo',
      key: 'cargo',
      render: (cargo) => cargo?.name,
    },
    {
      title: t('transportation.columns.unit'),
      dataIndex: 'cargo',
      key: 'unit',
      render: (cargo) => cargo?.unit,
    },
    {
      title: t('transportation.columns.amount'),
      dataIndex: 'cargo',
      key: 'amount',
      render: (cargo) => cargo?.amount,
    },
    {
      title: t('transportation.columns.weight'),
      dataIndex: 'cargo',
      key: 'weight',
      render: (cargo) => cargo?.weight,
    },
  ], [t])

  return (
    <>
      {data && (
        <ViewContentHeader
          onBackClick={() => history.push('/transportation')}
        >
          {`${data.date} ${data?.from?.full_name} - ${data?.to?.full_name}`}
        </ViewContentHeader>
      )}
      <Row gutter={[24, 0]}>
        <Col xs={24} lg={24}>
          <h2 className={style.title}>
            {t('transportationCreate.transportationInfo')}
          </h2>
          <Table
            dataSource={data?.points}
            columns={columns}
            loading={isLoading}
            rowKey="id"
            scroll={{ x: 'max-content' }}
            pagination={false}
            onRow={() => ({
              style: {
                height: '64px',
                lineHeight: '64px',
              },
            })}
          />
        </Col>
        <Col xs={24} lg={24}>
          <Card className={style.card} style={{ marginTop: '24px' }}>
            <Row gutter={[8, 0]}>
              <Col span={24}>
                <div className={style.distanceWrapper}>
                  <p className={style.distance}>
                    {t('transportationCreate.distanceTitle')}
                  </p>
                  {data?.total_distance && (
                    <p className={style.distanceText}>
                      {`${data?.total_distance} km`}
                    </p>
                  )}
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} lg={12}>
          <h2 className={style.title}>
            {t('transportationCreate.routeInfo')}
          </h2>
          <Card className={style.card_margin}>
            <Row gutter={[8, 16]}>
              <Col span={8}>
                <p className={style.cardTitle}>{`${t('transportationCreate.from')}:`}</p>
                <p className={style.cardText}>{data?.from?.full_name}</p>
              </Col>
              <Col span={8}>
                <p className={style.cardTitle}>{`${t('transportationCreate.to')}:`}</p>
                <p className={style.cardText}>{data?.to?.full_name}</p>
              </Col>
              <Col span={8}>
                <p className={style.cardTitle}>{`${t('transportationCreate.cargoIssued')}:`}</p>
                <p className={style.cardText}>{data?.cargo_issued}</p>
              </Col>
              <Col span={8}>
                <p className={style.cardTitle}>{`${t('transportationCreate.driver')}:`}</p>
                <p className={style.cardLink}>{data?.driver?.full_name}</p>
              </Col>
              <Col span={8}>
                <p className={style.cardTitle}>{`${t('transportationCreate.truck')}:`}</p>
                <p
                  className={style.cardLink}
                  onClick={hanldeTruckCkick}
                >
                  {`${data?.truck.make} ${data?.truck.model}`}
                </p>
              </Col>
              <Col span={8}>
                <p className={style.cardTitle}>{`${t('transportationCreate.date')}:`}</p>
                <p className={style.cardText}>{data?.date}</p>
              </Col>
              <Col span={8}>
                <p className={style.cardTitle}>{`${t('transportationCreate.comment')}:`}</p>
                <p className={style.cardText}>{data?.comment}</p>
              </Col>
              <Col span={24}>
                <Button
                  type="primary"
                  icon={<EditIcon />}
                  className="card-button"
                  onClick={() => history.push(`/transportation/edit/${params.id}`)}
                >
                  {t('carCategories.edit')}
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} lg={12}>
          <Card
            className={style.carCardStyle}
            loading={isLoading}
            activeTabKey={activeTabKey}
            onTabChange={setActiveTabKey}
            tabList={[
              { key: 'agreement', label: t('transportationView.tabs.agreement') },
              { key: 'images', label: t('transportationView.tabs.gallery') },
            ]}
            tabProps={{
              size: 'middle',
            }}
          >
            <Row gutter={[8, 0]}>
              {activeTabKey === 'agreement' && (
                <Col span={24}>
                  <Card className={style.card} style={{ padding: '12px 24px 16px 24px' }}>
                    {data && (
                      <TransportationDocument values={data} />
                    )}
                  </Card>
                </Col>
              )}
              {activeTabKey === 'images' && (
                <Col span={24}>
                  {data?.images.length ? (
                    <Card className={style.card} style={{ padding: '12px 24px 16px 24px' }}>
                      <Images
                        imageList={data.images}
                        onImageDelete={handleDeleteImage}
                      />
                    </Card>
                  ) : <NoData />}
                </Col>
              )}
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  )
}
