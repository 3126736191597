import { usePrintableBlock } from '@src/hooks/usePrintableBlock'
import { Button, Card } from 'antd'
import { PrinterOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import { agreementsApi } from '@src/store/services/agreements-service'
import { carsApi } from '@src/store/services/cars-service'
// import { Fragment } from 'react'
import moment from 'moment'
// import { customerWorkersApi } from '@src/store/services/customerWorkers-service'
import style from './Agreement.module.scss'

export const AcceptanceActDocument = () => {
  const { t } = useTranslation()
  const { values } = useFormikContext<any>()
  const { data: agreement } = agreementsApi.useGetOneAgreementQuery(values.agreement_id, { skip: !values.agreement_id })
  const { data: car } = carsApi.useGetOneCarQuery(values.car_id, { skip: !values.car_id })
  // const { data: ownerRepresentative } = customerWorkersApi.useGetOneQuery(values.owner_representative_id, { skip: !values.owner_representative_id })
  // const { data: customerRepresentative } = customerWorkersApi.useGetOneQuery(values.customer_representative_id, { skip: !values.customer_representative_id })

  usePrintableBlock('#printable-block')

  return (
    <Card className={style.documentCard}>
      <div className="agreement_document" id="printable-block">
        <p className="font-bold" style={{ textAlign: 'center' }}>
          TECHNIKOS GRAZINIMO AKTAS
        </p>
        <p className={style.documentBoldText} style={{ textAlign: 'center' }}>
          SPECIALIOSIOS SĄLYGOS numeris:
          {' '}
          {values?.agreementOrderNumber}
          <br />
          Nuomos sutarties numeris:
          {' '}
          {agreement?.agreement_number}
        </p>
        <p className="text" style={{ textAlign: 'center' }}>{moment(values.date_start).format('YYYY-MM-DD')}</p>
        <p className="text" style={{ textAlign: 'center' }}>Vilnius</p>
        <br />
        <p className="text">
          <span style={{ display: 'inline-block', width: 16 }} />
          Šiuo aktu pažymime, kad įrangos
          {' '}
          {car?.make}
          {' '}
          {car?.model}
          , serijos numeris
          {' '}
          {car?.serial_number}
          , perduodamas iš įmonės
          {' '}
          <strong>{agreement?.owner?.name}</strong>
          (į.k.
          {' '}
          {agreement?.owner?.company_code}
          ), į įmonę
          <strong>{agreement?.customer?.name}</strong>
          (į.k.
          {' '}
          {agreement?.customer?.company_code}
          ) žinią ir atsakomybę. Įrangos valandų skaičius, perdavimo metu, yra
          {' '}
          {values?.engine_hours_start}
          {' '}
          moto valandos.
        </p>
        <br />
        <p className="text">
          Nuomininkas informuotas, kad šiam įranga reikalingas kasdieninis tepimas.
        </p>
        <br />
        <p className="font-bold">
          Komentarą:
        </p>
        <p style={{ borderBottom: '1px dotted #000000', height: 24, width: '100%' }} />
        <p style={{ borderBottom: '1px dotted #000000', height: 24, width: '100%' }} />
        <p style={{ borderBottom: '1px dotted #000000', height: 24, width: '100%' }} />
        <p className="text">
          Perdavė:
        </p>
        <p className="text" style={{ textAlign: 'center' }}>
          _________________________________________
          <br />
          (vardas ir pavardė, pareigos, antspaudas, parašas)
        </p>
        <p className="text">
          Priėmė:
        </p>
        <p className="text" style={{ textAlign: 'center' }}>
          _________________________________________
          <br />
          (vardas ir pavardė, pareigos, antspaudas, parašas)
        </p>
      </div>
      <div className="documentActions">
        <Button icon={<PrinterOutlined />} onClick={window.print}>{t('settings.print')}</Button>
      </div>
    </Card>
  )
}
