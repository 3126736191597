import { FC, useRef } from 'react'

import { Button } from '@src/components/ui/Button/Button'
import { ReactComponent as PlusIcon } from '@src/assets/plus_icon.svg'
import { ReactComponent as DeleteIcon } from '@src/assets/delete_icon.svg'

import { Tooltip } from 'antd'
import style from './uploadFiles.module.scss'

type UploadFilesProps = {
  filesList: File[]
  onChange: (data: File[]) => void
}

export const UploadFiles: FC<UploadFilesProps> = ({ filesList, onChange }) => {
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <div>
      <div className={style.list}>
        {filesList.map((file, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={file.name + index} className={style.file}>
            <Tooltip title={file.name}>
              <div className={style.fileName}>
                {file.name}
              </div>
            </Tooltip>

            <div className={style.fileActions}>
              <button type="button" onClick={() => onChange(filesList.filter((_, fIndex) => fIndex !== index))}>
                <DeleteIcon />
              </button>
            </div>
          </div>
        ))}
      </div>
      <br />
      <input
        type="file"
        ref={inputRef}
        className={style.input}
        onChange={(e) => e.target.files && onChange([...filesList, ...e.target.files])}
        value={[]}
        multiple
      />
      <Button
        onClick={() => inputRef.current?.click()}
        type="secondary"
        size="large"
        leftIcon={<PlusIcon />}
      >
        Add files
      </Button>
    </div>
  )
}
