import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { GetItemsType } from '@src/api/api'
import { TransportationBillType } from '@src/types/transportationBills'
import { TransportationType } from '@src/types/transportation'
import { formatFilters } from '@src/lib/formatFilters'
import qs from 'qs'
import { getFileName } from '@src/lib/getFileName'
import { downloadFile } from '@src/lib/downloadFile'

export const transportationApi = createApi({
  reducerPath: 'transportationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      headers.set('Accept', 'application/json, text/plain, */*')

      return headers
    },
  }),
  refetchOnMountOrArgChange: 60,
  tagTypes: ['Transportation'],
  endpoints: (build) => ({
    getListTransportations: build.query<GetItemsType<TransportationType>, { page: number, filters: any }>({
      query: (params) => ({
        url: `/transportations?${formatFilters(params)}`,
      }),
      providesTags: () => ['Transportation'],
    }),
    getOneTransportation: build.query<TransportationType, number>({
      query: (id) => ({
        url: `/transportations/${id}`,
      }),
      providesTags: () => ['Transportation'],
    }),
    createTransportation: build.mutation<TransportationType, any>({
      query: (body) => ({
        url: '/transportations',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Transportation'],
    }),
    editTransportation: build.mutation<TransportationType, any>({
      query: ({ id, body }) => ({
        url: `/transportations/${id}?_method=PUT`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Transportation'],
    }),
    deleteTransportation: build.mutation<void, number>({
      query: (id) => ({
        url: `/transportations/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Transportation'],
    }),
    storeTransportationImage: build.mutation<void, { transportation_id: number, body: any }>({
      query: ({ transportation_id, body }) => ({
        url: '/store/transportation-image',
        method: 'POST',
        body,
        params: {
          transportation_id,
        },
      }),
      invalidatesTags: ['Transportation'],
    }),
    signTransportation: build.mutation<void, { id: number, body: any }>({
      query: ({ id, body }) => ({
        url: `/transportations/sign-transportation/${id}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Transportation'],
    }),
    transportationGenerateDocument: build.mutation<void, number>({
      query: (id) => ({
        url: `/transportations/generate/${id}`,
        method: 'GET',
        responseHandler: async (response) => {
          const blob = await response.blob()
          const name = getFileName(response)
          downloadFile(blob, name)
        },
      }),
    }),
    sendEmailTransportation: build.mutation<void, {id: number, emails: string[]}>({
      query: ({ id, ...params }) => ({
        url: `/transportations/${id}/customer-send?${qs.stringify(params)}`,
        method: 'GET',
      }),
      invalidatesTags: ['Transportation'],
    }),

    getListTransportationBills: build.query<GetItemsType<TransportationBillType>, { page: number, filters: any }>({
      query: (params) => ({
        url: `/transportation-bills?${formatFilters(params)}`,
      }),
      providesTags: () => ['Transportation'],
    }),
    getOneTransportationBills: build.query<TransportationBillType, number>({
      query: (id) => ({
        url: `/transportation-bills/${id}`,
      }),
      providesTags: () => ['Transportation'],
    }),
    editTransportationBill: build.mutation<void, { id: number, body: any }>({
      query: ({ id, body }) => ({
        url: `/transportation-bills/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Transportation'],
    }),
    archiveTransportationBill: build.mutation<void, { id: number, body: any }>({
      query: ({ id, body }) => ({
        url: `/transportation-bills/${id}/archive`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Transportation'],
    }),
    deleteImage: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: '/image',
        method: 'DELETE',
        body: {
          entity_id: id,
          entity_type: 'App\\Models\\TransportationImage',
        },
      }),
      invalidatesTags: ['Transportation'],
    }),
    transportationBillGenerateDocument: build.mutation<void, number>({
      query: (id) => ({
        url: `/transportations/generate-bill/${id}`,
        method: 'GET',
        responseHandler: async (response) => {
          const blob = await response.blob()
          const name = getFileName(response)
          downloadFile(blob, name)
        },
      }),
    }),
  }),
})
