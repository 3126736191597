import { TrucksType } from '@src/types/trucks'

export enum UserRoles {
  Admin = '1',
  Driver = '2',
  SalesManager = '3',
  ServiceManager = '4',
  Mechanic = '5',
  StoreManager = '6',
}

export type User = {
  id: number
  name: string
  surname: string
  phone?: string
  email: string
  role: UserRoles
  truck?: TrucksType
}

export type UsersShort = {
  id: number
  name: string
  full_name: string
}
