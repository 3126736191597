import { FC, useCallback, useMemo, useTransition } from 'react'
import { useTranslation } from 'react-i18next'

import { PageTable } from '@src/components/Table/PageTable'
import { TableFiltersType } from '@src/types/tableFilters'
import { agreementsApi } from '@src/store/services/agreements-service'
import useQuery from '@src/hooks/useQuery'
import { carsApi } from '@src/store/services/cars-service'
import { companiesApi } from '@src/store/services/companies-service'
import { CompanyType } from '@src/types/company'
import { CloseAgreementOrderForm } from '@src/components/pages/agreements/CloseAgreementOrderForm'
import { useAgreementOrderTableHook } from '@src/components/pages/agreements/AgreemetOrderTable.hook'
import { ColumnProps } from 'antd/es/table'

type AgreementOrderProps = {
  isTableOnly?: boolean;
  companyId?: number;
  companyType?: CompanyType;
  filters?: any;
  withoutParamsFilters?: boolean;
}

export const AgreementOrders: FC<AgreementOrderProps> = ({ isTableOnly, companyId, companyType, filters, withoutParamsFilters }) => {
  const { t } = useTranslation()
  const pageQuery = useQuery('page')
  const filtersQuery = useQuery('filters')
  const [isPending, startTransition] = useTransition()
  const { data: makesList } = carsApi.useGetCarsMakesQuery()
  const { data: modelsList } = carsApi.useGetCarsModelsQuery()
  const { data: customerSelect } = companiesApi.useGetSelectCompaniesQuery(CompanyType.Customer)
  const { data: ownersSelect } = companiesApi.useGetSelectCompaniesQuery(CompanyType.Owner)
  const [exportAgreementOrder, { isLoading: isLoadingExport }] = agreementsApi.useExportAgreementOrderMutation()

  const { columns, paramsFilters, setParamsFilters, isLoading, data, closeAgreementId, setCloseAgreementId } = useAgreementOrderTableHook({
    initParamsFilters: filters || {
      page: pageQuery ? +pageQuery : 1,
      filters: {
        ...(companyId && companyType === CompanyType.Customer
          ? { agreement: { customer_id: { $eq: companyId } } }
          : {}),
        ...(companyId && companyType === CompanyType.Owner
          ? { agreement: { owner_id: { $eq: companyId } } }
          : {}),
        ...(companyId && !companyType ? { agreement: { id: { $eq: companyId } } }
          : {}),
        ...(filtersQuery ? JSON.parse(filtersQuery) : {}),
      },
    },
    withoutParamsFilters,
  })

  const getFilteredColumns = (column: ColumnProps<AgreementOrderProps>[], type?: string) => {
    if (!type) {
      return column
    }

    const filteredColumns = type === CompanyType.Owner
      ? column.filter((col) => col.key !== 'owner')
      : column.filter((col) => col.key !== 'customer')

    return filteredColumns
  }

  const handleFilterChange = useCallback((filter: any) => {
    startTransition(() => {
      setParamsFilters(filter)
    })
  }, [setParamsFilters])

  const filtersList = useMemo<TableFiltersType>(() => [
    [
      {
        type: 'select',
        dataIndex: 'car.make',
        operator: '$eq',
        placeholder: t('agreements.columns.make'),
        width: '200px',
        options: makesList?.map((item) => ({ value: item, label: item })) || [],
        showSearch: true,
      },
      {
        type: 'select',
        dataIndex: 'car.model',
        operator: '$eq',
        placeholder: t('agreements.columns.model'),
        width: '200px',
        options: modelsList?.map((item) => ({ value: item, label: item })) || [],
        showSearch: true,
      },
      {
        type: 'input',
        dataIndex: 'car.serial_number',
        operator: '$contains',
        placeholder: t('agreements.columns.serialNumber'),
        width: '150px',
      },
    ],
    [
      {
        type: 'select',
        dataIndex: 'agreement.customer_id',
        operator: '$eq',
        placeholder: t('agreements.columns.customer'),
        width: '200px',
        options: customerSelect?.map((item) => ({ value: item.id, label: item.name })) || [],
        showSearch: true,
      },
      {
        type: 'select',
        dataIndex: 'agreement.owner_id',
        operator: '$eq',
        placeholder: t('cars.columns.owner'),
        width: '200px',
        options: ownersSelect?.map((owner) => ({ value: owner.id, label: owner.name })) || [],
      },
      {
        type: 'select',
        dataIndex: 'status',
        operator: '$eq',
        width: '120px',
        placeholder: t('agreements.columns.status'),
        options: [
          {
            value: 0,
            label: t('agreements.columns.statusOpen'),
          },
          {
            value: 1,
            label: t('agreements.columns.statusClosed'),
          },
        ],
      },
    ],
    [
      {
        type: 'rangePicker',
        dataIndex: 'date_start',
        placeholder: [t('filters.dateStartFrom'), t('filters.dateStartTo')],
      },
      {
        type: 'rangePicker',
        dataIndex: 'date_end',
        placeholder: [t('filters.dateEndFrom'), t('filters.dateEndTo')],
      },
    ],
  ], [customerSelect, makesList, modelsList, ownersSelect, t])

  return (
    <div>
      <PageTable
        title={t('menu.agreementOrders')!}
        createLink="/agreement-orders/create"
        isTableOnly={isTableOnly}
        filtersList={filtersList}
        setParamsFilters={handleFilterChange}
        columnsHeight="64px"
        paramsFilters={paramsFilters}
        filtersV2
        onExport={!isTableOnly && exportAgreementOrder}
        isLoadingExport={isLoadingExport}
        tableData={{
          ...data,
          isLoading: isLoading || isPending,
          columns: getFilteredColumns(columns, companyType),
        }}
      />
      <CloseAgreementOrderForm agreementOrderId={closeAgreementId} onClose={() => setCloseAgreementId(null)} />
    </div>
  )
}
