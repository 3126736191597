export enum BillsTypeEnum {
  Business = 'business',
  Rent = 'rent',
  Transportation = 'transportation'
}

type SumType = {
  agreementInvoicesRevenue: number
  businessInvoicesRevenue: number
  transportInvoicesRevenue: number
}

export type BillsResponse = {
  data: any[]
//  agreement_bills: AgreementBillType[]
 /// agreement_invoices: AgreementInvoicesType[]
  // transportation_bills: TransportationBillType[]
  // business_invoices: InvoicesType[]
  email: string
  revenue: SumType
}
