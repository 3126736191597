import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ColumnProps } from 'antd/lib/table'
import { ReactComponent as DocumentIcon } from '@src/assets/document_icon.svg'
import { ReactComponent as SendMailIcon } from '@src/assets/send-mail_icon.svg'
import { ReactComponent as CloseIcon } from '@src/assets/close_icon.svg'
import { ReactComponent as DocumentDoneIcon } from '@src/assets/document-done_icon.svg'
import cn from 'classnames'
import { agreementsApi } from '@src/store/services/agreements-service'
import { Agreement } from '@src/types/agreements'
import { isSelectionText } from '@src/lib/utils'
import { setSendEmail } from '@src/store/ducks/app/reducer'
import { SendEmailType } from '@src/types/mailLogs'
import { AppDispatch } from '@src/store/store'
import { addQuery } from '@src/lib/route'
import style from '@src/Pages/Agreements/agreements.module.scss'

export const useAgreementOrderTableHook = ({ initParamsFilters, withoutParamsFilters }: any) => {
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const history = useHistory()
  const [paramsFilters, setParamsFilters] = useState(initParamsFilters)
  const [closeAgreementId, setCloseAgreementId] = useState<null | number>(null)
  const { data, isLoading } = agreementsApi.useGetListAgreementOrderQuery(paramsFilters)
  const [generateDocument] = agreementsApi.useAgreementOrderGenerateDocumentMutation()
  const [generateCloseAgreementOrder, { isLoading: isLoadingGenerateCloseAgreementOrder }] = agreementsApi.useGenerateCloseAgreementOrderMutation()

  useEffect(() => {
    if (!withoutParamsFilters) {
      history.push({
        search: addQuery({ ...paramsFilters, filters: JSON.stringify(paramsFilters.filters) }),
      })
    }
  }, [paramsFilters, history, withoutParamsFilters])

  const onCell = useCallback((record: Agreement) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/agreement-orders/edit/${record.id}`)
      }
    },
  }), [history])

  const columns = useMemo<ColumnProps<any>[]>(() => [
    {
      title: t('agreements.columns.customer'),
      dataIndex: 'agreement',
      key: 'customer',
      render: (agreement) => agreement.customer.full_name,
      onCell,
    },
    {
      title: t('agreements.columns.owner'),
      dataIndex: 'agreement',
      key: 'owner',
      render: (agreement) => agreement.owner.full_name,
      onCell,
    },
    {
      title: t('agreements.columns.makeModel'),
      dataIndex: 'car',
      key: 'makeModel',
      align: 'center',
      render: (car) => `${car.make}/${car.model}`,
      onCell,
    },
    {
      title: t('agreements.columns.serialNumber'),
      dataIndex: 'car',
      key: 'serialNumber',
      align: 'center',
      render: (car) => car.serial_number,
      onCell,
    },
    {
      title: t('agreements.columns.dateStart'),
      dataIndex: 'date_start',
      key: 'date_start',
      align: 'center',
      onCell,
    },
    {
      title: t('agreements.columns.dateEnd'),
      dataIndex: 'date_end',
      key: 'date_end',
      align: 'center',
      onCell,
    },
    {
      title: t('agreements.columns.status'),
      dataIndex: 'status',
      key: 'status',
      width: 120,
      align: 'center',
      render: (status) => (status ? (
        <div className={cn(style.status, style.status__close)}>
          {t('agreements.columns.statusClosed')}
        </div>
      ) : (
        <div className={cn(style.status, style.status__open)}>
          {t('agreements.columns.statusOpen')}
        </div>
      )),
      onCell,
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '104px',
      fixed: 'right',
      render: (_, record) => (
        <div className={cn('table_actions')}>
          <button
            type="button"
            onClick={() => {
              generateDocument(record.id)
            }}
            className={cn('table_actions_item')}
          >
            <DocumentIcon />
          </button>

          {record.status === 1 && (
            <button
              type="button"
              onClick={() => {
                generateCloseAgreementOrder(record.id)
              }}
              className={cn('table_actions_item')}
              disabled={isLoadingGenerateCloseAgreementOrder}
            >
              <DocumentDoneIcon />
            </button>
          )}
          {record.status === 0 && (
            <button
              type="button"
              onClick={() => {
                dispatch(setSendEmail({
                  type: SendEmailType.AgreementOrder,
                  companyId: record.agreement.customer.id,
                  id: record.id,
                }))
              }}
              className={cn('table_actions_item')}
            >
              <SendMailIcon />
            </button>
          )}
          {record.status === 0 && (
            <button
              type="button"
              className={cn('table_actions_item')}
              onClick={() => {
                setCloseAgreementId(record.id)
              }}
            >
              <CloseIcon />
            </button>
          )}
        </div>
      ),
    },
  ], [t, onCell, isLoadingGenerateCloseAgreementOrder, generateDocument, generateCloseAgreementOrder, dispatch])

  return {
    columns,
    paramsFilters,
    setParamsFilters,
    data,
    isLoading,
    closeAgreementId,
    setCloseAgreementId,
  }
}
