import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import { Button as ButtonAntd, Col, Row } from 'antd'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { Button } from '@src/components/ui/Button/Button'
import { Preloader } from '@src/components/Preloader/Preloader'
import { ReactComponent as PlusCircleIcon } from '@src/assets/plus-circle_icon.svg'
import { SelectFormik } from '@src/components/SelectFormik/SelectFormik'
import * as Yup from 'yup'
import style from './CarModels.module.scss'

export const CarModelsCreate = () => {
  const { t } = useTranslation()

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      make: Yup.string().required(t('form.errors.required') ?? ''),
      model: Yup.string().required(t('form.errors.required') ?? ''),
      category: Yup.string().required(t('form.errors.required') ?? ''),
    })
  ), [t])

  const initialValues = useMemo(() => ({
    make: '',
    model: '',
    category: '',
  }), [])

  const onSubmit = useCallback(async () => {

  }, [])

  return (
    <Preloader loading={false}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {() => (
          <Form className={style.form}>
            <Row gutter={[8, 0]}>
              <Col xs={24} md={24}>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <SelectFormik
                    name="make"
                    placeholder={t('carModels.make') ?? ''}
                    options={[]}
                  />
                  <ButtonAntd
                    size="large"
                    onClick={() => {}}
                    className={style.plusButton}
                  >
                    <PlusCircleIcon />
                  </ButtonAntd>
                </div>
              </Col>
              <Col xs={24} md={24}>
                <FieldFormik name="model" placeholder={t('carModels.model') ?? ''} />
              </Col>
              <Col xs={24} md={24}>
                <FieldFormik name="category" placeholder={t('carModels.category') ?? ''} />
              </Col>
            </Row>
            <Button
              size="large"
              htmlType="submit"
              type="primary"
              style={{ marginTop: 'auto', alignSelf: 'flex-start' }}
            >
              {t('carModels.create') ?? ''}
            </Button>
          </Form>
        )}
      </Formik>
    </Preloader>
  )
}
