import { useTranslation } from 'react-i18next'
import React, { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { TableFiltersType } from '@src/types/tableFilters'
import useQuery from '@src/hooks/useQuery'
import { addQuery } from '@src/lib/route'
import { companiesApi } from '@src/store/services/companies-service'
import { PageTable } from '@src/components/Table/PageTable'
import { useTransportationTable } from '@src/components/pages/transportation/TransportationTable.hook'
import { usersApi } from '@src/store/services/users-service'
import { UserRoles } from '@src/types/users'
import { authApi } from '@src/store/services/auth-service'
import { DriverTransportations } from '../Driver/DriverTransportations'

export const Transportation = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { data: companiesList } = companiesApi.useGetSelectCompaniesQuery()
  const pageQuery = useQuery('page')
  const filtersQuery = useQuery('filters')
  const { data: usersSelect } = usersApi.useGetUsersForSelectQuery(UserRoles.Driver)
  const { data: user } = authApi.useGetMeQuery()

  const { paramsFilters, setParamsFilters, columns, data, isLoading, TransportationsComponents } = useTransportationTable({
    initParamsFilters: {
      page: pageQuery ? +pageQuery : 1,
      filters: filtersQuery ? JSON.parse(filtersQuery) : {
        driver_id: user?.role === UserRoles.Driver ? {
          $eq: user!.id,
        } : undefined,
      },
    },
  })

  useEffect(() => {
    history.push({
      search: addQuery({ ...paramsFilters, filters: JSON.stringify(paramsFilters.filters) }),
    })
  }, [paramsFilters, history])

  const filtersList = useMemo<TableFiltersType>(() => [
    [
      {
        type: 'input',
        dataIndex: 'points.cargo.name',
        placeholder: t('transportation.columns.cargo'),
        width: '200px',
        operator: '$contains',
      },
      {
        type: 'select',
        dataIndex: 'from_id',
        placeholder: t('transportation.columns.from'),
        width: '200px',
        operator: '$eq',
        options: companiesList?.map((item) => ({ value: item.id, label: item.name })) || [],
        showSearch: true,
      },
      {
        type: 'select',
        dataIndex: 'to_id',
        placeholder: t('transportation.columns.to'),
        width: '200px',
        operator: '$eq',
        options: companiesList?.map((item) => ({ value: item.id, label: item.name })) || [],
        showSearch: true,
      },
      {
        type: 'input',
        dataIndex: 'points.address_from',
        placeholder: t('transportation.columns.addressFrom'),
        width: '150px',
        operator: '$contains',
      },
      {
        type: 'input',
        dataIndex: 'points.address_to',
        placeholder: t('transportation.columns.addressTo'),
        width: '150px',
        operator: '$contains',
      },
      {
        type: 'select',
        dataIndex: 'driver_id',
        placeholder: t('transportation.columns.driver'),
        width: '200px',
        operator: '$eq',
        options: usersSelect?.map((item) => ({ value: item.id, label: item.name })) || [],
        roles: [UserRoles.Admin],
      },
    ],
    [
      {
        type: 'rangePicker',
        dataIndex: 'date',
        placeholder: [t('filters.dateFrom'), t('filters.dateTo')],
      },
    ],
  ], [t, companiesList, usersSelect])

  return (
    <div>
      {user?.role === UserRoles.Driver ? (
        <DriverTransportations
          transportations={data}
          setParamsFilters={setParamsFilters}
          isLoading={isLoading}
        />
      ) : (
        <>
          <PageTable
            title={t('transportation.title')!}
            createLink="/transportation/create"
            filtersList={filtersList}
            setParamsFilters={setParamsFilters}
            paramsFilters={paramsFilters}
            columnsHeight="48px"
            filtersV2
            tableData={{
              ...data,
              isLoading,
              columns,
            }}
          />
          {TransportationsComponents}
        </>
      )}
    </div>
  )
}
