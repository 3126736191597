export const getFileName = (response: Response) => {
  const contentDisposition = response.headers.get('content-disposition')

  let fileName = 'downloaded_file'

  if (contentDisposition && contentDisposition.includes('filename=')) {
    fileName = contentDisposition
      .split('filename=')[1]
      .split(';')[0]
      .replace(/"/g, '')
  }

  return fileName
}
