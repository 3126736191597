import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { Button as ButtonAntd, Card, Col, Divider, Drawer, Input, Row } from 'antd'
import { Preloader } from '@src/components/Preloader/Preloader'
import { SelectFormik } from '@src/components/SelectFormik/SelectFormik'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { DatePickerFormik } from '@src/components/DatePickerFormik/DatePickerFormik'
import { UploadImages } from '@src/components/UploadImages/UploadImages'
import { companiesApi } from '@src/store/services/companies-service'
import { carsApi } from '@src/store/services/cars-service'
import { transportationApi } from '@src/store/services/transportation-service'
import { authApi } from '@src/store/services/auth-service'
import { trucksApi } from '@src/store/services/trucks-service'
import { ReactComponent as DeleteIcon } from '@src/assets/delete_icon.svg'
import { ReactComponent as PlusCircleIcon } from '@src/assets/plus-circle_icon.svg'
import { ViewContentHeader } from '@src/components/ViewContentHeader/ViewContentHeader'
import { ReactComponent as PlusIcon } from '@src/assets/plus_icon.svg'
import { Images } from '@src/components/ImagesPreview/Image'
import { SwitchFormik } from '@src/components/SwitchFormik/SwitchFormik'
import { AddressFormik } from '@src/components/AddressFormik/AddressFormik'
import { usersApi } from '@src/store/services/users-service'
import { Button } from '@src/components/ui/Button/Button'
import * as Yup from 'yup'
import moment from 'moment'
import { UserRoles } from '@src/types/users'
import { serialize } from 'object-to-formdata'
import style from './transportationCreate.module.scss'
import { CustomerCreate } from '../CustomerCreate/CustomerCreate'

const coordinateStringRegex = /^lat:-?\d+(\.\d+)?,lng:-?\d+(\.\d+)?$/

export const TransportationCreate = () => {
  const formRef = useRef<any>()
  const { t } = useTranslation()
  const params = useParams<{ id?: string }>()
  const history = useHistory()
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)
  const { data: user } = authApi.useGetMeQuery()
  const { data: companiesSelect } = companiesApi.useGetSelectCompaniesQuery()
  const [selectedCarId, setSelectedCarId] = useState<number | undefined>(undefined)
  const [companyTrigger, { data: companyData, isFetching: isCompanyLoading }] = companiesApi.useLazyGetOneCompanyQuery()
  const { data: carsSelect } = carsApi.useGetCarsForSelectQuery({ agreements_id: params.id ? +params.id : undefined })
  const { data: info, isLoading: isLoadingGetOne } = transportationApi.useGetOneTransportationQuery(+params.id!, { skip: !params.id })
  const [createTransportation, { isLoading: isLoadingCreate }] = transportationApi.useCreateTransportationMutation()
  const [editTransportation, { isLoading: isLoadingEdit }] = transportationApi.useEditTransportationMutation()
  const [deleteImage, { isLoading: isLoadingStoreImage }] = transportationApi.useDeleteImageMutation()
  const { data: trucksSelect } = trucksApi.useGetTrucksListForSelectQuery()
  const { data: usersSelect } = usersApi.useGetUsersForSelectQuery(UserRoles.Driver)

  const openDrawer = () => setIsDrawerVisible(true)
  const closeDrawer = () => setIsDrawerVisible(false)

  const calculateDistance = useCallback(async (addressFrom: any, addressTo: any, index: number) => {
    if (window.google && window.google.maps && addressFrom && addressTo) {
      const directionsService = new window.google.maps.DirectionsService()
      const geocoder = new window.google.maps.Geocoder()

      let origin
      if (typeof addressFrom === 'string') {
        if (coordinateStringRegex.test(addressFrom)) {
          const parts = addressFrom.split(',')
          const latPart = parts[0].split(':')[1]
          const lat = parseFloat(latPart)
          const lngPart = parts[1].split(':')[1]
          const lng = parseFloat(lngPart)

          origin = new window.google.maps.LatLng(+lat, +lng)
        } else {
          const res: any = await geocoder.geocode({ address: addressFrom })
          origin = res?.results?.[0]?.geometry?.location
        }
      } else {
        origin = addressFrom.geometry.location
      }

      let destination
      if (typeof addressTo === 'string') {
        if (coordinateStringRegex.test(addressTo)) {
          const parts = addressTo.split(',')
          const latPart = parts[0].split(':')[1]
          const lat = parseFloat(latPart)
          const lngPart = parts[1].split(':')[1]
          const lng = parseFloat(lngPart)

          destination = new window.google.maps.LatLng(+lat, +lng)
        } else {
          const res: any = await geocoder.geocode({ address: addressTo })
          destination = res?.results?.[0]?.geometry?.location
        }
      } else {
        destination = addressTo.geometry.location
      }

      directionsService.route(
        {
          origin,
          destination,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === 'OK') {
            const route = response?.routes[0]
            let totalDistance = 0

            if (route) {
              // eslint-disable-next-line no-restricted-syntax
              for (const leg of route.legs) {
                totalDistance += leg.distance?.value || 0
              }
            }

            const distanceInKilometers = Math.ceil(totalDistance / 1000)
            formRef.current.setFieldValue(`points[${index}].distance`, distanceInKilometers)
          } else {
            console.error(`Directions request failed: ${status}`)
          }
        },
      )
    }
  }, [])

  useEffect(() => {
    if (selectedCarId) {
      companyTrigger(selectedCarId)
    }
  }, [selectedCarId, companyTrigger])

  const successCallback = useCallback(() => {
    history.goBack()
  }, [history])

  const onSubmit = useCallback(async (data: any) => {
    let result: any

    const body = {
      ...data,
      date: data.date ? data.date.format('YYYY-MM-DD') : '',
      fileList: undefined,
      images: data.fileList?.map((item: any) => item.originFileObj),
    }

    const formData = serialize(body, { indices: true })

    if (params.id) {
      result = await editTransportation({
        id: +params.id,
        body: formData,
      })
    } else {
      result = await createTransportation(formData)
    }

    if ('data' in result) {
      successCallback()
    }
  }, [createTransportation, editTransportation, params.id, successCallback])

  const validationSchema = useMemo(() => Yup.object().shape({
    from_id: Yup.string().required(t('form.errors.required') ?? ''),
    to_id: Yup.string().required(t('form.errors.required') ?? ''),
    cargo_issued: Yup.string().required(t('form.errors.required') ?? ''),
    date: Yup.string().required(t('form.errors.required') ?? ''),
    points: Yup.array().of(
      Yup.object().shape({
        address_from: Yup.string().required(t('form.errors.required') ?? ''),
        address_to: Yup.string().required(t('form.errors.required') ?? ''),
        distance: Yup.string().required(t('form.errors.required') ?? ''),
        cargo: Yup.object().when('carryingCargo', {
          is: 1,
          then: () =>
            Yup.object().shape({
              name: Yup.string().required(t('form.errors.required') ?? ''),
              unit: Yup.string().required(t('form.errors.required') ?? ''),
              amount: Yup.number()
                .min(1, ({ min }) => t('form.errors.min', { min }))
                .required(t('form.errors.required') ?? ''),
              weight: Yup.string().required(t('form.errors.required') ?? ''),
            }),
          otherwise: () => Yup.mixed().nullable().notRequired(),
        }),
      }),
    ),
    driver_id: Yup.string().required(t('form.errors.required') ?? ''),
    truck_id: Yup.string().required(t('form.errors.required') ?? ''),
  }), [t])

  const initialValues = useMemo(() => ({
    from_id: info?.from_id ?? '',
    to_id: info?.to_id ?? '',
    address_from: info?.address_from ?? '',
    address_to: info?.address_to ?? '',
    date: info?.date ? moment(info.date) : moment(),
    driver_id: info?.driver_id || user?.role === UserRoles.Driver ? user!.id : '',
    truck_id: info ? info.truck_id : user!.truck?.id || '',
    // eslint-disable-next-line no-nested-ternary
    truck_plate: info ? (info.truck?.truck_plate || '') : user?.role === UserRoles.Driver ? user!.truck?.truck_plate : '',
    // eslint-disable-next-line no-nested-ternary
    trailer_plate: info ? (info.truck?.trailer_plate || '') : user?.role === UserRoles.Driver ? user!.truck?.trailer_plate : '',
    cargo_issued: info?.cargo_issued ?? '',
    comment: info?.comment ?? '',
    fileList: [],
    points: info?.points?.map((point) => ({
      ...point,
      carryingCargo: !!point.cargo,
    })) ?? [{
      id: new Date().getTime(),
      address_from: '',
      address_to: '',
      distance: '',
      carryingCargo: false,
    }],
  }), [info, user])

  const handleDeleteImage = async (id?: number) => {
    if (id === undefined) {
      return
    }
    await deleteImage({ id })
  }

  return (
    <div>
      <ViewContentHeader
        onBackClick={() => successCallback()}
      >
        {params.id ? t('transportationCreate.titleEdit') : t('transportationCreate.title')}
      </ViewContentHeader>
      <Preloader loading={isLoadingGetOne}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          innerRef={formRef}
          enableReinitialize
        >
          {({ values, setFieldValue }) => (
            <Form className="not-card">
              <h2 className={style.title}>
                {t('transportationCreate.routeInfo')}
              </h2>
              {values.points?.map((point, index) => (
                <Card className={style.card_margin} key={point.id + point.address_from + point.address_to}>
                  <Row gutter={[8, 0]}>
                    <>
                      <Col xs={24} xl={9}>
                        <AddressFormik
                          onChange={(place) => {
                            const addressTo = values.points[index].address_to
                            if (addressTo) {
                              calculateDistance(place, addressTo, index)
                            }
                          }}
                          name={`points[${index}].address_from`}
                          placeholder={t('transportationCreate.addressFrom') ?? ''}
                        />
                      </Col>
                      <Col xs={24} xl={9}>
                        <AddressFormik
                          onChange={(place) => {
                            const addressFrom = values.points[index].address_from
                            if (addressFrom) {
                              calculateDistance(addressFrom, place, index)
                            }
                          }}
                          name={`points[${index}].address_to`}
                          placeholder={t('transportationCreate.addressTo') ?? ''}
                        />
                      </Col>
                      <Col xl={values.points.length > 1 ? 4 : 6} xs={24}>
                        <FieldFormik
                          name={`points[${index}].distance`}
                          type="number"
                          placeholder={t('transportationCreate.distance') ?? ''}
                        />
                      </Col>
                      {values.points.length > 1 && (
                        <Col xl={2} xs={0}>
                          <ButtonAntd
                            size="large"
                            style={{
                              marginTop: '20px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                            onClick={() => {
                              setFieldValue('points', values.points.filter((equipment: any, filterI: number) => filterI !== index))
                            }}
                          >
                            <DeleteIcon color="#E94444" />
                          </ButtonAntd>
                        </Col>
                      )}
                    </>
                    <Col span={24}>
                      <Card className={style.card_carringMarg}>
                        <Row gutter={[8, 0]}>
                          <Col span={24} style={{ marginBottom: '24px' }}>
                            <SwitchFormik
                              name={`points[${index}].carryingCargo`}
                              placeholder={t('transportationCreate.carryingCargo') ?? ''}
                              onChange={() => {
                                if (!point.carryingCargo) {
                                  setFieldValue(`points[${index}].cargo`, {
                                    name: '',
                                    unit: 1,
                                    amount: 1,
                                    weight: '',
                                  })
                                } else {
                                  setFieldValue(`points[${index}].cargo`, null)
                                }
                              }}
                            />
                          </Col>
                          {values.points[index].carryingCargo ? (
                            <>
                              <Col xs={24} xl={12}>
                                <SelectFormik
                                  showSearch
                                  placeholder={t('transportationCreate.customCargoName') ?? ''}
                                  name={`points[${index}].cargo.name`}
                                  options={carsSelect?.map((car) => ({ value: car.name, label: car.name })) || []}
                                  onChange={(value) => {
                                    const selectedCar = carsSelect?.find((car) => car.name === value)
                                    if (selectedCar) {
                                      setSelectedCarId(selectedCar.id)
                                    }
                                  }}
                                  suffixTag={companyData && companyData.full_name}
                                  isLoading={isCompanyLoading}
                                  dropdownRender={(menu) => (
                                    <>
                                      {menu}
                                      <Divider style={{ margin: '8px 0' }} />
                                      <Input
                                        style={{ height: '38px' }}
                                        placeholder={t('transportationCreate.customCargoName') ?? ''}
                                        onChange={(e) => setFieldValue(`points[${index}].cargo.name`, e.target.value)}
                                        onKeyDown={(e) => e.stopPropagation()}
                                      />
                                    </>
                                  )}
                                />
                              </Col>
                              <Col xs={24} xl={4}>
                                <SelectFormik
                                  name={`points[${index}].cargo.unit`}
                                  showSearch
                                  placeholder={t('transportationCreate.customCargoUnit') ?? ''}
                                  options={[{ value: 1, label: 'VNT' }]}
                                />
                              </Col>
                              <Col xs={24} xl={4}>
                                <FieldFormik
                                  name={`points[${index}].cargo.amount`}
                                  type="number"
                                  placeholder={t('transportationCreate.customCargoAmount') ?? ''}
                                />
                              </Col>
                              <Col xs={24} xl={4}>
                                <FieldFormik
                                  name={`points[${index}].cargo.weight`}
                                  placeholder={t('transportationCreate.customCargoWeight') ?? ''}
                                />
                              </Col>
                            </>
                          ) : null}
                        </Row>
                      </Card>
                    </Col>
                    <Col span={24}>
                      <Row gutter={[0, 24]}>
                        {values.points.length > 1 && (
                          <Col xl={0} xs={24}>
                            <ButtonAntd
                              size="large"
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '100%',
                                height: '38px',
                              }}
                              onClick={() => {
                                setFieldValue('points', values.points.filter((equipment: any, filterI: number) => filterI !== index))
                              }}
                            >
                              <DeleteIcon color="#E94444" />
                            </ButtonAntd>
                          </Col>
                        )}
                        {index === values.points.length - 1 && (
                          <Col span={24}>
                            <Button
                              leftIcon={<PlusIcon />}
                              type="secondary"
                              size="large"
                              className={style.styleButton}
                              onClick={() => {
                                const arr = [...values.points]
                                const lastPoint = arr[arr.length - 1]
                                setFieldValue('points', [
                                  ...arr,
                                  {
                                    address_to: '',
                                    address_from: lastPoint?.address_to || '',
                                    distance: '',
                                    id: new Date().getTime(),
                                    date: '2024-07-01',
                                  },
                                ])
                              }}
                            >
                              {t('transportationCreate.addRoute')}
                            </Button>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </Card>
              ))}
              <Card className={style.card}>
                <Row gutter={[8, 0]}>
                  <Col span={24}>
                    <div className={style.distanceWrapper}>
                      <p className={style.distance}>
                        {t('transportationCreate.distanceTitle')}
                      </p>
                      <p className={style.distanceText}>
                        {`${
                          values.points.reduce((total, point) => {
                            const distance = parseFloat(point.distance as string) || 0
                            return total + distance
                          }, 0)
                        } km`}
                      </p>
                    </div>
                  </Col>
                  <Col xs={24} xl={12}>
                    <DatePickerFormik name="date" placeholder={t('transportationCreate.date') ?? ''} />
                  </Col>
                  <Col xs={24} xl={12}>
                    <FieldFormik
                      type="input"
                      name="comment"
                      autoSize={{ minRows: 4, maxRows: 4 }}
                      placeholder={t('transportationBills.columns.comment') ?? ''}
                    />
                  </Col>
                </Row>
              </Card>
              <Row gutter={[24, 0]}>
                <Col xs={24} xl={12}>
                  <h2 className={style.title}>
                    {t('transportationCreate.fromInfoBlock')}
                  </h2>
                  <Card className={style.card}>
                    {user?.role === UserRoles.Driver ? (
                      <div style={{ display: 'flex', gap: '10px' }}>
                        <SelectFormik
                          name="from_id"
                          showSearch
                          placeholder={t('transportationCreate.from') ?? ''}
                          options={companiesSelect?.map((item) => ({ label: item.name, value: item.id })) || []}
                        />
                        <ButtonAntd
                          size="large"
                          style={{
                            marginTop: '20px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          onClick={openDrawer}
                        >
                          <PlusCircleIcon />
                        </ButtonAntd>
                      </div>
                    ) : (
                      <div style={{ display: 'flex' }}>
                        <SelectFormik
                          name="from_id"
                          showSearch
                          placeholder={t('transportationCreate.from') ?? ''}
                          options={companiesSelect?.map((item) => ({ label: item.name, value: item.id })) || []}
                        />
                      </div>
                    )}
                  </Card>
                </Col>
                <Col xs={24} xl={12}>
                  <h2 className={style.title}>
                    {t('transportationCreate.toInfoBlock')}
                  </h2>
                  <Card className={style.card}>
                    {user?.role === UserRoles.Driver ? (
                      <div style={{ display: 'flex', gap: '12px' }}>
                        <SelectFormik
                          name="to_id"
                          showSearch
                          placeholder={t('transportationCreate.to') ?? ''}
                          options={companiesSelect?.map((item) => ({ label: item.name, value: item.id })) || []}
                        />
                        <ButtonAntd
                          size="large"
                          style={{
                            marginTop: '20px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          onClick={openDrawer}
                        >
                          <PlusCircleIcon />
                        </ButtonAntd>
                      </div>
                    ) : (
                      <div style={{ display: 'flex' }}>
                        <SelectFormik
                          name="to_id"
                          showSearch
                          placeholder={t('transportationCreate.to') ?? ''}
                          options={companiesSelect?.map((item) => ({ label: item.name, value: item.id })) || []}
                        />
                      </div>
                    )}
                  </Card>
                </Col>
              </Row>
              <h2 className={style.title}>
                {t('transportationCreate.transportationInfo')}
              </h2>
              <Card className={style.card}>
                <Row gutter={[8, 0]}>
                  <Col xs={24} md={12}>
                    <FieldFormik
                      name="cargo_issued"
                      placeholder={t('transportationCreate.cargoIssued') ?? ''}
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <SelectFormik
                      name="driver_id"
                      placeholder={t('transportationCreate.driver') ?? ''}
                      options={usersSelect?.map((item) => ({ value: item.id, label: item.name })) || []}
                      disabled={user?.role === UserRoles.Driver}
                    />
                  </Col>
                  <Col xs={24} xl={8}>
                    <SelectFormik
                      name="truck_id"
                      options={trucksSelect?.map((item) => ({
                        ...item,
                        value: item.id,
                        label: item.name,
                      })) || []}
                      onChange={(_, current) => {
                        setFieldValue('truck_plate', current.truck_plate)
                        setFieldValue('trailer_plate', current.trailer_plate)
                        if (user?.role !== UserRoles.Driver) {
                          setFieldValue('driver_id', current.user_id)
                        }
                      }}
                      placeholder={t('transportationCreate.truck') ?? ''}
                    />
                  </Col>
                  <Col xs={24} xl={8}>
                    <FieldFormik
                      name="truck_plate"
                      disabled
                      placeholder={t('transportationCreate.truckPlate') ?? ''}
                    />
                  </Col>
                  <Col xs={24} xl={8}>
                    <FieldFormik
                      name="trailer_plate"
                      disabled
                      placeholder={t('transportationCreate.trailerPlate') ?? ''}
                    />
                  </Col>
                </Row>
              </Card>
              <h2 className={style.title}>
                {t('transportationCreate.imagesTitle')}
              </h2>
              <Card className={style.card_margin}>
                <Row gutter={[0, 0]}>
                  <Col span={24}>
                    {info?.images && (
                      <div>
                        <Images
                          imageList={info.images}
                          onImageDelete={handleDeleteImage}
                        />
                      </div>
                    )}
                  </Col>
                  <Col span={24}>
                    <div>
                      <UploadImages
                        fileList={values.fileList}
                        setFileList={(data) => {
                          setFieldValue('fileList', data)
                        }}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <Button
                      className={style.styleButton}
                      htmlType="submit"
                      size="large"
                      type="primary"
                      loading={isLoadingCreate || isLoadingEdit || isLoadingStoreImage}
                    >
                      {params.id ? t('transportationCreate.save') : t('transportationCreate.submit')}
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Form>
          )}
        </Formik>
      </Preloader>
      <Drawer
        title={t('customerCreate.title')!}
        open={isDrawerVisible}
        onClose={closeDrawer}
        width={554}
      >
        <CustomerCreate onClose={closeDrawer} isModal />
      </Drawer>
    </div>
  )
}
