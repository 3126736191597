import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { invoicesApi } from '@src/store/services/invoices-service'
import useQuery from '@src/hooks/useQuery'
import { addQuery } from '@src/lib/route'
import { InvoicesStatus, InvoicesType, InvoicesTypeEnum } from '@src/types/invoices'
import { ColumnProps } from 'antd/lib/table'
import { Button, Popover, Tag } from 'antd'
import { TableFiltersType } from '@src/types/tableFilters'
import { AgreementInvoicesStatus } from '@src/types/agreementInvoices'
import cn from 'classnames'
import { setSendEmail } from '@src/store/ducks/app/reducer'
import { SendEmailType } from '@src/types/mailLogs'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@src/store/store'
import { PageTable } from '@src/components/Table/PageTable'
import { companiesApi } from '@src/store/services/companies-service'
import { isSelectionText } from '@src/lib/utils'
import { ReactComponent as DocumentIcon } from '@src/assets/document_icon.svg'
import { ReactComponent as SendMailIcon } from '@src/assets/send-mail_icon.svg'
import { ReactComponent as DeleteIcon } from '@src/assets/delete_icon.svg'
import { Confirm } from '@src/components/Confirm/Confirm'
import style from './invoices.module.scss'

type InvoicesActionsProps = {
  record: InvoicesType
}

export const InvoicesActions: FC<InvoicesActionsProps> = ({ record }) => {
  const dispatch = useDispatch<AppDispatch>()
  const { t } = useTranslation()
  const [remove] = invoicesApi.useRemoveMutation()
  const [generateDocument] = invoicesApi.useGenerateDocumentMutation()

  const handleDelete = () => {
    remove(record.id)
  }

  return (
    <div className={cn('table_actions')}>
      <button
        type="button"
        className={cn('table_actions_item')}
        onClick={() => generateDocument(record.id)}
      >
        <DocumentIcon />
      </button>
      {record.status === InvoicesStatus.processed && (
        <button
          type="button"
          onClick={() => {
            dispatch(setSendEmail({
              type: SendEmailType.Invoices,
              companyId: record.company.id,
              id: record.id,
              documentName: `${t(`invoicesCreate.invoiceType.${record.type}`)} ${record.company.full_name}`,
            }))
          }}
          className={cn('table_actions_item')}
        >
          <SendMailIcon />
        </button>
      )}
      <Confirm
        title={t('deleteModal.deleteConfirmationTitle')}
        content={t('deleteModal.deleteConfirmation', { localizedType: t('deleteModal.invoice') })}
        okText={t('deleteModal.delete')}
        cancelText={t('deleteModal.cancel')}
        onOk={handleDelete}
        className={cn('table_actions_item', 'table_actions_item__delete')}
      >
        <DeleteIcon />
      </Confirm>
    </div>
  )
}

const statusColor: any = {
  1: 'blue',
  2: 'green',
  3: 'red',
}

export const Invoices = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch<AppDispatch>()
  const pageQuery = useQuery('page')
  const filtersQuery = useQuery('filters')
  const [paramsFilters, setParamsFilters] = useState({
    page: pageQuery ? +pageQuery : 1,
    filters: filtersQuery ? JSON.parse(filtersQuery) : {},
  })
  const { data: companiesSelect } = companiesApi.useGetSelectCompaniesQuery()
  const { data, isLoading } = invoicesApi.useGetListQuery(paramsFilters)
  const [changeStatus] = invoicesApi.useChangeStatusMutation()
  const [isOpenEditStatus, setIsOpenEditStatus] = useState<null | number>(null)

  useEffect(() => {
    history.push({
      search: addQuery({ ...paramsFilters, filters: JSON.stringify(paramsFilters.filters) }),
    })
  }, [paramsFilters, history])

  const onCell = useCallback((record: InvoicesType) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/invoices/edit/${record.id}`, { backUrl: location.pathname + location.search })
      }
    },
  }), [history, location])

  const onCellMailHandler = useCallback((record: InvoicesType, isAccountant?: boolean) => ({
    onClick: () => {
      if (!isSelectionText()) {
        dispatch(setSendEmail({
          type: SendEmailType.Invoices,
          companyId: record.company.id,
          id: record.id,
          isAccountant,
          documentName: `${t(`invoicesCreate.invoiceType.${record.type}`)} ${record.company.full_name}`,
        }))
      }
    },
  }), [dispatch, t])

  const updateStatus = useCallback((id: number, status: InvoicesStatus) => {
    changeStatus({ id, status })
    setIsOpenEditStatus(null)
  }, [changeStatus])

  const columns = useMemo<ColumnProps<InvoicesType>[]>(() => [
    {
      title: t('invoicesCreate.type'),
      dataIndex: 'type',
      key: 'type',
      render: (type) => t(`invoicesCreate.invoiceType.${type}`),
      onCell,
    },
    {
      title: t('invoices.columns.company'),
      dataIndex: 'company',
      key: 'company',
      render: (company) => company.full_name,
      onCell,
    },
    {
      title: t('invoices.columns.billNumber'),
      dataIndex: 'bill_number',
      key: 'bill_number',
      onCell,
    },
    {
      title: t('invoices.columns.sum'),
      dataIndex: 'sum',
      key: 'sum',
      render: (sum) => `${sum}€`,
      onCell,
    },
    {
      title: t('invoices.columns.dateCreated'),
      dataIndex: 'date',
      key: 'date',
      onCell,
    },
    {
      title: t('invoices.columns.payBy'),
      dataIndex: 'pay_by',
      key: 'payBy',
      onCell,
    },
    {
      title: t('invoices.columns.status'),
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => (
        <Popover
          content={(
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div>
                <Button
                  type="text"
                  onClick={() => updateStatus(record.id, InvoicesStatus.notProcessed)}
                >
                  <div>
                    {t(`invoices.status.${InvoicesStatus.notProcessed}`)}
                  </div>
                </Button>
              </div>
              <div>
                <Button
                  type="text"
                  onClick={() => updateStatus(record.id, InvoicesStatus.processed)}
                >
                  <div>
                    {t(`invoices.status.${InvoicesStatus.processed}`)}
                  </div>
                </Button>
              </div>
              <div>
                <Button
                  type="text"
                  onClick={() => updateStatus(record.id, InvoicesStatus.archived)}
                >
                  <div>
                    {t(`invoices.status.${InvoicesStatus.archived}`)}
                  </div>
                </Button>
              </div>
            </div>
          )}
          trigger="click"
          placement="bottom"
          open={isOpenEditStatus === record.id}
          onOpenChange={() => setIsOpenEditStatus(record.id)}
        >
          <Tag className={style.status} color={statusColor[status]}>{t(`invoices.status.${status}`)}</Tag>
        </Popover>
      ),
    },
    {
      title: t('invoices.columns.sentAccountant'),
      dataIndex: 'is_sent_accountant',
      key: 'is_sent_accountant',
      render: (isSentAccountant) => (
        <Tag className={style.status} color={isSentAccountant ? 'green' : 'red'}>
          {t(`sendStatus.${isSentAccountant}`)}
        </Tag>
      ),
      onCell: (record) => onCellMailHandler(record, true),
    },
    {
      title: t('invoices.columns.sentCustomer'),
      dataIndex: 'is_sent_customer',
      key: 'is_sent_customer',
      className: style.sentCustomer,
      render: (isSentAccountant) => (
        <Tag className={style.status} color={isSentAccountant ? 'green' : 'red'}>
          {t(`sendStatus.${isSentAccountant}`)}
        </Tag>
      ),
      onCell: (record) => onCellMailHandler(record),
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: 104,
      fixed: 'right',
      render: (_, record) => (
        <InvoicesActions record={record} />
      ),
    },
  ], [isOpenEditStatus, onCell, onCellMailHandler, t, updateStatus])

  const filtersList = useMemo<TableFiltersType>(() => [
    [
      {
        type: 'select',
        dataIndex: 'type',
        operator: '$eq',
        width: '100px',
        dropdownWidth: '280px',
        placeholder: t('invoicesCreate.type'),
        options: Object.values(InvoicesTypeEnum).map((type) => ({
          label: t(`invoicesCreate.invoiceType.${type}`),
          value: type,
        })),
      },
      {
        type: 'select',
        dataIndex: 'company_id',
        operator: '$eq',
        width: '150px',
        dropdownWidth: '250px',
        placeholder: t('invoices.columns.company'),
        options: companiesSelect?.map((item) => ({ value: item.id, label: item.name })) || [],
        showSearch: true,
      },
      {
        type: 'input',
        dataIndex: 'bill_number',
        operator: '$contains',
        placeholder: t('invoices.columns.billNumber'),
        width: '127px',
      },
    ],
    [
      {
        type: 'select',
        dataIndex: 'status',
        width: '140px',
        operator: '$eq',
        placeholder: t('invoices.columns.status'),
        options: [
          {
            value: AgreementInvoicesStatus.notProcessed,
            label: t(`invoices.status.${InvoicesStatus.notProcessed}`),
          },
          {
            value: AgreementInvoicesStatus.processed,
            label: t(`invoices.status.${InvoicesStatus.processed}`),
          },
          {
            value: AgreementInvoicesStatus.archived,
            label: t(`invoices.status.${InvoicesStatus.archived}`),
          },
        ],
      },
      {
        type: 'select',
        dataIndex: 'is_sent_accountant',
        operator: '$eq',
        width: '145px',
        placeholder: t('invoices.columns.sentAccountant'),
        options: [
          {
            value: '0',
            label: t('sendStatus.0'),
          },
          {
            value: '1',
            label: t('sendStatus.1'),
          },
        ],
      },
      {
        type: 'select',
        dataIndex: 'is_sent_customer',
        operator: '$eq',
        width: '130px',
        placeholder: t('invoices.columns.sentCustomer'),
        options: [
          {
            value: '0',
            label: t('sendStatus.0'),
          },
          {
            value: '1',
            label: t('sendStatus.1'),
          },
        ],
      },
    ],
    [
      {
        type: 'rangePicker',
        dataIndex: 'date_closed',
        width: '215px',
        placeholder: [`${t('invoices.dateFrom')}`, `${t('invoices.dateTo')}`],
      },
      {
        type: 'rangePicker',
        dataIndex: 'pay_by',
        width: '240px',
        placeholder: [`${t('invoices.payFrom')}`, `${t('invoices.payTo')}`],
      },
    ],
  ], [t, companiesSelect])

  return (
    <div>
      <PageTable
        title={t('invoices.title')}
        createLink="/invoices/create"
        setParamsFilters={setParamsFilters}
        filtersV2
        filtersList={filtersList}
        paramsFilters={paramsFilters}
        tableData={{
          ...data,
          columns,
          isLoading,
          components: {
            body: {
              row: (props: any) => (
                <tr
                  {...props}
                  style={{
                    height: '64px',
                    background: data?.data.find((item) => item.id === props['data-row-key'])?.company.color,
                  }}
                />
              ),
            },
          },
        }}
      />
    </div>
  )
}
