import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, Col, Drawer, Row } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { carsApi } from '@src/store/services/cars-service'
import { CarViewContent } from '@src/components/pages/cars/CarViewСontent'
import { ViewContentHeader } from '@src/components/ViewContentHeader/ViewContentHeader'
import { DetailsCard } from '@src/components/DetailsCard/DetailsCard'
import { CarStatus } from '@src/types/car'
import { Line } from '@ant-design/charts'
import moment from 'moment'
import cn from 'classnames'
import { CarsCreate } from '../CarsCreate/CarsCreate'
import style from './carsView.module.scss'

export const CarsView = () => {
  const { t } = useTranslation()
  const params = useParams<{ id: string }>()
  const history = useHistory()
  const [activeTabKey, setActiveTabKey] = useState<string>('agreementOrders')
  const { data, isLoading } = carsApi.useGetOneCarQuery(+params.id)
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false)

  const openDrawer = () => setIsDrawerVisible(true)
  const closeDrawer = () => setIsDrawerVisible(false)

  const detailsData = useMemo(() => [
    { label: t('cars.columns.category'), value: data?.category?.name },
    { label: t('cars.columns.year'), value: data?.year },
    { label: t('cars.columns.serialNumber'), value: data?.serial_number },
    { label: t('cars.columns.owner'), value: data?.owner?.full_name },
    { label: t('cars.columns.engineHours'), value: data?.engine_hours === '0' ? '' : data?.engine_hours },
    { label: t('cars.columns.status'),
      value: (
        <div>
          {data?.status === CarStatus.Available && (
            <div className={cn(style.status, style.status__free)}>
              {t('cars.columns.statusFree')}
            </div>
          )}
          {data?.status === CarStatus.InRent && (
            <div className={cn(style.status, style.status__rent)}>
              {t('cars.columns.statusRent')}
            </div>
          )}
          {data?.status === CarStatus.Service && (
            <div className={cn(style.status, style.status__repair)}>
              {t('cars.columns.statusRepair')}
            </div>
          )}
          {data?.status === CarStatus.Sold && (
            <div className={cn(style.status, style.status__repair)}>
              {t('cars.columns.statusSold')}
            </div>
          )}
        </div>
      ),
    },
    { label: t('cars.columns.createdAt'), value: moment(data?.created_at).format('YYYY-MM-DD') },
  ], [data, t])

  return (
    <div>
      {data && (
        <ViewContentHeader
          onBackClick={() => history.push('/cars')}
        >
          {`${data?.make}/${data?.model}`}
        </ViewContentHeader>
      )}
      <Row gutter={[24, 24]}>
        <Col xs={24} xl={12}>
          <DetailsCard
            data={detailsData}
            isLoading={isLoading}
            buttonLabel={t('customerCreate.edit')}
            buttonAction={openDrawer}
            showButton
          >
            <div className={style.lineWrapper}>
              <h2 className={style.cardTitle}>
                {t('carCreate.lineTitle')}
              </h2>
              <Line
                data={[
                  { month: '2023-03', value: 1 },
                  { month: '2023-04', value: 1 },
                  { month: '2023-05', value: 1 },
                  { month: '2023-06', value: 2 },
                  { month: '2023-07', value: 3.67 },
                  { month: '2023-08', value: 4 },
                  { month: '2023-09', value: 5.67 },
                  { month: '2023-10', value: 5 },
                  { month: '2023-11', value: 6.67 },
                  { month: '2023-12', value: 1 },
                  { month: '2024-01', value: 1.67 },
                  { month: '2024-02', value: 1 },
                ]}
                xField="month"
                yField="value"
                height={260}
                xAxis={{
                  label: {
                    style: {
                      fontSize: 12,
                    },
                    rotate: 1,
                    offset: 26,
                  },
                }}
                lineStyle={{
                  stroke: '#5B8FF9',
                }}
                tooltip={{
                  showMarkers: true,
                }}
                style={{ width: 'calc(100% - 24px)', marginLeft: '12px' }}
              />
            </div>
          </DetailsCard>
        </Col>
        <Col xs={24} xl={12}>
          <Card
            className={style.carCardStyle}
            loading={isLoading}
            activeTabKey={activeTabKey}
            onTabChange={setActiveTabKey}
            tabList={[
              {
                key: 'agreementOrders',
                label: t('menu.orders'),
              },
              {
                key: 'agreementInvoices',
                label: t('menu.invoices'),
              },
              {
                key: 'gallery',
                label: t('menu.gallery'),
              },
              {
                key: 'engineHours',
                label: t('menu.engineHours'),
              },
            ]}
            tabProps={{
              size: 'middle',
            }}
          >
            {data && (
              <CarViewContent
                activeTabKey={activeTabKey}
                serialNumber={data.serial_number}
                images={data?.images}
                id={data.id}
              />
            )}
          </Card>
        </Col>
      </Row>
      <Drawer
        title={t('carCreate.editCar')}
        open={isDrawerVisible}
        onClose={closeDrawer}
        width={554}
      >
        <CarsCreate onClose={closeDrawer} />
      </Drawer>
    </div>
  )
}
