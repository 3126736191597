import { Fragment, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { Button, Card, Col, Drawer, Row, Table } from 'antd'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import moment from 'moment/moment'
import { ColumnProps } from 'antd/lib/table'
import { Preloader } from '@src/components/Preloader/Preloader'
import { AppDispatch } from '@src/store/store'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { DatePickerFormik } from '@src/components/DatePickerFormik/DatePickerFormik'
import { SelectFormik } from '@src/components/SelectFormik/SelectFormik'
import { companiesApi } from '@src/store/services/companies-service'
import { transportationApi } from '@src/store/services/transportation-service'
import { setSendEmail } from '@src/store/ducks/app/reducer'
import { SendEmailType } from '@src/types/mailLogs'
import { TransportationBillStatus, TransportationBillTypeEnum } from '@src/types/transportationBills'
import { isString } from '@src/lib/utils'
import { ReactComponent as PlusIcon } from '@src/assets/plus_icon.svg'
import { ReactComponent as PlusCircleIcon } from '@src/assets/plus-circle_icon.svg'
import { ReactComponent as DeleteIcon } from '@src/assets/delete_icon.svg'
import { ViewContentHeader } from '@src/components/ViewContentHeader/ViewContentHeader'
import { SwitchFormik } from '@src/components/SwitchFormik/SwitchFormik'
import { TransportationShortType } from '@src/types/transportation'
import { Images } from '@src/components/ImagesPreview/Image'
import { TransportationDocument } from '@src/components/pages/transportation/TransportationDocument'
import { InvoiceDefaultDocument } from '@src/components/documents/InvoiceDefaultDocument'
import { NoData } from '@src/components/NoData/NoData'
import style from './transportationBillsEdit.module.scss'
import styleTransportation from '../TransportationView/transportationView.module.scss'
import { CustomerCreate } from '../CustomerCreate/CustomerCreate'

export const TransportationBillsEdit = () => {
  const { t } = useTranslation()
  const formRef = useRef<any>()
  const params = useParams<{ id: string }>()
  const dispatch = useDispatch<AppDispatch>()
  const history = useHistory()
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)
  const [activeTabKey, setActiveTabKey] = useState('invoice')
  const [deleteImage] = transportationApi.useDeleteImageMutation()

  const openDrawer = () => setIsDrawerVisible(true)
  const closeDrawer = () => setIsDrawerVisible(false)

  const { data: companiesSelect } = companiesApi.useGetSelectCompaniesQuery()
  const { data: info, isLoading: isLoadingGetOne } = transportationApi.useGetOneTransportationBillsQuery(+params.id)

  const [editTransportationBill, { isLoading: isLoadingEdit }] = transportationApi.useEditTransportationBillMutation()
  const [archiveTransportationBill, { isLoading: isLoadingArchive }] = transportationApi.useArchiveTransportationBillMutation()

  const validationSchema = useMemo(() => Yup.object().shape({
    type: Yup.string().required(t('form.errors.required') ?? ''),
    payer_id: Yup.number().required(t('form.errors.required')),
    bill_number: Yup.string().required(t('form.errors.required')),
    pay_by: Yup.string().required(t('form.errors.required')),
    date: Yup.string().required(t('form.errors.required')),
    points: Yup.array().of(Yup.object().shape({
      distance_rate: Yup.number().min(1, ({ min }) => t('form.errors.min', { min })).required(t('form.errors.required')),
    })),
    items: Yup.array().min(1).of(Yup.object().shape({
      name: Yup.string().required(t('form.errors.required') ?? ''),
      unit: Yup.string().required(t('form.errors.required') ?? ''),
      amount: Yup.number().required(t('form.errors.required') ?? ''),
      value: Yup.number().required(t('form.errors.required') ?? ''),
    })),
  }), [t])

  const columns = useMemo(() => (setFieldValue: Function, values: any): ColumnProps<TransportationShortType>[] => (
    [
      {
        title: t('transportation.columns.cargo'),
        dataIndex: 'cargo',
        key: 'cargo',
        render: (cargo, record) => {
          if (record.id === 'total') {
            return <span className={style.span}>{cargo?.name}</span>
          }
          return cargo?.name
        },
      },
      {
        title: t('transportation.columns.addressFrom'),
        dataIndex: 'address_from',
        key: 'address_from',
      },
      {
        title: t('transportation.columns.addressTo'),
        dataIndex: 'address_to',
        key: 'address_to',
      },
      {
        title: t('transportation.columns.distanceView'),
        dataIndex: 'distance',
        key: 'distance',
        render: (_, record) => `${record.distance} km`,
      },
      {
        title: t('transportation.columns.price/kilometer'),
        dataIndex: 'distance_rate',
        width: 150,
        key: 'price',
        align: 'center',
        render: (_, record, index) => {
          if (record.id === 'total') {
            return null
          }
          return (
            <FieldFormik
              name={`points[${index}].distance_rate`}
              type="number"
              onChange={(e) => {
                setFieldValue('items[0].value', values.points.reduce((acc: number, val: any) => (acc + (record.id === val.id ? +e.target.value : +val.distance_rate) * val.distance), 0))
              }}
            />
          )
        },
      },
      {
        title: t('transportation.columns.totalPrice'),
        dataIndex: 'total',
        align: 'center',
        key: 'total',
        render: (value, record, index) => (
          <span className={style.span}>
            {
              value
                ? (`${value.toFixed(2)} EUR`)
                : (`${((values?.points[index]?.distance_rate || 0) * record.distance).toFixed(2)} EUR`)
            }
          </span>
        ),
      },
    ]
  ), [t])

  const initialValues = useMemo(() => ({
    type: info?.type || TransportationBillTypeEnum.Tax,
    status: TransportationBillStatus.processed,
    id: info?.id ?? '',
    items: info?.items ? info?.items : ([{ name: 'Transporto paslaugos', unit: 'VNT', amount: 1, value: '', id: new Date().getTime() }] as any),
    payer_id: info?.payer_id ?? '',
    bill_number: info?.bill_number ?? '',
    pay_by: info?.pay_by ? moment(info.pay_by) : '',
    date: info?.date ? moment(info.date) : '',
    company_id: info?.company_id || '',
    vat_calculated: info?.vat_calculated ?? 1,
    points: (info?.transportation?.points || []).map((point) => ({
      id: point.id,
      distance: point.distance,
      distance_rate: point.distance_rate || 0,
    })),
    comment: info?.comment || '',
  }), [info])

  const onSubmit = async (data: any) => {
    let result

    if (data.status === TransportationBillStatus.processed) {
      result = await editTransportationBill({
        id: +params.id,
        body: {
          ...data,
          pay_by: data.pay_by ? data.pay_by.format('YYYY-MM-DD') : '',
          date: data.date ? data.date.format('YYYY-MM-DD') : '',
          sum: data.items.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.value * currentValue.amount, 0),
        },
      })
    } else {
      result = await archiveTransportationBill({
        id: +params.id,
        body: {
          ...data,
          pay_by: data.pay_by ? data.pay_by.format('YYYY-MM-DD') : '',
          date: data.date ? data.date.format('YYYY-MM-DD') : '',
          sum: data.items.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.value * currentValue.amount, 0),
        },
      })
    }

    if ('data' in result) {
      if (data.company_id && data.bill_number && data.company_id) {
        dispatch(setSendEmail({
          type: SendEmailType.TransportationBills,
          companyId: data.company_id,
          id: +params.id,
        }))
      }
      history.goBack()
    }
  }

  const handleDeleteImage = async (id?: number) => {
    if (id === undefined) {
      return
    }
    await deleteImage({ id })
  }

  return (
    <div>
      <ViewContentHeader>
        {t('transportationBillsEdit.title')}
      </ViewContentHeader>
      <Preloader loading={isLoadingGetOne}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize
          innerRef={formRef}
        >
          {({ values, setFieldValue, errors }) => {
            const totalSum = Array.isArray(values?.items)
              ? values?.items.reduce(
                (total, bills) => total + (+bills.amount || 0) * (+bills.value || 0),
                0,
              )
              : 0

            const pvm = values.vat_calculated ? (totalSum * 0.21).toFixed(2) : 0
            return (
              <Form className="not-card">
                <Row gutter={[24, 24]}>
                  <Col xs={24} lg={24}>
                    <h2 className={style.title}>
                      {t('transportationCreate.routeInfo')}
                    </h2>
                    <Table
                      dataSource={[
                        ...(info?.transportation?.points || []),
                        {
                          id: 'total',
                          cargo: {
                            name: t('transportation.columns.total'),
                          },
                          address_from: '',
                          address_to: '',
                          distance: (info?.transportation?.points || []).reduce((sum, point) => sum + (point.distance || 0), 0),
                          total: (info?.transportation?.points || []).reduce(
                            (sum, point, index) => sum + ((values?.points[index]?.distance_rate || 0) * (point.distance || 0)),
                            0,
                          ),
                        },
                      ]}
                      columns={columns(setFieldValue, values)}
                      loading={isLoadingGetOne}
                      rowKey="id"
                      scroll={{ x: 'max-content' }}
                      pagination={false}
                      onRow={(record) => ({
                        style: {
                          height: String(record.id) === 'total' ? '48px' : '64px',
                          lineHeight: '64px',
                        },
                      })}
                    />
                  </Col>
                  <Col xs={24} md={24} xl={12}>
                    <h2 className={style.title}>
                      {t('transportationCreate.transportationInfo')}
                    </h2>
                    <Card className={style.card}>
                      <Row gutter={[8, 0]}>
                        <Col span={24}>
                          <SelectFormik
                            name="type"
                            placeholder={t('transportationCreate.type')}
                            options={[
                              {
                                value: TransportationBillTypeEnum.Tax,
                                label: 'PVM SĄSKAITA-FAKTŪRA',
                              },
                              {
                                value: TransportationBillTypeEnum.Credit,
                                label: 'KREDITINĖ SĄSKAITA-FAKTŪRA',
                              },
                            ]}
                          />
                        </Col>
                        <Col xs={24} md={12}>
                          <FieldFormik
                            name="bill_number"
                            placeholder={t('transportationCreate.billNumber') ?? ''}
                          />
                        </Col>
                        <Col xs={24} md={10}>
                          <SelectFormik
                            name="payer_id"
                            showSearch
                            placeholder={t('transportationBillsEdit.company') ?? ''}
                            options={companiesSelect?.map((item) => ({ label: item.name, value: item.id })) || []}
                          />
                        </Col>
                        <Col xs={24} md={2}>
                          <Button
                            size="large"
                            style={{
                              marginTop: '20px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                            onClick={openDrawer}
                          >
                            <PlusCircleIcon />
                          </Button>
                        </Col>
                        <Col xs={24} md={12}>
                          <DatePickerFormik name="pay_by" placeholder={t('transportationCreate.payBy') ?? ''} />
                        </Col>
                        <Col xs={24} md={12}>
                          <DatePickerFormik
                            name="date"
                            placeholder={t('transportationBillsEdit.dateClosed') ?? ''}
                          />
                        </Col>
                        {values.items.map((item: any, i: any) => (
                          <Fragment key={item.id}>
                            <Col xs={24} md={10}>
                              <FieldFormik
                                name={`items[${i}].name`}
                                placeholder={t('transportationCreate.customCargoName') ?? ''}
                              />
                            </Col>
                            <Col xs={24} md={4}>
                              <SelectFormik
                                name={`items[${i}].unit`}
                                showSearch
                                placeholder={t('transportationCreate.customCargoUnit') ?? ''}
                                options={[{ value: 'VNT', label: 'VNT' }]}
                              />
                            </Col>
                            <Col xs={24} md={4}>
                              <FieldFormik
                                name={`items[${i}].amount`}
                                type="number"
                                placeholder={t('transportationCreate.customCargoAmount') ?? ''}
                              />
                            </Col>
                            <Col xs={24} md={4}>
                              <FieldFormik
                                name={`items[${i}].value`}
                                type="number"
                                placeholder={t('transportationCreate.customBillValue') ?? ''}
                              />
                            </Col>
                            <Col xs={24} md={2}>
                              {values.items.length > 1 && (
                                <Button
                                  size="large"
                                  style={{
                                    marginTop: '20px',
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                  onClick={() => {
                                    setFieldValue('items', values.items.filter((_: any, filterI: number) => filterI !== i))
                                  }}
                                >
                                  <DeleteIcon color="#E94444" />
                                </Button>
                              )}
                            </Col>
                          </Fragment>
                        ))}
                        {errors.items && isString(errors.items) && (
                          <Col>
                            <span style={{ color: 'red' }}>{errors.items.toString()}</span>
                          </Col>
                        )}
                        <Col span={24}>
                          <Button
                            className={style.button}
                            icon={<PlusIcon />}
                            onClick={() => {
                              setFieldValue('items', [...values.items, { name: '', unit: 'VNT', amount: 1, value: '', id: new Date().getTime() }])
                            }}
                          >
                            {t('transportationCreate.addBill')}
                          </Button>
                        </Col>
                        <Col span={24}>
                          <FieldFormik
                            name="comment"
                            placeholder={t('transportationBillsEdit.commentPlaceholder')}
                            label={t('transportationBillsEdit.comment')}
                          />
                        </Col>
                        <Col span={24} style={{ marginBottom: '24px' }}>
                          <SwitchFormik name="vat_calculated" placeholder={t('agreementInvoicesEdit.vatCalculated')} />
                        </Col>
                        <Col span={12}>
                          <p className={style.sumText}>
                            {`${t('transportationBillsEdit.transportationCost')}:`}
                          </p>
                        </Col>
                        <Col span={12}>
                          <p className={style.sum}>{`${(totalSum)} EUR`}</p>
                        </Col>
                        <Col span={12}>
                          <p className={style.sumText}>
                            {t('invoicesCreate.pvm')}
                          </p>
                        </Col>
                        <Col span={12}>
                          <p className={style.sum}>{`${+pvm} EUR`}</p>
                        </Col>
                        <Col span={12}>
                          <p className={style.boldSumText}>
                            {`${t('accounting.totalSum')}:`}
                          </p>
                        </Col>
                        <Col span={12}>
                          <p className={style.boldSum}>
                            {`${+(+pvm + +totalSum).toFixed(2)} EUR`}
                          </p>
                        </Col>
                        <div className={style.actions}>
                          <Button
                            htmlType="submit"
                            className="card-button"
                            style={{ margin: '0 0 24px 0' }}
                            onClick={() => { setFieldValue('status', TransportationBillStatus.processed) }}
                            type="primary"
                            disabled={isLoadingArchive}
                            loading={isLoadingEdit}
                          >
                            {t('transportationBillsEdit.submit')}
                          </Button>
                          <Button
                            className={style.button}
                            htmlType="submit"
                            onClick={() => { setFieldValue('status', TransportationBillStatus.archived) }}
                            type="primary"
                            disabled={isLoadingEdit}
                            loading={isLoadingArchive}
                          >
                            {t('transportationBillsEdit.toArchive')}
                          </Button>
                        </div>
                      </Row>
                    </Card>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Card
                      className={styleTransportation.carCardStyle}
                      loading={isLoadingGetOne}
                      activeTabKey={activeTabKey}
                      onTabChange={setActiveTabKey}
                      tabList={[
                        { key: 'invoice', label: t('transportationView.tabs.invoice') },
                        { key: 'agreement', label: t('transportationView.tabs.agreement') },
                        { key: 'images', label: t('transportationView.tabs.gallery') },
                      ]}
                      tabProps={{
                        size: 'middle',
                      }}
                    >
                      {activeTabKey === 'invoice' && (
                        <Col span={24}>
                          <Card className={style.card} style={{ padding: '12px 24px 16px 24px' }}>
                            {values && (
                              <InvoiceDefaultDocument
                                values={{
                                  name: values.type === TransportationBillTypeEnum.Tax ? 'PVM SĄSKAITA-FAKTŪRA' : 'KREDITINĖ SĄSKAITA-FAKTŪRA',
                                  bill_number: values.bill_number,
                                  company_id: values.payer_id as number,
                                  date: values.date,
                                  pay_by: values.pay_by,
                                  type: values.type,
                                  items: values.items,
                                  vat_calculated: !!values.vat_calculated,
                                  comment: values.comment,
                                }}
                              />
                            )}
                          </Card>
                        </Col>
                      )}
                      {activeTabKey === 'agreement' && (
                        <Col span={24}>
                          <Card className={style.card} style={{ padding: '12px 24px 16px 24px' }}>
                            {info?.transportation && (
                              <TransportationDocument values={info.transportation} />
                            )}
                          </Card>
                        </Col>
                      )}
                      {activeTabKey === 'images' && (
                        <Col span={24}>
                          {info?.transportation?.images?.length ? (
                            <Card className={style.card} style={{ padding: '12px 24px 16px 24px' }}>
                              <Images
                                imageList={info.transportation.images}
                                onImageDelete={handleDeleteImage}
                              />
                            </Card>
                          ) : <NoData />}
                        </Col>
                      )}
                    </Card>
                  </Col>
                </Row>
              </Form>
            )
          }}
        </Formik>
      </Preloader>
      <Drawer
        title={t('customerCreate.title')}
        open={isDrawerVisible}
        onClose={closeDrawer}
        width={554}
      >
        <CustomerCreate isCreate onClose={closeDrawer} />
      </Drawer>
    </div>
  )
}
