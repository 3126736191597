import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { transportationApi } from '@src/store/services/transportation-service'
import { TransportationType } from '@src/types/transportation'
import { UserRoles } from '@src/types/users'
import { ColumnProps } from 'antd/lib/table'
import { AllowedTo } from '@src/components/AllowedTo/AllowedTo'
import { ReactComponent as DocumentIcon } from '@src/assets/document_icon.svg'
import { ReactComponent as SignatureIcon } from '@src/assets/signature_icon.svg'
import { TransportationSign } from '@src/components/pages/transportation/TransportationSign'
import { isSelectionText } from '@src/lib/utils'
import cn from 'classnames'
import { TransportationTooltip } from './TransportationTooltip'

export const useTransportationTable = ({ initParamsFilters }: any) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [paramsFilters, setParamsFilters] = useState(initParamsFilters)
  const { data, isLoading } = transportationApi.useGetListTransportationsQuery(paramsFilters)
  const [signTransportationId, setSignTransportationId] = useState<null | number>(null)
  const [generateDocument] = transportationApi.useTransportationGenerateDocumentMutation()

  const onCell = useCallback((record: TransportationType) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/transportation/view/${record.id}`)
      }
    },
  }), [history])

  const columns = useMemo<ColumnProps<TransportationType>[]>(() => [
    {
      title: t('transportation.columns.date'),
      dataIndex: 'date',
      key: 'date',
      onCell,
    },
    {
      title: t('transportation.columns.from'),
      dataIndex: 'from',
      key: 'from',
      render: (_, record) => record.from?.full_name,
      onCell,
    },
    {
      title: t('transportation.columns.to'),
      dataIndex: 'to',
      key: 'to',
      render: (_, record) => record.to?.full_name,
      onCell,
    },
    {
      title: t('transportation.columns.route'),
      dataIndex: 'transportation',
      key: 'address_from',
      render: (_, record) => (
        <TransportationTooltip routes={record.points} />
      ),
      onCell,
    },
    {
      title: t('transportation.columns.distance'),
      dataIndex: 'total_distance',
      key: 'distance',
      render: (total_distance) => `${total_distance} km`,
      onCell,
    },
    {
      title: t('transportation.columns.driver'),
      dataIndex: 'driver',
      key: 'driver',
      render: (driver) => driver.full_name,
      onCell,
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '76px',
      fixed: 'right',
      render: (_, record) => (
        <div className={cn('table_actions')}>
          {(!record.recipient_signature || !record.driver_signature) && (
            <button
              type="button"
              className={cn('table_actions_item')}
              onClick={() => { setSignTransportationId(record.id) }}
            >
              <SignatureIcon />
            </button>
          )}
          <AllowedTo roles={[UserRoles.Admin, UserRoles.Driver]}>
            <button
              className={cn('table_actions_item')}
              type="button"
              onClick={() => generateDocument(record.id)}
            >
              <DocumentIcon />
            </button>
          </AllowedTo>
        </div>
      ),
    },
  ], [generateDocument, onCell, t])

  const TransportationsComponents = useMemo(() => (
    <TransportationSign transportationId={signTransportationId} onClose={() => setSignTransportationId(null)} />
  ), [signTransportationId])

  return {
    paramsFilters,
    setParamsFilters,
    data,
    isLoading,
    columns,
    TransportationsComponents,
  }
}
