import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { Button, Col, Divider, Row } from 'antd'
import { Preloader } from '@src/components/Preloader/Preloader'
import { companiesApi } from '@src/store/services/companies-service'
import { customerWorkersApi } from '@src/store/services/customerWorkers-service'
import { CompanyType } from '@src/types/company'
import { SelectFormik } from '@src/components/SelectFormik/SelectFormik'
import style from './customerCreate.module.scss'

type CustomerCreateProps = {
  isModal?: boolean
  successCallbackModal?: () => void
  onClose: () => void
  isCreate?: boolean
}

export const CustomerCreate: FC<CustomerCreateProps> = ({ isModal, successCallbackModal, onClose, isCreate }) => {
  const { t } = useTranslation()
  const params = useParams<{ id?: string }>()
  const { data, isLoading: isLoadingGetOne } = companiesApi.useGetOneCompanyQuery(params.id ? +params.id : 0, { skip: !params.id || isModal || isCreate })
  const [createCustomer, { isLoading: isLoadingCreate }] = companiesApi.useCreateCompanyMutation()
  const [editCustomer, { isLoading: isLoadingEdit }] = companiesApi.useEditCompanyMutation()
  const [createWorker] = customerWorkersApi.useCreateMutation()
  const { data: companyForms } = companiesApi.useGetCompaniesFormQuery()

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      form: Yup.string().required(t('form.errors.required') ?? ''),
      name: Yup.string().required(t('form.errors.required') ?? ''),
      company_code: Yup.string().required(t('form.errors.required') ?? ''),
      vat_number: Yup.string().required(t('form.errors.required') ?? ''),
      address: Yup.string().required(t('form.errors.required') ?? ''),
    })
  ), [t])

  const initialValues = useMemo(() => ({
    form: data?.form ?? '',
    name: data?.name ?? '',
    company_code: data?.company_code ?? '',
    vat_number: data?.vat_number ?? '',
    address: data?.address ?? '',
    type: CompanyType.Customer,
    nameWorker: '',
    emailWorker: '',
    phoneWorker: '',
    positionWorker: '',
  }), [data])

  const successCallback = useCallback(() => {
    if (successCallbackModal) {
      successCallbackModal()
      onClose()
    } else {
      onClose()
    }
  }, [successCallbackModal, onClose])

  const onSubmit = useCallback(async (values: any) => {
    let customerResult
    if (params.id && !isModal && !isCreate) {
      customerResult = await editCustomer({ ...values, id: +params.id })
    } else {
      customerResult = await createCustomer(values)
    }

    if ('data' in customerResult) {
      const customerId = customerResult.data.id

      if (values.nameWorker || values.emailWorker || values.phoneWorker || values.positionWorker) {
        const workerPayload = {
          name: values.nameWorker,
          email: values.emailWorker,
          phone: values.phoneWorker,
          position: values.positionWorker,
          company_id: customerId,
          default: false,
        }

        const workerResult = await createWorker(workerPayload)

        if ('data' in workerResult) {
          successCallback()
        }
      } else {
        successCallback()
      }
    }
  }, [createCustomer, editCustomer, createWorker, isModal, isCreate, params.id, successCallback])

  return (
    <Preloader loading={isLoadingGetOne}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {() => (
          <Form className="not-card">
            <Row gutter={[8, 0]}>
              <Col xs={24} md={12}>
                <SelectFormik
                  name="form"
                  placeholder={t('customerCreate.formOwnership') ?? ''}
                  options={companyForms?.forms.map((item) => ({
                    label: item,
                    value: item,
                  })) || []}
                />
              </Col>
              <Col xs={24} md={12}>
                <FieldFormik name="name" placeholder={t('customerCreate.name') ?? ''} />
              </Col>
              <Col xs={24} md={12}>
                <FieldFormik name="vat_number" placeholder={t('customerCreate.vatNumber') ?? ''} />
              </Col>
              <Col xs={24} md={12}>
                <FieldFormik name="company_code" placeholder={t('customerCreate.code') ?? ''} />
              </Col>
              <Col xs={24} md={24}>
                <FieldFormik name="address" placeholder={t('customerCreate.address') ?? ''} />
              </Col>
              {((!params.id && !isModal) || isCreate) && (
                <>
                  <Divider />
                  <Col xs={24} md={24}>
                    <p className={style.workerTitle}>
                      {t('customerWorkers.add') ?? ''}
                    </p>
                  </Col>
                  <Col xs={24} md={12}>
                    <FieldFormik name="nameWorker" placeholder={t('customerWorkers.columns.name') ?? ''} />
                  </Col>
                  <Col xs={24} md={12}>
                    <FieldFormik name="emailWorker" placeholder={t('customerWorkers.columns.email') ?? ''} />
                  </Col>
                  <Col xs={24} md={12}>
                    <FieldFormik name="phoneWorker" placeholder={t('customerWorkers.columns.phone') ?? ''} />
                  </Col>
                  <Col xs={24} md={12}>
                    <FieldFormik name="positionWorker" placeholder={t('customerWorkers.columns.position') ?? ''} />
                  </Col>
                </>
              )}
            </Row>
            <Button
              className="card-button"
              htmlType="submit"
              type="primary"
              loading={isLoadingCreate || isLoadingEdit}
            >
              {params.id && !isModal ? t('customerCreate.save') : t('customerCreate.submit')}
            </Button>
          </Form>
        )}
      </Formik>
    </Preloader>
  )
}
