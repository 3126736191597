import React, { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, Col, Divider, Row } from 'antd'
import { useHistory } from 'react-router-dom'
import { PaginationFormik } from '@src/components/PaginationFormik/PaginationFormik'
import { Button } from '@src/components/ui/Button/Button'
import { ReactComponent as DocumentIcon } from '@src/assets/document_icon.svg'
import { ReactComponent as SignatureIcon } from '@src/assets/signature_icon.svg'
import { ReactComponent as FiltersIcon } from '@src/assets/filters_icon.svg'
import { Preloader } from '@src/components/Preloader/Preloader'
import { TransportationSign } from '@src/components/pages/transportation/TransportationSign'
import { TransportationType } from '@src/types/transportation'
import { ResponseMetaType } from '@src/api/api'
import { transportationApi } from '@src/store/services/transportation-service'
import { DriverFilters } from './DriverFilters'
import { FloatingButtons } from './FloatingButtons'
import style from './driver.module.scss'

type TransportationProps = {
  transportations?: {
    data: TransportationType[]
    meta: ResponseMetaType
  }
  setParamsFilters: (state: any) => void
  isLoading: boolean
}

export const DriverTransportations: FC<TransportationProps> = ({ transportations, setParamsFilters, isLoading }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [signTransportationId, setSignTransportationId] = useState<null | number>(null)
  const [isShowFilters, setIsShowFilters] = useState<boolean>(false)
  const [generateDocument, { isLoading: isLoadingGenerateDocument }] = transportationApi.useTransportationGenerateDocumentMutation()

  const handlePaginationChange = useCallback((page: number) => {
    setParamsFilters((prevState: any) => ({
      ...prevState,
      page,
    }))
  }, [setParamsFilters])

  const handleCustomerClick = useCallback((id: number) => {
    history.push(`/transportation/edit/${id}`)
  }, [history])

  const handleSignClick = (e: React.MouseEvent, id: number) => {
    e.stopPropagation()
    setSignTransportationId(id)
  }

  return (
    <>
      <Preloader loading={isLoading}>
        <Col span={24}>
          <div className={style.title}>
            <h2>
              {t('menu.transportation')}
            </h2>
            <FiltersIcon
              className={style.icon}
              onClick={() => setIsShowFilters(true)}
            />
          </div>
        </Col>
        <Row gutter={[24, 8]}>
          {transportations?.data?.map((transportation) => (
            <Col span={24} key={transportation.id}>
              <Card
                className={style.card}
                onClick={() => handleCustomerClick(transportation.id)}
              >
                <Row gutter={[16, 0]}>
                  <Col span={8}>
                    <p className={style.placeholder}>
                      {t('transportation.columns.from')}
                    </p>
                    <p className={style.info}>
                      {transportation?.from?.name}
                    </p>
                  </Col>
                  <Col span={8}>
                    <p className={style.placeholder}>
                      {t('transportation.columns.to')}
                    </p>
                    <p className={style.info}>
                      {transportation?.to?.name}
                    </p>
                  </Col>
                  <Col span={4}>
                    <p className={style.placeholder}>
                      {t('transportation.columns.distance')}
                    </p>
                    <p className={style.info}>
                      {`${transportation?.total_distance}km`}
                    </p>
                  </Col>
                  <Col span={4}>
                    <p className={style.placeholder}>
                      {t('transportation.columns.date')}
                    </p>
                    <p className={style.info}>
                      {transportation?.date}
                    </p>
                  </Col>
                  <Col span={24}>
                    <p className={style.cardTitle}>
                      {t('transportation.columns.route')}
                    </p>
                  </Col>
                  {transportation?.points.map((point, index) => (
                    <React.Fragment key={point.id}>
                      <Col span={12} className={style.col}>
                        <p className={style.placeholder}>
                          {t('transportation.columns.fromPoints')}
                        </p>
                        <p className={style.info}>
                          {point?.address_from}
                        </p>
                      </Col>
                      <Col span={12} className={style.col}>
                        <p className={style.placeholder}>
                          {t('transportation.columns.toPoints')}
                        </p>
                        <p className={style.info}>
                          {point?.address_to}
                        </p>
                      </Col>
                      {point?.cargo?.name && (
                        <Col span={24} className={style.col}>
                          <p className={style.placeholder}>
                            {t('transportation.columns.cargo')}
                          </p>
                          <p className={style.info}>
                            {point?.cargo?.name}
                          </p>
                        </Col>
                      )}
                      {transportation?.points.length - 1 !== index && (
                        <Col span={24}>
                          <Divider className={style.divider} />
                        </Col>
                      )}
                    </React.Fragment>
                  ))}
                  <Col span={24}>
                    <Row gutter={[16, 0]}>
                      <Col span={12}>
                        <Button
                          type="secondary"
                          size="large"
                          leftIcon={<SignatureIcon />}
                          style={{ height: '38px', width: '100%' }}
                          onClick={(e) => handleSignClick(e, transportation.id)}
                        />
                      </Col>
                      <Col span={12}>
                        <Button
                          type="secondary"
                          size="large"
                          leftIcon={<DocumentIcon />}
                          style={{ height: '38px', width: '100%' }}
                          disabled={isLoadingGenerateDocument}
                          onClick={(e) => {
                            e.stopPropagation()
                            generateDocument(transportation.id)
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
        <div className={style.paginations}>
          <PaginationFormik
            total={transportations?.meta?.totalCount || 0}
            current={transportations?.meta?.currentPage || 1}
            pageSize={transportations?.meta?.pageSize || 10}
            onChange={handlePaginationChange}
          />
        </div>
        <TransportationSign transportationId={signTransportationId} onClose={() => setSignTransportationId(null)} />
      </Preloader>
      <FloatingButtons />
      <DriverFilters isOpen={isShowFilters} onClose={() => setIsShowFilters(false)} />
    </>
  )
}
