import { ReactComponent as SpinIcon } from '@src/assets/spin.svg'
import { FC } from 'react'
import cn from 'classnames'
import style from './spin.module.scss'

type SpinProps = {
  className?: string
}

export const Spin: FC<SpinProps> = ({ className }) => <SpinIcon className={cn(style.icon, className)} />
