import { FC, useMemo, useRef, useState } from 'react'
import { Form, Formik } from 'formik'
import { Button, Col, Divider, List, Row } from 'antd'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { tasksApi } from '@src/store/services/tasks-service'
import { AllowedTo } from '@src/components/AllowedTo/AllowedTo'
import { UserRoles } from '@src/types/users'
import { TaskStatusEnum, TaskType } from '@src/types/tasks'
import moment from 'moment'
import { serviceInvoicesApi } from '@src/store/services/serviceInvoices-service'
import { authApi } from '@src/store/services/auth-service'
import { DatePickerFormik } from '@src/components/DatePickerFormik/DatePickerFormik'
import { SelectFormik } from '@src/components/SelectFormik/SelectFormik'
import { formatPrice } from '@src/lib/formatPrice'
import { detailsApi } from '@src/store/services/details-service'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@src/store/store'
import { Storage } from '@src/types/details'
import { TaskEndWorkQuizForm } from '@src/components/pages/tasks/TaskEndWork/TaskEndWorkQuizForm'
import { TaskEndWorkQuizView } from '@src/components/pages/tasks/TaskEndWork/TaskEndWorkQuizView'
import style from './taskEndWork.module.scss'

type TaskEndWorkProps = {
  taskId: number
  quiz?: string
  taskStatus: TaskStatusEnum
  setActiveTabKey: (value: string) => void
  workDurationHours?: string
  workDurationMinutes?: string
  data: TaskType
  details: Array<{
    id: number
    name: string
    serial_number: string
    quantity: number
    storage_count: number
    count: number
    spent_quantity: number
    price: number
    sell_price: number
    storages?: Storage[]
  }>
}

export const TaskEndWork: FC<TaskEndWorkProps> = ({ taskId, quiz, taskStatus, setActiveTabKey, details, workDurationHours, workDurationMinutes, data }) => {
  const { t } = useTranslation()
  const signFormRef = useRef<any>()
  const dispatch = useDispatch<AppDispatch>()
  const { data: user } = authApi.useGetMeQuery()
  const [taskSign, { isLoading: isLoadingSign }] = tasksApi.useTaskSignMutation()
  const { data: timelogs } = tasksApi.useGetTimelogsHistoryQuery(
    taskId,
    { skip: (taskStatus !== TaskStatusEnum.Finished) },
  )
  const [addDetailsToDetailsStorage, { isLoading: isLoadingAddDetailsToDetailsStorage }] = detailsApi.useAddDetailsToDetailsStorageMutation()
  const [takeDetail, { isLoading: isLoadingTakeDetail }] = detailsApi.useTakeDetailMutation()
  const [increasePrice, setIncreasePrice] = useState(40)

  const timelogsDiff = useMemo(() => {
    if (timelogs) {
      return timelogs.reduce((acc, current) => acc + moment(current.ended).diff(current.started, 'minutes'), 0)
    }

    return 0
  }, [timelogs])

  const signValidationSchema = useMemo(() => (
    Yup.object().shape({
      work_duration_hours: Yup.string().required(t('form.errors.required') ?? ''),
      work_duration_minutes: Yup.string().required(t('form.errors.required') ?? ''),
      date_start: Yup.string().required(t('form.errors.required') ?? ''),
      date_end: Yup.string().required(t('form.errors.required') ?? ''),
    })
  ), [t])

  const signInitialValues = useMemo(() => ({
    work_duration_hours: signFormRef.current?.values?.work_duration_hours || workDurationHours || Math.floor(timelogsDiff / 60),
    work_duration_minutes: signFormRef.current?.values?.work_duration_hours || workDurationMinutes || timelogsDiff % 60,
    details: details.map((item, index) => ({
      ...item,
      // @ts-ignore
      spent_quantity: signFormRef.current?.values?.details?.[index]?.spent_quantity || item.spent_quantity || item.quantity,
      user_id: signFormRef.current?.values?.details?.[index]?.user_id || data.workers.length === 1 ? data.workers[0].id : null,
      price: signFormRef.current?.values.increase_price === increasePrice ? (signFormRef.current?.values?.details?.[index]?.price || item.sell_price || ((item.price * (increasePrice + 100)) / 100).toFixed(2)) : ((item.price * (increasePrice + 100)) / 100).toFixed(2),
      storePrice: item.price,
    })),
    // eslint-disable-next-line no-nested-ternary
    date_start: signFormRef.current?.values?.date_start ? moment(signFormRef.current?.values?.date_start) : data.date_start ? moment(data.date_start) : '',
    date_end: signFormRef.current?.values?.date_end || data.date_end ? moment(data.date_end) : '',
    increase_price: increasePrice || signFormRef.current?.values?.increase_price || 40,
  }), [details, timelogsDiff, workDurationHours, workDurationMinutes, data, increasePrice])

  const onSubmitSign = async (values: any) => {
    const result = await taskSign({
      taskId,
      body: {
        ...values,
        date_start: moment(values.date_start).format('YYYY-MM-DD HH:mm'),
        date_end: moment(values.date_end).format('YYYY-MM-DD HH:mm'),
      },
    })

    if ('data' in result) {
      serviceInvoicesApi.util.invalidateTags(['ServiceInvoice'])
      setActiveTabKey('information')
    }
  }

  const giveDetail = async (detail: any, isNowhere: boolean) => {
    const mechanicCount = detail.storages?.find((storage: any) => storage.user_id === detail.user_id)?.count || 0

    const count = detail.spent_quantity - mechanicCount

    if (count > 0) {
      if (isNowhere) {
        await addDetailsToDetailsStorage({
          detail_id: detail.id,
          count,
          to_id: detail.user_id,
        })
      } else {
        await takeDetail({
          detail_id: detail.id,
          count,
          to_id: detail.user_id,
        })
      }
    }

    dispatch(tasksApi.util.invalidateTags(['Tasks']))
  }

  return (
    <div>
      {[TaskStatusEnum.Finished].includes(taskStatus) && user?.role !== UserRoles.Mechanic ? (
        <TaskEndWorkQuizView quiz={quiz} />
      ) : (
        <TaskEndWorkQuizForm taskId={taskId} isCustomerSign={!!data.customer_signature} />
      )}
      <AllowedTo roles={[UserRoles.Admin, UserRoles.ServiceManager]}>
        {(taskStatus === TaskStatusEnum.Finished) && (
          <>
            <Divider orientation="left" orientationMargin="0">
              {t('tasks.viewTabs.sign')}
            </Divider>
            <Formik
              initialValues={signInitialValues}
              validationSchema={signValidationSchema}
              onSubmit={onSubmitSign}
              enableReinitialize
              innerRef={signFormRef}
            >
              {({ values }) => (
                <Form className="not-card">
                  <Row gutter={[20, 0]}>
                    <Col xs={24} md={12}>
                      <DatePickerFormik name="date_start" placeholder={t('tasks.endWork.dateStart')} showTime />
                    </Col>
                    <Col xs={24} md={12}>
                      <DatePickerFormik name="date_end" placeholder={t('tasks.endWork.dateEnd')} showTime />
                    </Col>
                    <Col xs={24} md={12}>
                      <FieldFormik
                        name="work_duration_hours"
                        placeholder={t('tasks.endWork.workDurationHours')}
                        type="number"
                      />
                    </Col>
                    <Col xs={24} md={12}>
                      <FieldFormik
                        name="work_duration_minutes"
                        placeholder={t('tasks.endWork.workDurationMinutes')}
                        type="number"
                      />
                    </Col>
                    <Col span={24}>
                      <List
                        className={style.details}
                        bordered
                        header={(
                          <div className={style.detailsRow}>
                            <div style={{ width: 200 }}>{t('details.columns.name')}</div>
                            <div style={{ width: 200 }}>{t('details.columns.serialNumber')}</div>
                            <div style={{ width: 80 }}>{t('details.columns.quantityForTask')}</div>
                            <div style={{ width: 100 }}>{t('details.columns.actualQuantityForTask')}</div>
                            <div style={{ width: 80 }}>{t('details.columns.in_mechanic')}</div>
                            <div style={{ width: 80 }}>{t('details.columns.storage_count')}</div>
                            {taskStatus === TaskStatusEnum.Finished && (
                              <div style={{ width: 150 }} />
                            )}
                            <div style={{ width: 100 }}>{t('details.columns.storePrice')}</div>
                            <div style={{ width: 100 }}>{t('details.columns.sellPrice')}</div>
                            <div style={{ width: 200 }}>{t('details.columns.worker')}</div>
                          </div>
                        )}
                        dataSource={values.details}
                        renderItem={(item, index) => (
                          <List.Item>
                            <div className={style.detailsRow}>
                              <div style={{ width: 200 }}>{item.name}</div>
                              <div style={{ width: 200, wordBreak: 'break-all' }}>{item.serial_number}</div>
                              <div style={{ width: 80 }}>{item.quantity}</div>
                              <div style={{ width: 100 }}>
                                <FieldFormik
                                  name={`details[${index}].spent_quantity`}
                                  placeholder="quantity"
                                  size="middle"
                                  noMarginBottom
                                  type="number"
                                />
                              </div>
                              <div style={{ width: 80 }}>
                                {item.storages?.find((storage) => storage.user_id === item.user_id)?.count || 0}
                              </div>
                              <div style={{ width: 80 }}>{item.count}</div>
                              {taskStatus === TaskStatusEnum.Finished && (
                                <div style={{ width: 150 }}>
                                  {(item.spent_quantity - (item.storages?.find((storage) => storage.user_id === item.user_id)?.count || 0)) > 0 && (
                                  <>
                                    <Button
                                      type="primary"
                                      onClick={() => giveDetail(item, false)}
                                      disabled={isLoadingAddDetailsToDetailsStorage}
                                      loading={isLoadingTakeDetail}
                                    >
                                      {t('tasks.endWork.giveDetailFromStorage')}
                                    </Button>
                                    <br />
                                    <Button
                                      type="primary"
                                      onClick={() => giveDetail(item, true)}
                                      disabled={isLoadingTakeDetail}
                                      loading={isLoadingAddDetailsToDetailsStorage}
                                      style={{ marginTop: 4 }}
                                    >
                                      {t('tasks.endWork.giveDetailFromNowhere')}
                                    </Button>
                                  </>
                                  )}
                                </div>
                              )}
                              <div style={{ width: 100 }}>
                                {formatPrice(item.storePrice)}
                              </div>
                              <div style={{ width: 100 }}>
                                <FieldFormik
                                  name={`details[${index}].price`}
                                  placeholder={t('details.columns.sellPrice')}
                                  size="middle"
                                  noMarginBottom
                                  type="number"
                                />
                              </div>
                              <div style={{ width: 200 }}>
                                <SelectFormik
                                  name={`details[${index}].user_id`}
                                  options={data.workers.map((worker) => ({
                                    value: worker.id,
                                    label: `${worker.name} ${worker.surname}`,
                                  }))}
                                />
                              </div>
                            </div>
                          </List.Item>
                        )}
                      />
                    </Col>
                  </Row>
                  {taskStatus === TaskStatusEnum.Finished && (
                    <>
                      <br />
                      <FieldFormik
                        name="increase_price"
                        placeholder={t('tasks.endWork.increasePrice')}
                        size="large"
                        noMarginBottom
                        type="number"
                        onChange={(e) => setIncreasePrice(e.target.value ? +e.target.value : 0)}
                      />
                    </>
                  )}
                  <br />
                  <Button type="primary" htmlType="submit" loading={isLoadingSign}>
                    {t('tasks.endWork.save')}
                  </Button>
                </Form>
              )}
            </Formik>
          </>
        )}
      </AllowedTo>
    </div>
  )
}
