import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'antd'
import { PrinterOutlined } from '@ant-design/icons'
import { companiesApi } from '@src/store/services/companies-service'
import moment from 'moment/moment'
import { InvoicesTypeEnum } from '@src/types/invoices'
// @ts-ignore
// eslint-disable-next-line import/no-extraneous-dependencies
import n2words from 'n2words'
import { customRound } from '@src/lib/converter'

type InvoiceDefaultDocumentProps = {
  lang?: 'lt' | 'en'
  values: {
    name: string
    bill_number: string
    company_id?: number
    date: any
    pay_by: any
    type: string
    vat_calculated: boolean
    comment: string
    items: Array<{
      name: string
      unit: string
      amount: number
      value: number
    }>
  }
}

export const InvoiceDefaultDocument: FC<InvoiceDefaultDocumentProps> = ({ values, lang = 'lt' }) => {
  const { i18n } = useTranslation()
  const { data: company } = companiesApi.useGetOneCompanyQuery(values.company_id!, { skip: !values.company_id })
  const t = i18n.getFixedT(lang)

  const price = useMemo(() => values.items.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.value * currentValue.amount, 0), [values])
  const pvm = useMemo(() => price * (values.vat_calculated ? 0.21 : 0), [price, values.vat_calculated])
  const total = useMemo(() => price + pvm, [pvm, price])

  return (
    <>
      <div className="document" id="printable-block">
        <p className="font-bold" style={{ textAlign: 'center', textTransform: 'uppercase' }}>
          {values.name}
          {}
        </p>
        <p className="text" style={{ textAlign: 'center' }}>
          {t('invoiceDocument.series')}
          {' '}
          {values.bill_number}
        </p>

        <table style={{ fontSize: 13, tableLayout: 'fixed', width: '100%' }}>
          <tbody>
            <tr>
              <td valign="top" style={{ width: '50%', minWidth: '50%', verticalAlign: 'top' }}>
                {t('invoiceDocument.seller')}
                <br />
                <strong>UAB Senresa</strong>
                <br />
                Vilų g. 36A - 60, LT-93102, Neringa
                <br />
                {t('invoiceDocument.companyCode')}
                {' '}
                304865983
                <br />
                {t('invoiceDocument.vatCode')}
                {' '}
                LT100012516511
                <br />
                {t('invoiceDocument.accountNumber')}
                {' '}
                LT597180300038467953
              </td>
              <td valign="top" style={{ width: '50%', minWidth: '50%', verticalAlign: 'top' }}>
                {t('invoiceDocument.buyer')}
                <br />
                <strong>{company?.full_name}</strong>
                <br />
                {company?.address}
                <br />
                {t('invoiceDocument.companyCode')}
                {' '}
                {company?.company_code}
                <br />
                {t('invoiceDocument.vatCode')}
                {' '}
                {company?.vat_number}
              </td>
            </tr>
          </tbody>
        </table>
        <p className="text" style={{ textAlign: 'center' }}>
          {t('invoiceDocument.date')}
          {' '}
          {moment(values.date).format('YYYY-MM-DD')}
        </p>
        <p className="text">
          {t('invoiceDocument.payBy')}
          {' '}
          {moment(values.pay_by).format('YYYY-MM-DD')}
        </p>

        <table className="image-table" style={{ width: '100%', fontSize: 13 }}>
          <tbody>
            <tr>
              <td
                className="image-row"
                style={{ width: '5%', fontWeight: 700, textAlign: 'center' }}
              >
                {t('invoiceDocument.number')}
              </td>
              <td className="image-row" style={{ width: '33.5%', fontWeight: 700 }}>{t('invoiceDocument.name')}</td>
              <td
                className="image-row"
                style={{ width: '15%', fontWeight: 700, textAlign: 'center' }}
              >
                {t('invoiceDocument.unitTitle')}
              </td>
              <td
                className="image-row"
                style={{ width: '10%', fontWeight: 700, textAlign: 'center' }}
              >
                {t('invoiceDocument.quantity')}
              </td>
              <td
                className="image-row"
                style={{ width: '17.5%', fontWeight: 700, textAlign: 'center' }}
              >
                {t('invoiceDocument.priceEUR')}
              </td>
              <td
                className="image-row"
                style={{ width: '19%', fontWeight: 700, textAlign: 'center' }}
              >
                {t('invoiceDocument.totalEUR')}
              </td>
            </tr>
            {values.items.map((item: any, index: number) => (
              <tr key={item.name}>
                <td
                  className="image-row"
                  style={{ width: '5%', fontWeight: 400, textAlign: 'center' }}
                >
                  {index + 1}
                  .
                </td>
                <td className="image-row" style={{ width: '33.5%', fontWeight: 400 }}>{item.name}</td>
                <td
                  className="image-row"
                  style={{ width: '15%', fontWeight: 400, textAlign: 'center' }}
                >
                  {t(`invoiceDocument.${item.unit}`) ?? ''}
                </td>
                <td
                  className="image-row"
                  style={{ width: '10%', fontWeight: 400, textAlign: 'center' }}
                >
                  {item.amount ?? ''}
                </td>
                <td
                  className="image-row"
                  style={{ width: '17.5%', fontWeight: 400, textAlign: 'center' }}
                >
                  {values.type === 'credit' && '-'}
                  {item.value?.toFixed?.(2) ?? ''}
                </td>
                <td
                  className="image-row"
                  style={{ width: '19%', fontWeight: 400, textAlign: 'center' }}
                >
                  {values.type === 'credit' && '-'}
                  {(item.value * item.amount).toFixed(2) ?? ''}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <table style={{ fontSize: 13, width: '100%' }}>
          <tbody>
            <tr style={{ width: '100%' }}>
              <td style={{ width: '20%', color: '#ffffff' }}>.</td>
              <td style={{ width: '35%' }}>{t('invoiceDocument.total')}</td>
              <td style={{ width: '45%', textAlign: 'right' }}>
                {values.type === InvoicesTypeEnum.credit && '-'}
                {price.toFixed(2)}
                {' '}
                EUR
              </td>
            </tr>
            <tr style={{ width: '100%' }}>
              <td style={{ width: '20%', color: '#ffffff' }}>.</td>
              <td style={{ width: '35%' }}>
                {t('invoiceDocument.pvm')}
                {' '}
                {values.vat_calculated ? '21' : '0'}
                %
              </td>
              <td style={{ width: '45%', textAlign: 'right' }}>
                {values.type === InvoicesTypeEnum.credit && '-'}
                {pvm.toFixed(2)}
                {' '}
                EUR
              </td>
            </tr>
            <tr style={{ width: '100%' }}>
              <td style={{ width: '20%', color: '#ffffff' }}>.</td>
              <td style={{ width: '35%' }}>{t('invoiceDocument.totalAmount')}</td>
              <td style={{ width: '45%', textAlign: 'right' }}>
                {values.type === InvoicesTypeEnum.credit && '-'}
                {total.toFixed(2)}
                {' '}
                EUR
              </td>
            </tr>
          </tbody>
        </table>
        <p className="text">
          {values.vat_calculated ? t('invoiceDocument.totalWithPVM') : t('invoiceDocument.totalWithoutPVM')}
          {' '}
          {n2words(customRound(total), { lang: values.type === InvoicesTypeEnum.proforma ? 'en' : 'lt' })}
          {' '}
          EUR,
          {' '}
          {total.toFixed(2).split('.')[1]}
          ct.
        </p>
        <p className="text">
          {values.comment}
        </p>
        <p className="text">
          <span style={{ width: '100%', display: 'inline-block' }}>
            <span style={{ float: 'left', width: '20%' }}>{t('invoiceDocument.invoiceIssuedBy')}</span>
            <span style={{ borderBottom: 'solid 1px #000000', width: '80%', display: 'block', marginLeft: '20%' }}>
              <span style={{ color: '#ffffff', opacity: 0 }}>empty input</span>
            </span>
          </span>
          <br />
          <span className="text" style={{ textAlign: 'center', display: 'inline-block', width: '100%' }}>
            {t('invoiceDocument.signature')}
          </span>
        </p>
        <p className="text">
          <span style={{ width: '100%', display: 'inline-block' }}>
            <span style={{ float: 'left', width: '23%' }}>{t('invoiceDocument.invoiceReceivedBy')}</span>
            <span style={{ borderBottom: 'solid 1px #000000', width: '77%', display: 'block', marginLeft: '23%' }}>
              <span style={{ color: '#ffffff', opacity: 0 }}>empty input</span>
            </span>
          </span>
          <br />
          <span className="text" style={{ textAlign: 'center', display: 'inline-block', width: '100%' }}>
            {t('invoiceDocument.signature')}
          </span>
        </p>

      </div>
      <div className="documentActions">
        <Button icon={<PrinterOutlined />} onClick={window.print}>{t('settings.print')}</Button>
      </div>
    </>
  )
}
