import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button as ButtonAntd } from 'antd'
import { Button } from '@src/components/ui/Button/Button'
import { ReactComponent as DocumentIcon } from '@src/assets/document_icon.svg'
import { ReactComponent as CustomerIcon } from '@src/assets/customer_icon.svg'
import { ReactComponent as LargePlusIcon } from '@src/assets/large-plus-button_icon.svg'
import style from './driver.module.scss'

export const FloatingButtons = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()

  const isCustomersActive = location.pathname === '/customers'
  const isDocumentsActive = location.pathname === '/transportation'

  const handleCustomerClick = useCallback(() => {
    history.push('/customers')
  }, [history])

  const handleDocumentClick = useCallback(() => {
    history.push('/transportation')
  }, [history])

  const handleCreateClick = useCallback(() => {
    history.push('/transportation/create')
  }, [history])

  return (
    <div>
      <div
        style={{
          position: 'fixed',
          bottom: '24px',
          display: 'flex',
          left: '50%',
          transform: 'translateX(-50%)',
          gap: '16px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button
          active={isCustomersActive}
          type="primary"
          size="large"
          leftIcon={<DocumentIcon />}
          onClick={handleCustomerClick}
        >
          {t('companies.customersTitle')}
        </Button>
        <ButtonAntd
          type="primary"
          shape="circle"
          icon={<LargePlusIcon />}
          className={style.circleButton}
          onClick={handleCreateClick}
        />
        <Button
          onClick={handleDocumentClick}
          active={isDocumentsActive}
          size="large"
          leftIcon={<CustomerIcon color="#FFFFFF" />}
        >
          {t('menu.transportation')}
        </Button>
      </div>
    </div>
  )
}
