import { Company } from '@src/types/company'

export type CarImages = {
  [key: string]: Array<{
    id: number
    image: string
    thumb: string
    image_s3: string
    thumb_s3: string
    order: number
  }>
}

export enum CarStatus {
  Available = 'available',
  InRent = 'in_rent',
  Service = 'service',
  Sold = 'sold'
}

export type Car = {
  id: number
  make: string
  model: string
  year: string
  serial_number: string
  engine_hours: string
  comment: string
  key: number
  status: CarStatus
  images: CarImages
  owner_id: number
  created_at: number
  from_service: boolean
  paid_once: boolean
  owner: Company
  car_category_id?: number
  car_value: string
  price: string
  category: {
    name: string
}
}

export type CarForSelect = {
  id: number
  name: string
  engine_hours: string
  owner_id: number
}

export type StoreFileRequest = {
  body: any
  car_id: number
  agreement_order_id?: number
  status?: number
}

export type CarRecord = {
  value: number
  ownerId: string
  engineHours: string
}

export type CarMakesType = {
  key: number
  name: string
}
