import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { Button, Card, Col, Divider, Drawer, Modal, Row, Table, Tag } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { UploadFile } from 'antd/es/upload/interface'
import { FileTextOutlined } from '@ant-design/icons'
import { Form, Formik } from 'formik'
import { Preloader } from '@src/components/Preloader/Preloader'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { SelectFormik } from '@src/components/SelectFormik/SelectFormik'
import { AgreementsCreate } from '@src/Pages/Agreements/AgreementsCreate'
import { CarsCreate } from '@src/Pages/CarsCreate/CarsCreate'
import { DatePickerFormik } from '@src/components/DatePickerFormik/DatePickerFormik'
import { UploadImages } from '@src/components/UploadImages/UploadImages'
import { AgreementInvoicesStatus, AgreementInvoicesType } from '@src/types/agreementInvoices'
import { agreementsApi } from '@src/store/services/agreements-service'
import { carsApi } from '@src/store/services/cars-service'
import { isSelectionText } from '@src/lib/utils'
import { customerWorkersApi } from '@src/store/services/customerWorkers-service'
import { AgreementOrderCreateCard } from '@src/components/pages/agreements/AgreementOrderCreateCard'
import { SwitchFormik } from '@src/components/SwitchFormik/SwitchFormik'
import { CarRecord, CarStatus } from '@src/types/car'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@src/store/store'
import { ViewContentHeader } from '@src/components/ViewContentHeader/ViewContentHeader'
import { ReactComponent as PlusCircleIcon } from '@src/assets/plus-circle_icon.svg'
import { ReactComponent as DeleteIcon } from '@src/assets/delete_icon.svg'
import { ReactComponent as PlusIcon } from '@src/assets/plus_icon.svg'
import { ReactComponent as CancelIcon } from '@src/assets/cancel_icon.svg'
import { Button as SecondaryButton } from '@src/components/ui/Button/Button'
import { UploadFiles } from '@src/components/UploadFiles/UploadFiles'
import moment from 'moment'
import * as Yup from 'yup'
import { serialize } from 'object-to-formdata'
import style from './agreementOrders.module.scss'

export const AgreementOrderCreate = () => {
  const formRef = useRef<any>()
  const { t } = useTranslation()
  const params = useParams<{ id?: string }>()
  const history = useHistory()
  const dispatch = useDispatch<AppDispatch>()
  const [isCreate, setIsCreate] = useState<boolean>(false)
  const [createType, setCreateType] = useState<'car' | 'agreement' | null>(null)
  const { data: info, isLoading } = agreementsApi.useGetOneAgreementOrderQuery(+params.id!, { skip: !params.id })
  const [create, { isLoading: isLoadingCreate }] = agreementsApi.useCreateAgreementOrderMutation()
  const [edit, { isLoading: isLoadingEdit }] = agreementsApi.useEditAgreementOrderMutation()
  const [triggerAgreement, { data: agreement }] = agreementsApi.useLazyGetOneAgreementQuery()
  const [triggerOwnerRepresentative, { data: ownerRepresentative }] = customerWorkersApi.useLazyShortListQuery()
  const [triggerCustomerRepresentative, { data: customerRepresentative }] = customerWorkersApi.useLazyShortListQuery()
  const { data: carsSelect } = carsApi.useGetCarsForSelectQuery({
    filters: !params?.id ? {
      status: {
        $eq: CarStatus.Available,
      },
    } : {},
  })
  const [deleteFileAgreements] = agreementsApi.useDeleteFileMutation()
  const [storeFileAttachment, { isLoading: isLoadingStoreFileAttachment }] = agreementsApi.useStoreFileAttachmentMutation()
  const { data: agreementsSelect } = agreementsApi.useGetShortListAgreementsQuery()
  const [triggerCar, { data: car, isFetching }] = carsApi.useLazyGetOneCarQuery()
  const [deleteImage] = carsApi.useDeleteImageMutation()

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        insurance: Yup.string().required(t('form.errors.required') ?? ''),
        payment_term_price: Yup.number().required(t('form.errors.required') ?? ''),
        payment_deadline: Yup.string().required(t('form.errors.required') ?? ''),
        lease_term: Yup.string().required(t('form.errors.required') ?? ''),
        max_engine_hours: Yup.string().required(t('form.errors.required') ?? ''),
        additional_engine_hour_cost: Yup.string().required(t('form.errors.required') ?? ''),
        rent_place: Yup.string().required(t('form.errors.required') ?? ''),
        date_start: Yup.string().required(t('form.errors.required') ?? ''),
        car_id: Yup.string().required(t('form.errors.required') ?? ''),
        car_engine_log: Yup.object().shape({
          engine_hours_start: Yup.string().required(t('form.errors.required') ?? ''),
        }),
        payment_term: Yup.string().required(t('form.errors.required') ?? ''),
        agreement_id: Yup.string().required(t('form.errors.required') ?? ''),
        owner_representative_id: Yup.string().required(t('form.errors.required') ?? ''),
        customer_representative_id: Yup.string().required(t('form.errors.required') ?? ''),
      }),
    [t],
  )

  const initialValues = useMemo(
    () => ({
      agreementOrderNumber: info?.agreement_order_number ?? '',
      agreement_id: info?.agreement_id ?? '',
      car_id: info?.car_id ?? '',
      car_engine_log: {
        engine_hours_start: info?.car_engine_log?.engine_hours_start ?? '',
        engine_hours_end: info?.car_engine_log?.engine_hours_end ?? undefined,
      },
      rent_place: info?.rent_place ?? 'Savanorių pr. 247, Vilnius, 02300',
      date_start: info?.date_start ? moment(info?.date_start) : '',
      date_end: info?.date_end ? moment(info?.date_end) : '',
      time_rent: info?.time_rent ? moment(info?.time_rent, 'HH:mm') : moment().startOf('hour'),
      equipment: info?.equipment ? info?.equipment : ([] as any),
      services: info?.services ? info?.services : ([] as any),
      insurance: info?.insurance ?? 'Nuomotojas',
      payment_term_price: info?.payment_term_price ?? '',
      payment_deadline: info?.payment_deadline ?? '15 dienų',
      lease_term: info?.lease_term ?? '',
      max_engine_hours: info?.max_engine_hours ?? '',
      additional_engine_hour_cost: info?.additional_engine_hour_cost ?? '',
      is_tank_full: info?.is_tank_full ?? 0,
      comment: info?.comment ?? '',
      defect_comment: info?.defect_comment ?? '',
      fileList: [] as UploadFile[],
      fileListAttachment: [],
      images: info?.images || {},
      payment_term: info?.payment_term || 'months',
      term_type: 'tmp',
      owner_representative_id: info ? info.owner_representative_id : '',
      customer_representative_id: info ? info.customer_representative_id : '',
    }),
    [info],
  )

  useEffect(() => {
    if (info) {
      triggerCar(info.car_id)
    }
  }, [info, triggerCar])

  const onSubmit = useCallback(
    async (values: any) => {
      const data = {
        ...values,
        date_start: values.date_start.format('YYYY-MM-DD'),
        date_end: values.date_end ? values.date_end.format('YYYY-MM-DD') : '',
        time_rent: values.time_rent ? values.time_rent.format('HH:mm:ss') : '',
        equipment: values.equipment,
        services: values.services,
        images: values.fileList.map((item: any) => item.originFileObj),
        fileList: undefined,
      }

      const formData = serialize(data, { indices: true })

      let result

      if (params.id) {
        result = await edit({
          id: +params.id,
          body: formData,
        })
      } else {
        result = await create(formData)
      }

      if ('data' in result) {
        if (data.fileListAttachment.length) {
          const filesPromise = data.fileListAttachment.map((item: any) => {
            const formData2 = new FormData()
            formData2.append('attachment', item.originFileObj)

            return storeFileAttachment({
              body: formData2,
              agreement_order_id: params.id ? +params.id : result!.data.id,
            })
          })
          await Promise.all(filesPromise)
        }

        history.goBack()
      }
    },
    [create, edit, history, params.id, storeFileAttachment],
  )

  const onCarChange = useCallback((record: CarRecord, setFieldValue: any) => {
    triggerCar(record.value)
    setFieldValue('car_engine_log.engine_hours_start', record.engineHours)
  }, [triggerCar])

  const onCloseCreate = useCallback(() => {
    setIsCreate(false)
    setCreateType(null)
  }, [])

  useEffect(() => {
    if (info) {
      triggerOwnerRepresentative({ companyId: +info.agreement.owner_id })
      triggerCustomerRepresentative({ companyId: +info.agreement.customer_id })
    }
  }, [info, triggerOwnerRepresentative, triggerCustomerRepresentative])

  useEffect(() => {
    if (agreement) {
      triggerOwnerRepresentative({ companyId: +agreement.owner_id })
      triggerCustomerRepresentative({ companyId: +agreement.customer_id })
      if (formRef.current) {
        formRef.current.setFieldValue('owner_representative_id', agreement.owner_representative_id)
        formRef.current.setFieldValue('customer_representative_id', agreement.customer_representative_id)
      }
    }
  }, [agreement, triggerOwnerRepresentative, triggerCustomerRepresentative])

  const onAgreementChange = useCallback((value: any, setFieldValue: any) => {
    triggerAgreement(+value)
    setFieldValue('owner_representative_id', '')
    setFieldValue('customer_representative_id', '')
  }, [triggerAgreement])

  const onCell = useCallback((record: AgreementInvoicesType) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/agreement-invoices/edit/${record.id}`)
      }
    },
  }), [history])

  const columnsInvoices = useMemo<ColumnProps<AgreementInvoicesType>[]>(() => [
    {
      title: t('agreementInvoices.columns.index'),
      dataIndex: 'index',
      key: 'index',
      render: (i, record, index) => index + 1,
      onCell,
    },
    {
      title: t('agreementInvoices.columns.dateStart'),
      dataIndex: 'date_start',
      key: 'date_start',
      onCell,
    },
    {
      title: t('agreementInvoices.columns.dateEnd'),
      dataIndex: 'date_end',
      key: 'date_end',
      onCell,
    },
    {
      title: t('agreementInvoices.columns.status'),
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        switch (status) {
          case AgreementInvoicesStatus.notProcessed:
            return <Tag color="blue">{t(`agreementInvoices.status.${AgreementInvoicesStatus.notProcessed}`)}</Tag>
          case AgreementInvoicesStatus.processed:
            return <Tag color="green">{t(`agreementInvoices.status.${AgreementInvoicesStatus.processed}`)}</Tag>
          case AgreementInvoicesStatus.archived:
            return <Tag color="red">{t(`agreementInvoices.status.${AgreementInvoicesStatus.archived}`)}</Tag>
          default:
            return ''
        }
      },
      onCell,
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      fixed: 'right',
      render: (_actions, record) => (
        <div className={style.actions}>
          <a
            // eslint-disable-next-line max-len
            href={`${process.env.REACT_APP_API_URL}/api/agreements/generate-invoice/${record.id}?access_token=${localStorage.getItem('token')}`}
            target="_blank"
            rel="noreferrer"
            className={style.actions_item}
          >
            <FileTextOutlined />
          </a>
        </div>
      ),
    },
  ], [onCell, t])

  const onImageDelete = useCallback(
    (imageId?: number) => {
      const { confirm } = Modal

      return new Promise<void>((resolve, reject) => {
        if (!imageId) {
          reject()
          return
        }

        confirm({
          title: t('confirmDeleteFileTitle'),
          onOk: async () => {
            await deleteImage(imageId)
            dispatch(agreementsApi.util.invalidateTags(['Agreements']))
            resolve()
          },
          onCancel: () => {
            reject()
          },
        })
      })
    },
    [deleteImage, dispatch, t],
  )

  return (
    <div>
      <ViewContentHeader
        onBackClick={() => history.goBack()}
      >
        {params.id ? t('agreementsCreate.titleEdit') : t('agreementsCreate.title')}
      </ViewContentHeader>
      <Preloader loading={isLoading}>
        <>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            innerRef={formRef}
            enableReinitialize
          >
            {({ values, setFieldValue }) => (
              <Row gutter={[24, 0]}>
                <Col xl={12} xs={24}>
                  <Form className="not-card">
                    <Col span={24}>
                      <h2 className={style.title}>{t('agreementsCreate.carInfoBlock')}</h2>
                    </Col>
                    <Card className={style.card}>
                      <Row gutter={[8, 0]}>
                        <Col xl={21} xs={22}>
                          <SelectFormik
                            name="car_id"
                            showSearch
                            onChange={(_, current) => onCarChange(current, setFieldValue)}
                            placeholder={t('agreementsCreate.car') ?? ''}
                            options={carsSelect?.map((item) => ({
                              label: item.name, value: item.id, ownerId: item.owner_id, engineHours: item.engine_hours,
                            })) || []}
                            suffixTag={car && car?.owner?.name}
                            isLoading={isFetching}
                          />
                        </Col>
                        <Col xl={3} xs={2} style={{ textAlign: 'end' }}>
                          <Button
                            size="large"
                            onClick={() => {
                              setIsCreate(true)
                              setCreateType('car')
                            }}
                            className={style.plusButton}
                          >
                            <PlusCircleIcon />
                          </Button>
                        </Col>
                      </Row>
                      {(info?.status === 1 && params.id) ? (
                        <Row gutter={[8, 0]}>
                          <Col span={12}>
                            <FieldFormik name="car_engine_log.engine_hours_start" placeholder={t('agreementsCreate.engineHoursStart') ?? ''} />
                          </Col>
                          <Col span={12}>
                            <FieldFormik name="car_engine_log.engine_hours_end" placeholder={t('agreementsCreate.engineHoursEnd') ?? ''} />
                          </Col>
                        </Row>
                      ) : (
                        <FieldFormik name="car_engine_log.engine_hours_start" placeholder={t('agreementsCreate.engineHoursStart') ?? ''} />
                      )}
                      <SwitchFormik name="is_tank_full" placeholder={t('agreementsCreate.isTankFull')} />
                      <Divider />
                      <h2 className={style.cardTitle}>{t('agreementsCreate.equipment')}</h2>
                      <Row gutter={[8, 0]}>
                        {values.equipment.map((item: any, i: any) => (
                          <Fragment key={item.id}>
                            <Col xs={11} xl={11}>
                              <FieldFormik
                                name={`equipment[${i}].name`}
                                placeholder={t('agreementsCreate.customEquipmentName') ?? ''}
                              />
                            </Col>
                            <Col xs={11} xl={10}>
                              <FieldFormik
                                name={`equipment[${i}].value`}
                                placeholder={t('agreementsCreate.customEquipmentValue') ?? ''}
                              />
                            </Col>
                            <Col xs={2} xl={3}>
                              <Button
                                size="large"
                                onClick={() => {
                                  setFieldValue('equipment', values.equipment.filter((equipment: any, filterI: number) => filterI !== i))
                                }}
                                className={style.plusButton}
                              >
                                <DeleteIcon color="#E94444" />
                              </Button>
                            </Col>
                          </Fragment>
                        ))}
                      </Row>
                      <SecondaryButton
                        onClick={() => {
                          setFieldValue('equipment', [...values.equipment, { name: '', value: '' }])
                        }}
                        type="secondary"
                        size="large"
                        leftIcon={<PlusIcon />}
                      >
                        {t('agreementsCreate.addEquipment')}
                      </SecondaryButton>
                      <Divider />
                      <h2 className={style.cardTitle}>{t('agreementsCreate.services')}</h2>
                      <Row gutter={[8, 0]}>
                        {values.services.map((item: any, i: any) => (
                          <Fragment key={item.id}>
                            <Col xl={11} xs={11}>
                              <FieldFormik
                                name={`services[${i}].name`}
                                placeholder={t('agreementsCreate.customEquipmentName') ?? ''}
                              />
                            </Col>
                            <Col xl={10} xs={11}>
                              <FieldFormik
                                name={`services[${i}].value`}
                                placeholder={t('agreementsCreate.customEquipmentValue') ?? ''}
                              />
                            </Col>
                            <Col xl={3} xs={2}>
                              <Button
                                size="large"
                                onClick={() => {
                                  setFieldValue('services', values.services.filter((service: any, filterI: number) => filterI !== i))
                                }}
                                className={style.plusButton}
                              >
                                <DeleteIcon color="#E94444" />
                              </Button>
                            </Col>
                          </Fragment>
                        ))}
                      </Row>
                      <SecondaryButton
                        onClick={() => {
                          setFieldValue('services', [...values.services, { name: '', value: '' }])
                        }}
                        type="secondary"
                        size="large"
                        leftIcon={<PlusIcon />}
                        style={{ marginBottom: '24px' }}
                      >
                        {t('agreementsCreate.addServices')}
                      </SecondaryButton>
                    </Card>
                    <Col span={24}>
                      <h2 className={style.title}>{t('agreementsCreate.representativeTitle')}</h2>
                    </Col>
                    <Card className={style.card}>
                      <Row gutter={[8, 0]}>
                        <Col span={24}>
                          <Row gutter={[8, 0]}>
                            <Col span={21}>
                              <SelectFormik
                                name="agreement_id"
                                options={agreementsSelect?.map((item) => ({ value: item.id, label: item.name })) || []}
                                placeholder={t('agreements.title')}
                                disabled={!!params.id}
                                onChange={(value) => onAgreementChange(value, setFieldValue)}
                              />
                            </Col>
                            <Col span={3}>
                              <Button
                                size="large"
                                onClick={() => {
                                  setIsCreate(true)
                                  setCreateType('agreement')
                                }}
                                className={style.plusButton}
                              >
                                <PlusCircleIcon />
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={24} md={12}>
                          <SelectFormik
                            name="owner_representative_id"
                            options={ownerRepresentative?.map((item) => ({
                              value: item.id!,
                              label: `${item.name}`,
                            })) || []}
                            disabled={!values.agreement_id}
                            placeholder={t('agreementsCreate.ownerRepresentative')!}
                          />
                        </Col>
                        <Col xs={24} md={12}>
                          <SelectFormik
                            name="customer_representative_id"
                            options={customerRepresentative?.map((item) => ({
                              value: item.id!,
                              label: `${item.name}`,
                            })) || []}
                            disabled={!values.agreement_id}
                            placeholder={t('agreementsCreate.customerRepresentative')!}
                          />
                        </Col>
                      </Row>
                    </Card>
                    <Col span={24}>
                      <h2 className={style.title}>{t('agreementsCreate.agreementInfoBlock')}</h2>
                    </Col>
                    <Card className={style.card}>
                      <Row gutter={[8, 0]}>
                        <Col xs={24} md={12}>
                          <SelectFormik
                            name="insurance"
                            placeholder={t('agreementsCreate.insurance') ?? ''}
                            options={[
                              {
                                value: 'Nuomotojas',
                                label: 'Nuomotojas',
                              },
                              {
                                value: 'Nuomininkas',
                                label: 'Nuomininkas',
                              },
                              {
                                value: 'Nera',
                                label: 'Nera',
                              },
                            ]}
                          />
                        </Col>
                        <Col xs={24} md={12}>
                          <FieldFormik
                            name="payment_deadline"
                            placeholder={t('agreementsCreate.paymentDeadline') ?? ''}
                            type="autoComplete"
                            autoCompleteOptions={[
                              '15 dienų',
                              '30 dienų',
                              '15 dienų nuo sąskaitos faktūros išrašymo dienos',
                              '30 dienų nuo sąskaitos faktūros išrašymo dienos',
                            ]}
                          />
                        </Col>
                        <Col xs={24} md={12}>
                          <FieldFormik
                            name="payment_term_price"
                            type="number"
                            placeholder={t('agreementsCreate.minimumRent') ?? ''}
                          />
                        </Col>
                        <Col xs={24} md={5}>
                          <FieldFormik
                            name="lease_term"
                            type="number"
                            placeholder={t('agreementsCreate.leaseTerm') ?? ''}
                          />
                        </Col>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '4px' }}>
                          <CancelIcon />
                        </div>
                        <Col xs={24} md={5}>
                          <SelectFormik
                            name="payment_term"
                            options={[
                              {
                                value: 'months',
                                label: t('agreementsCreate.paymentTerms.months'),
                              },
                              {
                                value: 'days',
                                label: t('agreementsCreate.paymentTerms.days'),
                              },
                              {
                                value: 'hours',
                                label: t('agreementsCreate.paymentTerms.hours'),
                              },
                            ]}
                          />
                        </Col>
                        <Col xs={24} md={12}>
                          <FieldFormik
                            name="max_engine_hours"
                            type="number"
                            placeholder={t('agreementsCreate.maxEngineHours') ?? ''}
                          />
                        </Col>
                        <Col xs={24} md={12}>
                          <FieldFormik
                            name="additional_engine_hour_cost"
                            type="number"
                            placeholder={t('agreementsCreate.engineHourCost') ?? ''}
                          />
                        </Col>
                        {info?.status === 1 && params.id ? (
                          <>
                            <Col xs={24} md={12}>
                              <DatePickerFormik name="date_start" placeholder={t('agreementsCreate.dateStart') ?? ''} />
                            </Col>
                            <Col xs={24} md={12}>
                              <DatePickerFormik name="date_end" placeholder={t('agreementsCreate.dateEnd') ?? ''} />
                            </Col>
                            <Col xs={24} md={24}>
                              <FieldFormik name="rent_place" placeholder={t('agreementsCreate.rentPlace') ?? ''} />
                            </Col>
                          </>
                        ) : (
                          <>
                            <Col xs={24} md={12}>
                              <DatePickerFormik name="date_start" placeholder={t('agreementsCreate.dateStart') ?? ''} />
                            </Col>
                            <Col xs={24} md={12}>
                              <FieldFormik name="rent_place" placeholder={t('agreementsCreate.rentPlace') ?? ''} />
                            </Col>
                          </>
                        )}
                        <Col xs={24} md={24}>
                          <FieldFormik
                            type="input"
                            name="comment"
                            placeholder={t('invoicesCreate.typeHere') ?? ''}
                            label={t('transportationBills.columns.comment')}
                          />
                        </Col>
                        <Divider style={{ marginTop: '0px' }} />
                        <Col span={24}>
                          <h2 className={style.cardTitle} style={{ margin: 0 }}>{t('agreementsCreate.files')}</h2>
                        </Col>
                        <Col span={24} style={{ marginTop: values.fileListAttachment.length ? '16px' : '0px' }}>
                          <UploadFiles
                            filesList={values.fileListAttachment}
                            onChange={(data) => {
                              setFieldValue('fileListAttachment', data)
                            }}
                          />
                        </Col>
                        {info?.attachments && (
                          <Row>
                            <Col xs={24} md={12}>
                              <div className={style.upload}>
                                <UploadImages
                                  isUploadButton={false}
                                  fileList={info.attachments.map((el: any) => ({
                                    url: `${process.env.REACT_APP_API_URL}/${el.file}`,
                                    id: el.id,
                                    name: el.filename,
                                  }))}
                                  onRemove={(data) => {
                                    deleteFileAgreements(data.id)
                                  }}
                                  isPictureCard={false}
                                />
                              </div>
                            </Col>
                          </Row>
                        )}
                        <Divider />
                        <Col span={24}>
                          <h2 className={style.cardTitle}>{t('agreementsCreate.images')}</h2>
                        </Col>
                        <Col span={24}>
                          <UploadImages
                            fileList={values.fileList}
                            setFileList={(data) => {
                              setFieldValue('fileList', data)
                            }}
                          />
                        </Col>
                        <Col span={24}>
                          <SecondaryButton
                            htmlType="submit"
                            type="primary"
                            size="large"
                            style={{ marginBottom: '24px' }}
                            loading={isLoadingCreate || isLoadingEdit || isLoadingStoreFileAttachment}
                          >
                            {params.id ? t('agreementsCreate.save') : t('agreementsCreate.submit')}
                          </SecondaryButton>
                        </Col>
                      </Row>
                    </Card>
                  </Form>
                </Col>
                <Col xl={12} xs={24}>
                  <AgreementOrderCreateCard images={info?.images} onImageDelete={onImageDelete} />
                </Col>
              </Row>
            )}
          </Formik>
          {!!info?.invoices?.length && (
            <Table<AgreementInvoicesType>
              dataSource={info.invoices}
              columns={columnsInvoices}
              className={style.table}
              scroll={{ x: 'max-content' }}
              pagination={false}
            />
          )}

        </>
      </Preloader>
      <Drawer
        title={
          (createType === 'car' && t('carCreate.title'))
          || (createType === 'agreement' && t('agreementsCreate.title'))
          || ''
        }
        open={isCreate}
        onClose={onCloseCreate}
        width={554}
      >
        {createType === 'car' && <CarsCreate isModal onClose={onCloseCreate} />}
        {createType === 'agreement' && <AgreementsCreate isModal successCallbackModal={onCloseCreate} />}
      </Drawer>
    </div>
  )
}
