import { FC, useRef, useState, useEffect } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import cn from 'classnames'
import { useField, useFormikContext } from 'formik'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { debounce } from 'lodash'
import style from './signatureFormik.module.scss'

type SignatureFormikProps = {
  name: string
  placeholder?: string
}

export const SignatureFormik: FC<SignatureFormikProps> = ({ name, placeholder }) => {
  const signatureRef = useRef<any>()
  const { t } = useTranslation()
  const [, meta] = useField(name)
  const { setFieldValue } = useFormikContext<any>()

  const [canvasWidth, setCanvasWidth] = useState<number>(0)
  const [canvasHeight, setCanvasHeight] = useState<number>(116)

  const updateCanvasSize = () => {
    const container = signatureRef.current?.getCanvas().parentElement
    if (container) {
      const newWidth = container.clientWidth
      const aspectRatio = 480 / 116
      const newHeight = newWidth / aspectRatio

      setCanvasWidth(newWidth)
      setCanvasHeight(newHeight)
    }
  }

  const debouncedUpdateCanvasSize = useRef(debounce(updateCanvasSize, 200)).current

  useEffect(() => {
    updateCanvasSize()

    window.addEventListener('resize', debouncedUpdateCanvasSize)

    return () => {
      window.removeEventListener('resize', debouncedUpdateCanvasSize)
      debouncedUpdateCanvasSize.cancel()
    }
  }, [debouncedUpdateCanvasSize])

  const clearSignature = () => {
    signatureRef.current.clear()
    setFieldValue(name, '')
  }

  return (
    <div className={style.signatureCanvasContainer}>
      <div style={{ width: '100%' }}>
        <div className={style.placeholder}>{placeholder}</div>
        <SignatureCanvas
          penColor="#000"
          ref={signatureRef}
          onEnd={() => {
            setFieldValue(name, signatureRef.current.getTrimmedCanvas().toDataURL('image/png'))
          }}
          canvasProps={{
            width: canvasWidth,
            height: canvasHeight,
            className: cn(style.signatureCanvas, meta.error && meta.touched && style.signatureCanvasError),
            style: {
              width: '100%',
            },
          }}
        />
        {meta.error && meta.touched && (
          <div className={style.signatureError}>{meta.error}</div>
        )}
      </div>
      <Button type="dashed" onClick={clearSignature} className={style.clearButton}>
        {t('transportationCreate.clear')}
      </Button>
    </div>
  )
}
