import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { suppliersApi } from '@src/store/services/suppliers-service'
import { FC, useCallback, useMemo } from 'react'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { Button, Col, Row } from 'antd'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { Preloader } from '@src/components/Preloader/Preloader'
import { SelectFormik } from '@src/components/SelectFormik/SelectFormik'
import { serialize } from 'object-to-formdata'
import { UploadFiles } from '@src/components/UploadFiles/UploadFiles'

type SupplierCreateProps = {
  onClose: () => void
}

export const SupplierCreate: FC<SupplierCreateProps> = ({ onClose }) => {
  const { t } = useTranslation()
  const params = useParams<{ id?: string }>()
  const { data, isFetching: isLoadingGetOne } = suppliersApi.useGetOneSupplierQuery(+params.id!, { skip: !params.id })
  const [create, { isLoading: isLoadingCreate }] = suppliersApi.useCreateSupplierMutation()
  const [edit, { isLoading: isLoadingEdit }] = suppliersApi.useEditSupplierMutation()
  const { data: supplierCategories } = suppliersApi.useGetSupplierCategoriesShortListQuery()

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      name: Yup.string().required(t('form.errors.required') ?? ''),
      email: Yup.string().email().required(t('form.errors.required') ?? ''),
      phone: Yup.string().required(t('form.errors.required') ?? ''),
    })
  ), [t])

  const initialValues = useMemo(() => ({
    name: data?.name || '',
    email: data?.email || '',
    phone: data?.phone || '',
    categories: data?.categories?.map((item) => item.id) || [],
    comment: data?.comment || '',
    url: data?.url || '',
    catalog: [],
  }), [data])

  const onSubmit = useCallback(async (values: any, { resetForm }: any) => {
    let result

    const formData = serialize(values, { indices: true })

    if (params.id) {
      result = await edit({
        id: params.id,
        formData,
      })
    } else {
      result = await create(formData)
    }

    if ('data' in result) {
      resetForm()
      onClose()
    }
  }, [create, edit, onClose, params])

  return (
    <div>
      <Preloader loading={isLoadingGetOne}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Row gutter={[8, 0]}>
                <Col span={24}>
                  <FieldFormik name="name" placeholder={t('suppliers.name')!} />
                </Col>
                <Col span={24}>
                  <FieldFormik name="email" placeholder={t('suppliers.email')!} />
                </Col>
                <Col span={24}>
                  <FieldFormik name="phone" placeholder={t('suppliers.phone')!} />
                </Col>
                <Col span={24}>
                  <SelectFormik
                    name="categories"
                    placeholder={t('suppliers.categories')}
                    isMultiple
                    options={supplierCategories?.map((item) => ({
                      value: item.id,
                      label: item.name,
                    })) || []}
                  />
                </Col>
                <Col span={24}>
                  <FieldFormik name="comment" placeholder={t('suppliers.comment')} />
                </Col>
                <Col span={24}>
                  <FieldFormik name="url" placeholder={t('suppliers.url')} />
                </Col>
                <Col span={24}>
                  <UploadFiles
                    filesList={values.catalog}
                    onChange={(fileList) => {
                      setFieldValue('catalog', fileList)
                    }}
                  />
                </Col>
              </Row>
              <br />
              <Button
                htmlType="submit"
                type="primary"
                size="large"
                loading={isLoadingCreate || isLoadingEdit}
              >
                {params.id ? t('suppliers.save') : t('suppliers.submit')}
              </Button>
            </Form>
          )}
        </Formik>
      </Preloader>
    </div>
  )
}
