import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { GetItemsType } from '@src/api/api'
import { Agreement, AgreementShort } from '@src/types/agreements'
import { AgreementInvoicesStatus, AgreementInvoicesType } from '@src/types/agreementInvoices'
import { AgreementBillStatus, AgreementBillType } from '@src/types/agreementBills'
import { AgreementPause, AgreementPauseCreateRequest, AgreementPauseUpdateRequest } from '@src/types/agreementPauses'
import { formatFilters } from '@src/lib/formatFilters'
import { downloadFile } from '@src/lib/downloadFile'
import { getFileName } from '@src/lib/getFileName'
import qs from 'qs'

export const agreementsApi = createApi({
  reducerPath: 'agreementsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      headers.set('Accept', 'application/json, text/plain, */*')

      return headers
    },
  }),
  refetchOnMountOrArgChange: 60,
  tagTypes: ['Agreements'],
  endpoints: (build) => ({
    // Agreements
    getListAgreements: build.query<GetItemsType<Agreement>, { page: number, filters: any }>({
      query: (params) => ({
        url: `/agreements?${formatFilters(params)}`,
      }),
      providesTags: () => ['Agreements'],
    }),
    getShortListAgreements: build.query<AgreementShort[], void>({
      query: () => ({
        url: '/get-short/agreements',
      }),
      providesTags: () => ['Agreements'],
    }),
    getOneAgreement: build.query<Agreement, number>({
      query: (id) => ({
        url: `/agreements/${id}`,
      }),
      providesTags: () => ['Agreements'],
    }),
    createAgreement: build.mutation<Agreement, any>({
      query: (body) => ({
        url: '/agreements',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Agreements'],
    }),
    editAgreement: build.mutation<Agreement, any>({
      query: ({ id, body }) => ({
        url: `/agreements/${id}?_method=PUT`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Agreements'],
    }),
    deleteAgreement: build.mutation<void, number>({
      query: (id) => ({
        url: `/agreements/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Agreements'],
    }),
    deleteFile: build.mutation<void, number>({
      query: (id) => ({
        url: `/delete/attachment/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Agreements'],
    }),
    storeFileAttachment: build.mutation<void, {body: any, agreement_order_id: number}>({
      query: ({ body, ...params }) => ({
        url: '/store/attachment',
        method: 'POST',
        body,
        params,
      }),
      invalidatesTags: ['Agreements'],
    }),
    exportAgreements: build.mutation<void, number[]>({
      query: (agreementsIds) => ({
        url: '/agreements/excel-export',
        method: 'POST',
        body: {
          agreementsIds,
        },
        responseHandler: async (response) => {
          const blob = await response.blob()
          const name = getFileName(response)
          downloadFile(blob, name)
        },
      }),
    }),
    sendEmailCustomerAgreements: build.mutation<void, {id: number, emails: string[]}>({
      query: ({ id, ...params }) => ({
        url: `/agreements/${id}/customer-send?${qs.stringify(params)}`,
        method: 'GET',
      }),
      invalidatesTags: ['Agreements'],
    }),
    agreementGenerateDocument: build.mutation<void, number>({
      query: (id) => ({
        url: `/agreements/${id}/generate-document`,
        method: 'GET',
        responseHandler: async (response) => {
          const blob = await response.blob()
          const name = getFileName(response)
          downloadFile(blob, name)
        },
      }),
    }),

    // agreement pauses
    getListAgreementPauses: build.query<GetItemsType<AgreementPause>, { page: number, filters: any }>({
      query: (params) => ({
        url: '/agreement-pauses',
        params: {
          page: params.page,
          filters: params.filters ? JSON.stringify(params.filters) : undefined,
        },
      }),
      providesTags: () => ['Agreements'],
    }),
    getOneAgreementPause: build.query<AgreementPause, number>({
      query: (id) => ({
        url: `/agreement-pauses/${id}`,
      }),
      providesTags: () => ['Agreements'],
    }),
    createAgreementPause: build.mutation<AgreementPause, AgreementPauseCreateRequest>({
      query: (body) => ({
        url: '/agreement-pauses',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Agreements'],
    }),
    editAgreementPause: build.mutation<AgreementPause, AgreementPauseUpdateRequest>({
      query: ({ id, ...body }) => ({
        url: `/agreement-pauses/${id}?_method=PUT`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Agreements'],
    }),
    deleteAgreementPause: build.mutation<void, number>({
      query: (id) => ({
        url: `/agreement-pauses/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Agreements'],
    }),

    // AgreementInvoices
    getListAgreementInvoices: build.query<GetItemsType<AgreementInvoicesType>, { page: number, filters: any }>({
      query: (params) => ({
        url: `/agreement-invoice?${formatFilters(params)}`,
      }),
      providesTags: () => ['Agreements'],
    }),
    getOneAgreementInvoice: build.query<AgreementInvoicesType, number>({
      query: (id) => ({
        url: `/agreement-invoice/${id}`,
      }),
      providesTags: () => ['Agreements'],
    }),
    editAgreementInvoice: build.mutation<AgreementInvoicesType, any>({
      query: ({ id, body }) => ({
        url: `/agreement-invoice/${id}?_method=PUT`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Agreements'],
    }),
    agreementInvoiceGenerateDocument: build.mutation<void, number>({
      query: (id) => ({
        url: `/agreement-invoice/generate-invoice/${id}`,
        method: 'GET',
        responseHandler: async (response) => {
          const blob = await response.blob()
          const name = getFileName(response)
          downloadFile(blob, name)
        },
      }),
    }),
    sendEmailAgreementInvoices: build.mutation<void, {id: number, emails: string[]}>({
      query: ({ id, ...params }) => ({
        url: `/agreement-invoice/${id}/customer-send?${qs.stringify(params)}`,
        method: 'GET',
      }),
      invalidatesTags: ['Agreements'],
    }),
    exportAgreementInvoices: build.mutation<void, number[]>({
      query: (ids) => ({
        url: 'agreement-invoice/excel-export',
        method: 'POST',
        responseHandler: async (response) => {
          const blob = await response.blob()
          const name = getFileName(response)
          downloadFile(blob, name)
        },
        body: {
          ids,
        },
      }),
    }),
    changeStatusAgreementInvoices: build.mutation<void, {id: number, status: AgreementInvoicesStatus}>({
      query: ({ id, status }) => ({
        url: `/agreement-invoice/${id}/change-status`,
        method: 'POST',
        body: {
          status,
        },
      }),
      invalidatesTags: ['Agreements'],
    }),
    sendEmailCompanyAgreementInvoices: build.mutation<void, {id: number, emails: string[]}>({
      query: ({ id, ...params }) => ({
        url: `/agreement-invoices/${id}/customer-send?${qs.stringify(params)}`,
        method: 'GET',
      }),
      invalidatesTags: ['Agreements'],
    }),

    // AgreementBills
    exportAgreementBills: build.mutation<void, number[]>({
      query: (agreementBillsIds) => ({
        url: '/agreement-bills/excel-export',
        method: 'POST',
        responseHandler: async (response) => {
          const blob = await response.blob()
          const name = getFileName(response)
          downloadFile(blob, name)
        },
        body: {
          agreementBillsIds,
        },
      }),
    }),
    getListAgreementBills: build.query<GetItemsType<AgreementBillType>, { page: number, filters: any }>({
      query: (params) => ({
        url: '/agreement-bills',
        params: {
          page: params.page,
          filters: params.filters ? JSON.stringify(params.filters) : undefined,
        },
      }),
      providesTags: () => ['Agreements'],
    }),
    getOneAgreementBill: build.query<AgreementBillType, number>({
      query: (id) => ({
        url: `/agreement-bills/${id}`,
      }),
      providesTags: () => ['Agreements'],
    }),
    editAgreementBill: build.mutation<AgreementBillType, any>({
      query: ({ id, body }) => ({
        url: `/agreement-bills/${id}?_method=PUT`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Agreements'],
    }),
    deleteAgreementBill: build.mutation<void, number>({
      query: (id) => ({
        url: `/agreement-bills/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Agreements'],
    }),
    sendEmailCustomerAgreementBills: build.mutation<void, {id: number, emails: string[]}>({
      query: ({ id, ...params }) => ({
        url: `/agreement-bills/${id}/customer-send?${qs.stringify(params)}`,
        method: 'GET',
      }),
      invalidatesTags: ['Agreements'],
    }),
    changeStatusAgreementBill: build.mutation<void, {id: number, status: AgreementBillStatus}>({
      query: ({ id, status }) => ({
        url: `/agreement-bills/${id}/change-status`,
        method: 'post',
        body: {
          status,
        },
      }),
      invalidatesTags: ['Agreements'],
    }),

    // AgreementOrders
    getListAgreementOrder: build.query<GetItemsType<any>, { page: number, filters: any }>({
      query: (params) => ({
        url: `/agreement-order?${formatFilters(params)}`,
      }),
      providesTags: () => ['Agreements'],
    }),
    getOneAgreementOrder: build.query<any, number>({
      query: (id) => ({
        url: `/agreement-order/${id}`,
      }),
      providesTags: () => ['Agreements'],
    }),
    createAgreementOrder: build.mutation<Agreement, any>({
      query: (body) => ({
        url: '/agreement-order',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Agreements'],
    }),
    editAgreementOrder: build.mutation<Agreement, any>({
      query: ({ id, body }) => ({
        url: `/agreement-order/${id}?_method=PUT`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Agreements'],
    }),
    agreementOrderGenerateDocument: build.mutation<void, number>({
      query: (id) => ({
        url: `/agreement-order/${id}/generate-document`,
        method: 'GET',
        responseHandler: async (response) => {
          const blob = await response.blob()
          const name = getFileName(response)
          downloadFile(blob, name)
        },
      }),
    }),
    sendEmailCustomerAgreementOrder: build.mutation<void, {id: number, emails: string[]}>({
      query: ({ id, ...params }) => ({
        url: `/agreement-order/${id}/customer-send?${qs.stringify(params)}`,
        method: 'GET',
      }),
      invalidatesTags: ['Agreements'],
    }),
    closeAgreementOrder: build.mutation<void, any>({
      query: ({ body }) => ({
        url: '/agreement-order/close',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Agreements'],
    }),
    closeSpecialAgreementOrder: build.mutation<void, any>({
      query: ({ id, body }) => ({
        url: `/agreement-order/${id}/close-special`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Agreements'],
    }),
    exportAgreementOrder: build.mutation<void, number[]>({
      query: (ids) => ({
        url: '/agreement-order/excel-export',
        method: 'POST',
        responseHandler: async (response) => {
          const blob = await response.blob()
          const name = getFileName(response)
          downloadFile(blob, name)
        },
        body: {
          ids,
        },
      }),
    }),
    generateCloseAgreementOrder: build.mutation<void, number[]>({
      query: (id) => ({
        url: `/agreement-order/${id}/generate-acceptance-document`,
        method: 'GET',
        responseHandler: async (response) => {
          const blob = await response.blob()
          const name = getFileName(response)
          downloadFile(blob, name)
        },
      }),
    }),
  }),
})
