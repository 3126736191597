import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { GetItemsType } from '@src/api/api'
import { formatFilters } from '@src/lib/formatFilters'
import { Supplier, SupplierCategory } from '@src/types/suppliers'

export const suppliersApi = createApi({
  reducerPath: 'suppliersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      headers.set('Accept', 'application/json, text/plain, */*')

      return headers
    },
  }),
  refetchOnMountOrArgChange: 60,
  tagTypes: ['suppliers', 'supplierCategories'],
  endpoints: (build) => ({
    getSupplierCategoriesList: build.query<GetItemsType<SupplierCategory>, any>({
      query: (params) => ({
        url: `/supplier-categories?${formatFilters(params)}`,
      }),
      providesTags: () => ['supplierCategories'],
    }),
    getSupplierCategoriesShortList: build.query<SupplierCategory[], void>({
      query: () => ({
        url: '/get-short/supplier-categories',
      }),
      providesTags: () => ['supplierCategories'],
    }),
    getOneSupplierCategory: build.query<SupplierCategory, number>({
      query: (id) => ({
        url: `/supplier-categories/${id}`,
      }),
      providesTags: () => ['supplierCategories'],
    }),
    createSupplierCategory: build.mutation<SupplierCategory, any>({
      query: (body) => ({
        url: '/supplier-categories',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['supplierCategories'],
    }),
    editSupplierCategory: build.mutation<SupplierCategory, any>({
      query: ({ id, ...body }) => ({
        url: `/supplier-categories/${id}?_method=PUT`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['supplierCategories'],
    }),
    deleteSupplierCategory: build.mutation<void, number>({
      query: (id) => ({
        url: `/supplier-categories/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['supplierCategories'],
    }),

    getSuppliersList: build.query<GetItemsType<Supplier>, any>({
      query: (params) => ({
        url: `/suppliers?${formatFilters(params)}`,
      }),
      providesTags: () => ['suppliers'],
    }),
    getOneSupplier: build.query<Supplier, number>({
      query: (id) => ({
        url: `/suppliers/${id}`,
      }),
      providesTags: () => ['suppliers'],
    }),
    createSupplier: build.mutation<Supplier, any>({
      query: (body) => ({
        url: '/suppliers',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['suppliers'],
    }),
    editSupplier: build.mutation<Supplier, any>({
      query: ({ id, formData }) => ({
        url: `/suppliers/${id}?_method=PUT`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['suppliers'],
    }),
    deleteSupplier: build.mutation<void, number>({
      query: (id) => ({
        url: `/suppliers/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['suppliers'],
    }),
  }),
})
