import { Button, Col, Drawer, Row } from 'antd'
import { Form, Formik } from 'formik'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { SignatureFormik } from '@src/components/SignatureFormik/SignatureFormik'
import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { tasksApi } from '@src/store/services/tasks-service'

type TaskSignCustomerProps = {
  taskId: number
  isOpen: boolean
  onClose: () => void
}

export const TaskSignCustomer: FC<TaskSignCustomerProps> = ({ taskId, isOpen, onClose }) => {
  const { t } = useTranslation()
  const [customerSign, { isLoading }] = tasksApi.useTaskCustomerSignMutation()

  const validationSignSchema = useMemo(() => Yup.object().shape({
    customer_name: Yup.string().required(t('form.errors.required') ?? ''),
    customer_surname: Yup.string().required(t('form.errors.required') ?? ''),
    customer_signature: Yup.string().required(t('form.errors.required') ?? ''),
  }), [t])

  const initialValues = useMemo(() => ({
    customer_name: '',
    customer_surname: '',
    customer_signature: '',
  }), [])

  const onSubmit = async (values: any) => {
    const result = await customerSign({
      id: taskId,
      ...values,
    })

    if ('data' in result) {
      onClose()
    }
  }

  return (
    <Drawer
      title={t('tasks.customerSign')}
      open={isOpen}
      onClose={onClose}
      width={554}
      styles={{
        body: {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          padding: 24,
        },
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSignSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        <Form className="not-card">
          <Row gutter={[8, 0]}>
            <Col xs={24} md={12}>
              <FieldFormik
                name="customer_name"
                placeholder={t('transportation.sign.customerName')}
              />
            </Col>
            <Col xs={24} md={12}>
              <FieldFormik
                name="customer_surname"
                placeholder={t('transportation.sign.customerSurname')}
              />
            </Col>
            <Col span={24}>
              <SignatureFormik
                name="customer_signature"
                placeholder={t('transportationCreate.signature') ?? ''}
              />
            </Col>
          </Row>
          <Button
            className="card-button"
            htmlType="submit"
            type="primary"
            block
            loading={isLoading}
          >
            {t('transportationCreate.sign')}
          </Button>
        </Form>
      </Formik>
    </Drawer>
  )
}
