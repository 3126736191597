import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ColumnProps } from 'antd/lib/table'
import { useHistory } from 'react-router-dom'
import cn from 'classnames'
import { PageTable } from '@src/components/Table/PageTable'
import useQuery from '@src/hooks/useQuery'
import { TaskType } from '@src/types/tasks'
import { trucksApi } from '@src/store/services/trucks-service'
import { isSelectionText } from '@src/lib/utils'
import { Confirm } from '@src/components/Confirm/Confirm'
import { ReactComponent as DeleteIcon } from '@src/assets/delete_icon.svg'
import { Drawer } from 'antd'
import { TrucksCreate } from './TrucksCreate'

export const Trucks = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const pageQuery = useQuery('page')
  const [paramsFilters, setParamsFilters] = useState({
    page: pageQuery ? +pageQuery : 1,
  })
  const { data, isLoading } = trucksApi.useGetTrucksListQuery(paramsFilters)
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)
  const openDrawer = () => setIsDrawerVisible(true)
  const closeDrawer = () => setIsDrawerVisible(false)
  const [deleteTrawl] = trucksApi.useDeleteTruckMutation()
  // const [exportDetails, { isLoading: isLoadingExport }] = trucksApi.useExportTrucksMutation()

  const onCell = useCallback((record: TaskType) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/trucks/view/${record.id}`)
      }
    },
  }), [history])

  const handleDelete = useCallback((record: TaskType) => (
    deleteTrawl(record.id!)
  ), [deleteTrawl])

  const columns = useMemo<ColumnProps<TaskType>[]>(() => [
    {
      title: t('trucks.columns.make'),
      dataIndex: 'make',
      key: 'make',
      onCell,
    },
    {
      title: t('trucks.columns.model'),
      dataIndex: 'model',
      key: 'model',
      onCell,
    },
    {
      title: t('trucks.columns.truckPlate'),
      dataIndex: 'truck_plate',
      key: 'truck_plate',
      onCell,
    },
    {
      title: t('trucks.columns.trailerPlate'),
      dataIndex: 'trailer_plate',
      key: 'trailer_plate',
      onCell,
    },
    {
      title: t('trucks.columns.driver'),
      dataIndex: 'driver',
      key: 'driver',
      render: (driver) => driver?.full_name,
      onCell,
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '48px',
      fixed: 'right',
      render: (_, record) => (
        <Confirm
          title={t('deleteModal.deleteConfirmationTitle')}
          content={t('deleteModal.deleteConfirmation', { localizedType: t('deleteModal.truck') })}
          okText={t('deleteModal.delete')}
          cancelText={t('deleteModal.cancel')}
          onOk={() => handleDelete(record)}
          className={cn('table_actions_item', 'table_actions_item__delete')}
        >
          <DeleteIcon />
        </Confirm>
      ),
    },
  ], [handleDelete, onCell, t])

  return (
    <>
      <PageTable
        title={t('trucks.title')!}
        setParamsFilters={setParamsFilters}
        paramsFilters={paramsFilters}
        onCreate={openDrawer}
        // onExport={exportDetails}
        // isLoadingExport={isLoadingExport}
        tableData={{
          ...data,
          isLoading,
          columns,
        }}
      />
      <Drawer
        title={t('trucks.createTitle')}
        open={isDrawerVisible}
        onClose={closeDrawer}
        width={554}
      >
        <TrucksCreate
          onClose={closeDrawer}
        />
      </Drawer>
    </>
  )
}
