import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ColumnProps } from 'antd/lib/table'
import { Tag } from 'antd'

import { invoicesApi } from '@src/store/services/invoices-service'
import { TableFiltersType } from '@src/types/tableFilters'
import useQuery from '@src/hooks/useQuery'
import { addQuery } from '@src/lib/route'
import { BillsTypeEnum } from '@src/types/settings'
import { InvoicesActions } from '@src/Pages/Invoices/Invoices'
import { companiesApi } from '@src/store/services/companies-service'
import { CompanyType } from '@src/types/company'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@src/store/store'
import { SendEmailType } from '@src/types/mailLogs'
import { setSendEmail } from '@src/store/ducks/app/reducer'
import { AgreementInvoicesActions } from '@src/components/pages/agreementInvoices/AgreementInvoicesTable.hook'
import { PageTable } from '@src/components/Table/PageTable'
import { isSelectionText } from '@src/lib/utils'
import { formatPrice } from '@src/lib/formatPrice'
import style from './allInvoices.module.scss'

export const AllInvoices = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch<AppDispatch>()
  const filtersQuery = useQuery('filters')
  const pageQuery = useQuery('page')
  const location = useLocation()
  const [paramsFilters, setParamsFilters] = useState({
    page: pageQuery ? +pageQuery : 1,
    filters: filtersQuery ? JSON.parse(filtersQuery) : {},
  })
  const { data: customerNamesList } = companiesApi.useGetSelectCompaniesQuery(CompanyType.Customer)
  const { data, isLoading } = invoicesApi.useGetAllInvoicesQuery(paramsFilters, { refetchOnFocus: true })

  useEffect(() => {
    history.push({
      search: addQuery({ ...paramsFilters, filters: JSON.stringify(paramsFilters.filters) }),
    })
  }, [paramsFilters, history])

  const onCellMailHandler = useCallback((record: any, isAccountant?: boolean) => ({
    onClick: () => {
      if (!isSelectionText()) {
        switch (record.invoice_type) {
          case BillsTypeEnum.Business: {
            dispatch(setSendEmail({
              companyId: record.company_id, id: record.id, type: SendEmailType.Invoices, isAccountant,
            }))
            break
          }
          case BillsTypeEnum.Transportation: {
            dispatch(setSendEmail({
              companyId: record.company_id, id: record.id, type: SendEmailType.TransportationBills, isAccountant,
            }))
            break
          }
          case BillsTypeEnum.Rent: {
            dispatch(setSendEmail({
              companyId: record.company_id,
              id: record.id,
              type: isAccountant ? SendEmailType.AgreementInvoices : SendEmailType.AgreementInvoicesV2,
              isAccountant,
            }))
            break
          }
          default: {
            break
          }
        }
      }
    },
  }), [dispatch])

  const onCell = useCallback((record: any) => ({
    onClick: () => {
      if (!isSelectionText()) {
        switch (record.invoice_type) {
          case BillsTypeEnum.Business:
            history.push(`/invoices/edit/${record.id}`, { backUrl: location.pathname + location.search })
            break
          case BillsTypeEnum.Transportation:
            history.push(`/transportation-bills/edit/${record.id}`, { backUrl: location.pathname + location.search })
            break
          case BillsTypeEnum.Rent:
            history.push(`/agreement-invoices/edit/${record.id}`, { backUrl: location.pathname + location.search })
            break
          default:
        }
      }
    },
  }), [history, location])

  const columns = useMemo<ColumnProps<any>[]>(() => [
    {
      title: t('allInvoices.columns.type'),
      dataIndex: 'invoice_type',
      key: 'invoice_type',
      render: (invoiceType) => t(`allInvoices.type.${invoiceType}`),
      align: 'center',
      onCell,
    },
    {
      title: t('allInvoices.columns.date'),
      dataIndex: 'date_start',
      key: 'date_start',
      align: 'center',
      onCell,
    },
    {
      title: t('invoices.columns.company'),
      dataIndex: 'company_full_name',
      key: 'name',
      align: 'center',
      onCell,
    },
    {
      title: t('invoices.columns.billNumber'),
      dataIndex: 'bill_number',
      key: 'bill_number',
      align: 'center',
      onCell,
    },
    {
      title: t('invoices.columns.sum'),
      dataIndex: 'price',
      key: 'price',
      align: 'center',
      render: (price) => formatPrice(price),
      onCell,
    },
    {
      title: t('invoices.columns.sentAccountant'),
      dataIndex: 'is_sent_accountant',
      key: 'isSentAccountant',
      align: 'center',
      render: (isSentAccountant) => (
        <Tag color={isSentAccountant ? 'green' : 'red'}>
          {t(`sendStatus.${isSentAccountant}`)}
        </Tag>
      ),
      onCell: (record) => onCellMailHandler(record, true),
    },
    {
      title: t('invoices.columns.sentCustomer'),
      dataIndex: 'is_sent_customer',
      key: 'isSentCustomer',
      align: 'center',
      className: style.sentCustomer,
      render: (isSentCustomer) => (
        <Tag color={isSentCustomer ? 'green' : 'red'}>
          {t(`sendStatus.${isSentCustomer}`)}
        </Tag>
      ),
      onCell: (record) => onCellMailHandler(record),
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      fixed: 'right',
      render: (_, record) => (
        <>
          {record.invoice_type === BillsTypeEnum.Business && (
            <InvoicesActions record={record} />
          )}
          {record.invoice_type === BillsTypeEnum.Rent && (
            <AgreementInvoicesActions record={record} />
          )}
        </>
      ),
    },
  ], [t, onCell, onCellMailHandler])

  const filtersList = useMemo<TableFiltersType>(() => [
    [
      {
        type: 'select',
        dataIndex: 'invoice_type',
        operator: '$eq',
        placeholder: t('allInvoices.columns.type'),
        width: '200px',
        options: [
          {
            value: BillsTypeEnum.Rent,
            label: t(`allInvoices.type.${BillsTypeEnum.Rent}`),
          },
          {
            value: BillsTypeEnum.Transportation,
            label: t(`allInvoices.type.${BillsTypeEnum.Transportation}`),
          },
          {
            value: BillsTypeEnum.Business,
            label: t(`allInvoices.type.${BillsTypeEnum.Business}`),
          },
        ],
      },
      {
        type: 'rangePicker',
        dataIndex: 'date_start',
        placeholder: [t('filters.dateFrom'), t('filters.dateTo')],
      },
      {
        type: 'select',
        dataIndex: 'company_id',
        operator: '$eq',
        placeholder: t('agreements.columns.customer'),
        width: '200px',
        options: customerNamesList?.map((item) => ({ value: item.id, label: item.name })) || [],
      },
      {
        type: 'input',
        dataIndex: 'bill_number',
        operator: '$eq',
        placeholder: t('allInvoices.columns.billNumber'),
        width: '200px',
      },
    ],
  ], [customerNamesList, t])

  return (
    <div>
      <PageTable
        title={t('allInvoices.title')}
        setParamsFilters={setParamsFilters}
        filtersList={filtersList}
        filtersV2
        paramsFilters={paramsFilters}
        tableData={{
          ...data,
          columns,
          isLoading,
          components: {
            body: {
              // eslint-disable-next-line max-len
              row: (props: any) => <tr {...props} style={{ background: data?.data.find((item: any) => item.id === props['data-row-key'])?.company?.color }} />,
            },
          },
        }}
      />
    </div>
  )
}
