import { Card } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as NoDataIcon } from '@src/assets/no-data_icon.svg'
import style from './NoData.module.scss'

export const NoData = () => {
  const { t } = useTranslation()

  return (
    <Card className={style.card}>
      <div className={style.wrapper}>
        <NoDataIcon />
        <p className={style.text}>{t('noData.text')}</p>
      </div>
    </Card>
  )
}
