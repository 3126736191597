import React, { FC, ReactNode, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Card } from 'antd'
import { AgreementOrderDocument } from '@src/components/pages/agreements/AgreementOrderDocument'
import { ImagesPreview, Image } from '@src/components/ImagesPreview/ImagesPreview'
import { TransferActDocument } from '@src/components/pages/agreements/TransferActDocument'
import { AcceptanceActDocument } from '@src/components/pages/agreements/AcceptanceActDocument'
import style from './Agreement.module.scss'

type AgreementOrderCreateCardProps = {
  images?: Record<string, Image[]>
  onImageDelete: (id?: number) => Promise<void>
}

export const AgreementOrderCreateCard: FC<AgreementOrderCreateCardProps> = ({ images, onImageDelete }) => {
  const { t } = useTranslation()
  const [activeTabKey, setActiveTabKey] = useState<string>('document')

  const contentList: Record<string, ReactNode> = useMemo(() => ({
    document: (
      <AgreementOrderDocument />
    ),
    transferAct: (
      <TransferActDocument />
    ),
    acceptanceAct: (
      <AcceptanceActDocument />
    ),
    gallery: images && (
      <ImagesPreview images={images} onImageDelete={onImageDelete} />
    ),
  }), [images, onImageDelete])

  return (
    <Card
      activeTabKey={activeTabKey}
      onTabChange={setActiveTabKey}
      className={style.cardStyle}
      tabList={[
        {
          key: 'document',
          label: t('agreementsCreate.card.document'),
        },
        {
          key: 'transferAct',
          label: t('agreementsCreate.card.transferAct'),
        },
        {
          key: 'acceptanceAct',
          label: t('agreementsCreate.card.acceptanceAct'),
        },
        {
          key: 'gallery',
          label: t('agreementsCreate.card.gallery'),
        },
      ]}
      tabProps={{
        size: 'middle',
      }}
    >
      {contentList[activeTabKey]}
    </Card>
  )
}
