import { ButtonHTMLAttributes, FC, forwardRef, ReactNode } from 'react'

import cn from 'classnames'
import { Spin } from '@src/components/ui/Spin/Spin'
import style from './button.module.scss'

type ButtonProps = Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'type'> & {
  type?: 'primary' | 'secondary'
  size?: 'large' | 'medium' | 'small'
  htmlType?: 'submit' | 'reset' | 'button'
  leftIcon?: ReactNode
  rightIcon?: ReactNode
  loading?: boolean
  active?: boolean
  fullContent?: boolean
  className?: string
}

export const Button: FC<ButtonProps> = forwardRef<HTMLButtonElement, ButtonProps>((
  {
    children,
    type = 'primary',
    size = 'medium',
    leftIcon,
    rightIcon,
    loading,
    htmlType = 'button',
    disabled,
    active = false,
    fullContent = false,
    className,
    ...props
  },
  ref,
) => (
  <button
    {...props}
    // eslint-disable-next-line react/button-has-type
    type={htmlType}
    ref={ref}
    className={cn(style.button, style[type], style[size], className, {
      [style.notChildren]: !children,
      [style.active]: active,
      [style.loading]: loading,
      [style.fullContent]: fullContent,
    })}
    disabled={disabled || loading}
  >
    <div className={cn(style.wrapper, { [style.wrapperSpin]: loading })}>
      {leftIcon}
      {children}
      {rightIcon}
    </div>
    {loading && (
      <Spin className={style.spin} />
    )}
  </button>
))
