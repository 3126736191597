import { FC, useCallback, useMemo, useState } from 'react'
import { Input } from 'antd'
import { useField, useFormikContext } from 'formik'
// eslint-disable-next-line import/no-extraneous-dependencies
import { usePlacesWidget } from 'react-google-autocomplete'
import cn from 'classnames'
import style from '@src/components/FieldFormik/FieldFormik.module.scss'

type AddressFormikProps = {
  name: string
  placeholder: string
  onChange?: (value: any) => void
}

export const AddressFormik: FC<AddressFormikProps> = ({ name, placeholder, onChange }) => {
  const [field, meta] = useField(name)
  const [value, setValue] = useState(field.value || '')
  const { setFieldValue } = useFormikContext<any>()
  const [isFocused, setIsFocused] = useState<boolean>(false)

  const { ref }: any = usePlacesWidget({
    apiKey: 'AIzaSyCbIbOy8l4T5d4m_CtaOwUjGPNWsx4s3V0',
    language: 'lt',
    onPlaceSelected: (place) => {
      // antInputRef.current.setValue(place?.formatted_address)
      setValue(place?.formatted_address || '')
      setFieldValue(name, place?.formatted_address || '')
      onChange?.(place)
    },
    options: {
      types: ['geocode', 'establishment'],
    },
  })

  const isError = useMemo(() => !isFocused && meta.error && meta.touched, [isFocused, meta.error, meta.touched])

  const handleFocus = useCallback(() => {
    setIsFocused(true)
  }, [])
  const handleBlur = useCallback((e: any) => {
    setIsFocused(false)
    field.onBlur(e)
  }, [field])

  return (
    <div className={style.wrapper}>
      <label className={cn(style.label)}>
        <div className={cn(style.labelText)}>
          {(placeholder)}
        </div>
        <Input
          size="large"
          name={name}
          placeholder={placeholder}
          value={value}
          onFocus={handleFocus}
          onBlur={handleBlur}
          style={{ borderColor: isError ? 'red' : '' }}
          onChange={(e) => {
            setValue(e.target.value)
          }}
          ref={(c) => {
            if (c) ref.current = c.input
          }}
        />
        {isError && <div className={style.error}>{meta.error}</div>}
      </label>
    </div>
  )
}
