import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Button, Popover, Tag } from 'antd'
import { ColumnProps } from 'antd/lib/table'

import { AppDispatch } from '@src/store/store'
import { agreementsApi } from '@src/store/services/agreements-service'
import { AgreementInvoicesStatus, AgreementInvoicesType } from '@src/types/agreementInvoices'
import { setSendEmail } from '@src/store/ducks/app/reducer'
import { SendEmailType } from '@src/types/mailLogs'
import { ReactComponent as DocumentIcon } from '@src/assets/document_icon.svg'
import { ReactComponent as SendMailIcon } from '@src/assets/send-mail_icon.svg'
import { addQuery } from '@src/lib/route'
import { isSelectionText } from '@src/lib/utils'
import cn from 'classnames'
import style from '@src/Pages/AgreementInvoices/agreementInvoices.module.scss'

const statusColor: any = {
  1: 'blue',
  2: 'green',
  3: 'red',
}

type AgreementInvoicesActionsProps = {
  record: AgreementInvoicesType
}

export const AgreementInvoicesActions: FC<AgreementInvoicesActionsProps> = ({ record }) => {
  const dispatch = useDispatch<AppDispatch>()
  const [generateDocument] = agreementsApi.useAgreementInvoiceGenerateDocumentMutation()

  return (
    <div className={cn('table_actions')}>
      <button
        type="button"
        onClick={() => {
          generateDocument(record.id)
        }}
        className={cn('table_actions_item')}
      >
        <DocumentIcon />
      </button>
      <button
        type="button"
        onClick={() => {
          dispatch(setSendEmail({
            type: SendEmailType.AgreementInvoicesV2,
            companyId: record.company_id,
            id: record.id,
          }))
        }}
        className={cn('table_actions_item')}
      >
        <SendMailIcon />
      </button>
    </div>
  )
}

export const useAgreementInvoicesTableHook = ({ initParamsFilters, withoutParamsFilters }: any) => {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch<AppDispatch>()
  const [paramsFilters, setParamsFilters] = useState(initParamsFilters)
  const [isOpenEditStatus, setIsOpenEditStatus] = useState<null | number>(null)
  const { data, isLoading } = agreementsApi.useGetListAgreementInvoicesQuery(paramsFilters)
  const [changeStatus] = agreementsApi.useChangeStatusAgreementInvoicesMutation()

  useEffect(() => {
    if (!withoutParamsFilters) {
      history.push({
        search: addQuery({ ...paramsFilters, filters: JSON.stringify(paramsFilters.filters) }),
      })
    }
  }, [paramsFilters, history, withoutParamsFilters])

  const onCell = useCallback((record: AgreementInvoicesType) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/agreement-invoices/edit/${record.id}`, { backUrl: location.pathname + location.search })
      }
    },
  }), [history, location])

  const updateStatus = useCallback((id: number, status: AgreementInvoicesStatus) => {
    changeStatus({ id, status })
    setIsOpenEditStatus(null)
  }, [changeStatus])

  const onCellMailHandler = useCallback((record: AgreementInvoicesType, isAccountant?: boolean) => ({
    onClick: () => {
      if (!isSelectionText()) {
        dispatch(setSendEmail({
          type: isAccountant ? SendEmailType.AgreementInvoices : SendEmailType.AgreementInvoicesV2,
          companyId: record.order.agreement.customer_id,
          id: record.id,
          isAccountant,
        }))
      }
    },
  }), [dispatch])

  const columns = useMemo<ColumnProps<AgreementInvoicesType>[]>(
    () => [
      {
        title: t('agreementInvoices.columns.dateStart'),
        dataIndex: 'date_start',
        key: 'date_start',
        onCell,
      },
      {
        title: t('agreementInvoices.columns.dateEnd'),
        dataIndex: 'date_end',
        key: 'date_end',
        onCell,
      },
      {
        title: t('agreementInvoices.columns.customer'),
        dataIndex: 'company',
        key: 'name',
        render: (company) => company?.full_name,
        onCell,
      },
      {
        title: t('agreementInvoices.columns.car'),
        dataIndex: 'order',
        key: 'car',
        render: (order) => `${order.car.make} ${order.car.model} (${order.car.serial_number})`,
        onCell,
      },
      {
        title: t('agreementInvoices.columns.number'),
        dataIndex: 'bill_number',
        key: 'bill_number',
        onCell,
      },
      {
        title: t('transportation.columns.sum'),
        dataIndex: 'price',
        key: 'price_month',
        render: (priceMonth) => priceMonth && `${priceMonth}€`,
        onCell,
      },
      {
        title: t('agreementInvoices.columns.type.title'),
        dataIndex: 'type',
        key: 'type',
        render: (type) => t(`agreementInvoices.columns.type.${type}`),
        onCell,
      },
      {
        title: t('agreementInvoices.columns.status'),
        dataIndex: 'status',
        key: 'status',
        width: 120,
        align: 'center',
        render: (status, record) => (
          <Popover
            content={(
              <div>
                <div>
                  <Button type="text" onClick={() => updateStatus(record.id, AgreementInvoicesStatus.notProcessed)}>
                    {t(`agreementInvoices.status.${AgreementInvoicesStatus.notProcessed}`)}
                  </Button>
                </div>
                <div>
                  <Button type="text" onClick={() => updateStatus(record.id, AgreementInvoicesStatus.processed)}>
                    {t(`agreementInvoices.status.${AgreementInvoicesStatus.processed}`)}
                  </Button>
                </div>
                <div>
                  <Button type="text" onClick={() => updateStatus(record.id, AgreementInvoicesStatus.archived)}>
                    {t(`agreementInvoices.status.${AgreementInvoicesStatus.archived}`)}
                  </Button>
                </div>
              </div>
            )}
            trigger="click"
            placement="bottom"
            open={isOpenEditStatus === record.id}
            onOpenChange={() => setIsOpenEditStatus(record.id)}
          >
            <Tag className={style.status} style={{ cursor: 'pointer' }} color={statusColor[status]}>
              {t(`agreementInvoices.status.${status}`)}
            </Tag>
          </Popover>
        ),
      },
      {
        title: t('agreementInvoices.columns.sentAccountant'),
        dataIndex: 'is_sent_accountant',
        key: 'isSentAccountant',
        width: 120,
        align: 'center',
        className: style.sentCustomer,
        render: (isSentAccountant) => (
          <Tag className={style.status} color={isSentAccountant ? 'green' : 'red'}>{t(`sendStatus.${isSentAccountant}`)}</Tag>
        ),
        onCell: (record) => onCellMailHandler(record, true),
      },
      {
        title: t('agreementInvoices.columns.sentCustomer'),
        dataIndex: 'is_sent_customer',
        key: 'isSentCustomer',
        width: 120,
        align: 'center',
        className: style.sentCustomer,
        render: (isSentAccountant) => (
          <Tag className={style.status} color={isSentAccountant ? 'green' : 'red'}>
            {t(`sendStatus.${isSentAccountant}`)}
          </Tag>
        ),
        onCell: (record) => onCellMailHandler(record),
      },
      {
        dataIndex: 'actions',
        key: 'actions',
        width: '76px',
        fixed: 'right',
        render: (_, record) => <AgreementInvoicesActions record={record} />,
      },
    ],
    [t, onCell, onCellMailHandler, isOpenEditStatus, updateStatus],
  )

  return {
    columns,
    paramsFilters,
    setParamsFilters,
    data,
    isLoading,
  }
}
